import { IKeyValueConfiguration } from "../types/common";
import { IRevenueMapFilterConfigurations, IVariableConfig } from "../types/revenueMap";
const topProductFilterConfig = {
	category: {
		title: "Category",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	vendor: {
		title: "Vendor",
		options: [{ value: "PEPSICO", label: "PEPSICO" }],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	segment: {
		title: "Segment",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	brand: {
		title: "Brand",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	subBrand: {
		title: "Sub Brand",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	packSize: {
		title: "Pack Size",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
};

const productFilter = {
	category: {
		title: `Category`,
		options: [],
		placeholder: "Select",
		defaultSelectAll: false,
		all: true,
		multiple: true,
		first: true,
		children: "vendor",
	},
	vendor: {
		title: "Vendor",
		options: [],
		placeholder: "Select",
		defaultSelectAll: false,
		all: true,
		multiple: true,
		children: "segment",
	},
	segment: {
		title: `Segment`,
		options: [],
		placeholder: "Select",
		defaultSelectAll: false,
		all: true,
		multiple: true,
		children: "brand",
	},
	brand: {
		title: `Brand `,
		options: [],
		placeholder: "Select",
		all: true,
		defaultSelectAll: false,
		multiple: true,
		children: "subBrand",
	},
	subBrand: {
		title: `Sub Brand `,
		options: [],
		placeholder: "Select",
		all: true,
		defaultSelectAll: false,
		multiple: true,
		children: "packSize",
	},
	packSize: {
		title: `Pack Size`,
		options: [],
		placeholder: "Select",
		defaultSelectAll: false,
		all: true,
		multiple: true,
		last: true,
	},
};

const localProductFilterConfig = {
	category: {
		title: "Category",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	vendor: {
		title: "Vendor",
		options: [{ value: "PEPSICO", label: "PEPSICO" }],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	segment: {
		title: "Segment",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	brand: {
		title: "Brand",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	subBrand: {
		title: "Sub Brand",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
	packSize: {
		title: "Pack Size",
		options: [],
		placeholder: "Select",
		all: true,
		multiple: true,
		defaultSelectAll: false,
	},
};

const HeadingData = [
	{
		label: "Current Period",
		value: "currentPeriod",
		initial: "CP",
	},
	{
		label: "Previous Period",
		value: "lastPeriod",
		initial: "LP",
	},
	{
		label: "Current Period VS Previous Period (%)",
		value: "periodCompare",
		initial: "LPCP",
	},
	{
		label: "ABS Current Period VS Previous Period",
		value: "absPeriodCompare",
		initial: "ACP",
	},
];

//measure filter data
const varTop = null;

const viewSize: IKeyValueConfiguration[] = [
	{
		label: "MIX Sales Amount Sell Out",
		value: "somSalesAmountSellOut",
	},
	{
		label: "MIX Sales Volume Sell Out",
		value: "somSalesVolumeSellOut",
	},
	{
		label: "MIX Sales Unit Sell Out",
		value: "somSalesUnitSellOut",
	},
	{
		label: "MIX Sales Amount Sell In",
		value: "somSalesAmountSellIn",
	},
	{
		label: "MIX Sales Volume Sell In",
		value: "somSalesVolumeSellIn",
	},
	{
		label: "MIX Sales Unit Sell In",
		value: "somSalesUnitSellIn",
	},
];
const viewType: IKeyValueConfiguration[] = [
	{
		label: "Vendor",
		value: "manufacturer",
	},
	{
		label: "Pack Size",
		value: "packageSize",
	},
	{
		label: "Brand",
		value: "brand",
	},
	{
		label: "Brand - Pack Size",
		value: "brandSize",
	},
	{
		label: "Sub Brand",
		value: "subBrand",
	},
	{
		label: "Sub Brand - Pack Size",
		value: "subBrandSize",
	},
	{
		label: "Segment",
		value: "segment",
	},
];
const variableList: IKeyValueConfiguration[] = [
	{
		label: "ABS GROWTH SOM ",
		value: "absGrowthSOM",
	},
	{
		label: "ABS GROWTH MIX",
		value: "absGrowthMix",
	},
	{
		label: "GROWTH SELL OUT",
		value: "growthSellOut",
	},
	{
		label: "GROWTH SELL IN",
		value: "growthSellIn",
	},
	{
		label: "PRICE SELL OUT",
		value: "priceSellOut",
	},
	{
		label: "PRICE SELL IN",
		value: "priceSellIn",
	},
	{
		label: "GRAMS PER UNIT SELL OUT",
		value: "gramsByUnitSellOut",
	},
	{
		label: "GRAMS PER UNIT SELL IN",
		value: "gramsByUnitSellIn",
	},
	{
		label: "% PRICING SELL IN",
		value: "perPricingSellIn",
	},
	{
		label: "% PRICING SELL OUT",
		value: "perPricingSellOut",
	},
];

const variableConfiguration: IVariableConfig[] = [
	{
		label: "Value",
		value: "Value",
		kpi: ["absGrowthSOM", "absGrowthMix", "growthSellOut", "growthSellIn"],
	},
	{
		label: "Volume",
		value: "Volume",
		kpi: ["absGrowthSOM", "absGrowthMix", "growthSellOut", "growthSellIn"],
	},
	{
		label: "Units",
		value: "Units",
		kpi: ["absGrowthSOM", "absGrowthMix", "growthSellOut", "growthSellIn"],
	},
	{
		label: "Per Unit",
		value: "perUnit",
		kpi: ["priceSellOut", "priceSellIn", "perPricingSellIn", "perPricingSellOut"],
	},
	{
		label: "Per Volume",
		value: "perVolume",
		kpi: ["priceSellOut", "priceSellIn", "perPricingSellIn", "perPricingSellOut"],
	},
];

const measureConfiguration: IVariableConfig[] = [
	{
		label: "Category",
		value: "Category",
		kpi: ["absGrowthSOM"],
	},
	{
		label: "Segment",
		value: "Segment",
		kpi: ["absGrowthSOM"],
	},
	{
		label: "ABS",
		value: "ABS",
		kpi: ["growthSellOut", "growthSellIn"],
	},
	{
		label: "%",
		value: "Percentage",
		kpi: ["growthSellOut", "growthSellIn"],
	},
];

const sellVariableData: IKeyValueConfiguration[] = [
	{
		label: "Grams Per Unit Sell In",
		value: "gramsPerUnitSellIn",
	},
	{
		label: "Grams Per Unit Sell Out",
		value: "gramsPerUnitSellOut",
	},
	{
		label: "MIX Sales Amount Sell Out",
		value: "somSalesAmountSellOut",
	},
	{
		label: "MIX Sales Units Sell Out",
		value: "somSalesUnitsSellOut",
	},
	{
		label: "MIX Sales Volume Sell Out",
		value: "somSalesVolumeSellOut",
	},
	{
		label: "Price Per Unit Sell In",
		value: "priceByUnitSellIn",
	},
	{
		label: "Price Per Unit Sell Out",
		value: "priceByUnitSellOut",
	},
	{
		label: "Price Per Volume Sell In",
		value: "priceByVolumeSellIn",
	},
	{
		label: "Price Per Volume Sell Out",
		value: "priceByVolumeSellOut",
	},
	{
		label: "Sales Amount Sell In",
		value: "salesAmountSellIn",
	},
	{
		label: "Sales Amount Sell Out",
		value: "salesAmountSellOut",
	},
	{
		label: "Sales Units Sell In",
		value: "salesUnitsSellIn",
	},
	{
		label: "Sales Units Sell Out",
		value: "salesUnitsSellOut",
	},
	{
		label: "Sales Volume Sell In",
		value: "salesVolumeSellIn",
	},
	{
		label: "Sales Volume Sell Out",
		value: "salesVolumeSellOut",
	},
	{
		label: "SOM Sales Amount",
		value: "somSalesAmount",
	},
	{
		label: "SOM Sales Units",
		value: "somSalesUnits",
	},
	{
		label: "SOM Sales Volume",
		value: "somSalesVolume",
	},
];

const ViewXLocal: IKeyValueConfiguration[] = [
	{
		label: "Price Per Unit",
		value: "pricePerUnit",
	},
	{
		label: "Volume Per Unit",
		value: "volumePerUnit",
	},
];

const ViewYLocal: IKeyValueConfiguration[] = [
	{
		label: "Price Per Unit",
		value: "pricePerUnit",
	},
	{
		label: "Price Per Volume",
		value: "pricePerVolume",
	},
	{
		label: "Volume Per Unit",
		value: "volumePerUnit",
	},
];

const AdjustPrice: IKeyValueConfiguration[] = [
	{
		label: "Adjust Graph Price",
		value: "adjustGraphPrice",
	},
	{
		label: "Enter Parameters In The Table",
		value: "enterParametersInTheTable",
	},
];

const measureFilter = {
	SOMVarConfig: {
		title: "SOM Variable Configuration",
		options: [
			{ id: "1", value: "Sales" },
			{ id: "2", value: "Volume" },
			{ id: "3", value: "Units" },
		],
		defaultOption: { id: "1", value: "Sales" },
	},
	SOM: {
		title: "SOM/Ctg Sgmnt",
		options: [
			{ id: "1", value: "Category" },
			{ id: "2", value: "Segment" },
		],
		defaultOption: { id: "1", value: "Category" },
		showRadioButton: true,
	},
};

const monthRangeFilter = {
	title: "Select Range of Month",
	options: [
		{ id: "1", value: "3 Months" },
		{ id: "2", value: "6 Months" },
		{ id: "3", value: "12 Months" },
	],
	defaultOption: { id: "1", value: "3 Months" },
	showRadioButton: true,
};
const simulationSellOutStatsCard = [
	{
		title: "Gross Sales",
		value: "0",
	},
	{
		title: "Volume (KGS)",
		value: "0",
	},
	{
		title: "Share Of Market",
		value: "0",
	},
];

const simulationSellInStatsCard = [
	{
		title: "Gross Sales",
		value: "0",
	},
	{
		title: "Volume (KGS)",
		value: "0",
	},
	{
		title: "Gross Profit",
		value: "0",
	},
];

export const periodView = {
	title: "Period View",
	options: [
		{ label: "NA", value: "na" },
		{ label: "Full Year", value: "FullYear" },
		{ label: "Year to Date", value: "YearToDate" },
		{ id: "3", label: "Rolling Year", value: "RollingYear" },
	],
	placeholder: "Select",
};

const ViewYViewXMapper = {
	pricePerUnit: "ppuSimulations",
	pricePerVolume: "ppvSimulations",
	volumePerUnit: "vpuSimulations",
};

export const revenueMapProductFilterOrder = [
	{
		key: "category",
		order: 1,
	},
	{
		key: "vendor",
		order: 2,
	},
	{
		key: "segment",
		order: 3,
	},
	{
		key: "brand",
		order: 4,
	},
	{
		key: "subBrand",
		order: 5,
	},
	{
		key: "packSize",
		order: 6,
	},
];

export const initialRevenueMapFilterConfigurations: IRevenueMapFilterConfigurations = {
	country: "",
	geoLevel: [],
	geoLevel2: [],
	channel: [],
	periodView: [],
	viewTop: 5,
	data: "",
	viewX: "",
	viewY: "",
	viewSize: "",
	viewType: "",
	category: [],
	vendor: [],
	segment: [],
	brand: [],
	subBrand: [],
	packSize: [],
	date: "",
	variables: [],
	views: [],
};

const defaultRevenueMapFilterConfigurations: IRevenueMapFilterConfigurations[] = [
	{
		country: "MEXICO",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL MEXICO-DTS"],
		channel: ["DTS", "OT", "WHSL"],
		periodView: ["na"],
		viewTop: 10,
		data: "sellOut",
		viewX: "absGrowthSOM",
		viewY: "priceSellOut",
		viewSize: "somSalesUnitSellOut",
		xSOMGroup: "Value",
		xSOMMeasure: "Category",
		ySOMGroup: "perVolume",
		ySOMMeasure: "",
		viewType: "subBrandSize",
		category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-DIPS & SALSA", "SAV-NUTS & SEEDS", "SAV-POPCORN-MW", "SAV-SALTY"],
		vendor: ["BIMBO", "BOKADOS", "BYDSA", "OTHER VENDOR", "PEPSICO"],
		segment: [
			"CKY-BFY-SWEET",
			"CKY-CHOC CHIP",
			"CKY-CHOC COVER",
			"CKY-FRUIT",
			"CKY-LOW-END",
			"CKY-MARIAS",
			"CKY-MARSHMLW",
			"CKY-OTHER",
			"CKY-SANDWICH",
			"CKY-SURTIDOS",
			"CKY-TRAD",
			"CKY-WAFER",
			"CRK-BFY-SALTY",
			"CRK-IN-HOME",
			"CRK-RICE",
			"CRK-SALTINE",
			"CRK-SAVORY",
			"CRK-SOPA",
			"FDS-BARS CHEWY",
			"FDS-BARS FRUIT",
			"FDS-BARS GRANOLA",
			"FDS-BARS NUTR",
			"FDS-BARS OAT",
			"FDS-BARS OTHER",
			"FDS-CEREAL ADULT",
			"FDS-CEREAL GRANOLA",
			"FDS-CEREAL KIDS",
			"FDS-OATS INSTANT",
			"FDS-OATS MILD_PWD",
			"FDS-OATS NATURAL",
			"FDS-OATS OTHER",
			"FDS-OATS TRAD",
			"SAV-CORN",
			"SAV-EXTRUDED",
			"SAV-HLTHY SNACK",
			"SAV-NUTS",
			"SAV-OTHER",
			"SAV-PELLET",
			"SAV-POPCORN-MW",
			"SAV-POPCORN-RTE",
			"SAV-PORK RINDS",
			"SAV-POTATO",
			"SAV-RTE DIPS",
			"SAV-SALTY MIX",
			"SAV-SEEDS",
			"SAV-TORTILLA",
			"SAV-VARIETY PK",
			"SAV-WHEAT",
		],
		brand: [
			"3DS",
			"4 BUDDIES POPCORN",
			"ABETO",
			"ACHTLI SALUDABLE CHICHARITOS",
			"AGROPEL",
			"AIRES DEL CAMPO",
			"AIRES DEL CAMPO BARRAS",
			"ALAZAN",
			"ALAZAON OAT",
			"ALDERACA",
			"ALEX CHURRO",
			"ALFA",
			"ALFARIN",
			"ALIBOMBO CHICHAROMBO POPCORN",
			"ALIBOMBO CHICHAROMBO VARIETY",
			"ALIBOMBO DONA",
			"ALL FIBRAN",
			"ALMANARA",
			"ALMASA",
			"AMIEVA",
			"AMULETO NUTS",
			"AMULETO VARIETY",
			"ANEXI",
			"ANGELINAS",
			"ANITA CHETO",
			"ANITA NACHOS",
			"ANNIES ORGANIC",
			"ANZURES",
			"APROZA FRITURA",
			"APROZA POPCORN",
			"ARAGON",
			"ARANZA",
			"ARCOIRIS",
			"ARLUY",
			"ARTESANALES",
			"ARTIACH",
			"ASINEZ",
			"ASINEZ REGULAR LE",
			"ASTOR",
			"AURRERA PAQUE MIX BOTANA SURTIDA REGULAR",
			"AUSTIN",
			"AUTAN",
			"AVECAO",
			"AVENA EL MADRUGADOR",
			"AVILA",
			"AYALA CORN",
			"AYALA POTATO",
			"AZTECA COOKIES",
			"AZTECA FRITOS",
			"AZTECA JAPONES",
			"BAEZ COOKIES",
			"BAEZ OAT",
			"BAKERS",
			"BAKES",
			"BALOCCO",
			"BANA RICOS FRUTOS SECOS",
			"BARCEL ARELOS",
			"BARCEL BIG MIX",
			"BARCEL CHICH DE CERDO",
			"BARCEL CHIPOTLES",
			"BARCEL CHIPS",
			"BARCEL CHURRITOS",
			"BARCEL CORN OTHER",
			"BARCEL DE LA FERIA",
			"BARCEL GOLDEN NUTS REGULAR",
			"BARCEL GOLDEN PEPITA",
			"BARCEL HOT NUTS",
			"BARCEL KIMONO",
			"BARCEL KIYAKIS",
			"BARCEL PAPA FLAT",
			"BARCEL PELLET OTHER",
			"BARCEL POP",
			"BARCEL POPCORN RTE OTHER",
			"BARCEL RUNNERS",
			"BARCEL SALTYMIX OTHER",
			"BARCEL TOREADAS",
			"BARCEL TOREADAS NUTS",
			"BARCEL TORTILLA OTHER",
			"BARCEL VARIETY",
			"BARCEL VARIETY OTHER",
			"BARCEL VERO",
			"BARCEL WAPAS",
			"BARCEL WATZ",
			"BARILLA",
			"BARRITAS MARINELA",
			"BEAUTIFUL DENMARK",
			"BEL ARA CORN",
			"BEL ARA NUT",
			"BEL ARA PEPITA",
			"BEL ARA SAVOTHER",
			"BELGIAN BOYS",
			"BEST SELECTION",
			"BETO",
			"BETO PAPA",
			"BETO PELLET",
			"BETO POP CORN",
			"BETTER OATS",
			"BETTINO",
			"BETTY",
			"BIBLOS",
			"BIG COUNTRY",
			"BIMBO BARS",
			"BIMBO BRAN FRUT",
			"BIMBO CRACKER",
			"BIMBO LABEL BARS FRUIT",
			"BIMBO MULTIGRANO",
			"BIMBO NATURA",
			"BIMBO PRO-T PROTEIN",
			"BIMBO SILUETA",
			"BIMBO SPIGA GALLETA",
			"BIOLICIOUS OAT",
			"BIRBA",
			"BISTRO GOURMET",
			"BLANCAS",
			"BLIVE",
			"BOGOS CORN",
			"BOGOS NUTS",
			"BOGOS PORK RINDS",
			"BOGOS TORTILLA",
			"BOKACHITOS",
			"BOKADOS CHICH DE CERDO",
			"BOKADOS CORN",
			"BOKADOS MIX",
			"BOKADOS NUTS",
			"BOKADOS PAPAS",
			"BOKADOS PELLET",
			"BOKADOS PEPITA",
			"BOKADOS TORTILLA",
			"BOLITAS",
			"BOMBITOS",
			"BOMBONETES",
			"BONNE MAMAN FRUIT",
			"BONNE MAMAN TRAD",
			"BONYBON",
			"BOT DER EXTRUDED",
			"BOT DER POTATO",
			"BOT DER TORTILLA",
			"BOTANAC",
			"BOTANAS SELECTAS CORN",
			"BOTANAS SELECTAS EXTRUDED",
			"BOTANAS SELECTAS NUTS",
			"BOTANAS SELECTAS PELLET",
			"BOTANAS SELECTAS POPCORN",
			"BOTANAS SELECTAS PORKRIND",
			"BOTANERO",
			"BOTANON",
			"BOTANZ",
			"BOTATAS PAPAS",
			"BRABUCONES",
			"BRABUCONES CHICHARRON",
			"BSD FOODS",
			"BSUSY",
			"BUENO",
			"BUFALO DONITAS",
			"BUFALO NACHOS",
			"BUFALO PAPAS",
			"BUMOS CHETO",
			"CABARET",
			"CABARET BFY SALTY",
			"CACAHUA TITO",
			"CACHITOS",
			"CACHITOS CORN",
			"CACHITOS EXTRUDED",
			"CACHITOS MIX",
			"CACHITOS PELLET",
			"CACHITOS TORTILLA",
			"CAMPO BUENO",
			"CAMPO FRESCO",
			"CANADA TRUE",
			"CANASTA VERDE",
			"CANTUCCINI",
			"CAPED",
			"CAPN CRUNCH",
			"CAPRI",
			"CAPRICE",
			"CAPTAINS",
			"CARITO",
			"CASA RINALDI",
			"CASCARIN",
			"CASERAS",
			"CASHITAS NUTS",
			"CASHITAS PALOMITAS",
			"CASHITAS SEMILLAS",
			"CAYRO",
			"CAZARES CACAHUATE",
			"CAZARES CHICHARRON",
			"CAZARES DONITAS",
			"CAZARES HOT CORN",
			"CAZARES MULTISURTIDO",
			"CAZARES PALOMITAS",
			"CAZARES PORK RINDS",
			"CEREALES",
			"CHALITO",
			"CHARLY CHURRITOS",
			"CHARLY PAPAS",
			"CHARRAS",
			"CHARRAS PELLET",
			"CHARRINES",
			"CHARRITOS",
			"CHE CHE CHE CORN",
			"CHE CHE CHE NUTS",
			"CHE CHE CHE PORK RINDS",
			"CHE CHE CHE SEMILLAS",
			"CHECHERECHE",
			"CHECHITOS CORN",
			"CHECHITOS EXTRUDED",
			"CHECHITOS POPCORN RTE",
			"CHECHITOS TORTILLA",
			"CHEETOS BAKED",
			"CHEETOS COLMILLOS",
			"CHEETOS FRIED",
			"CHEETOS POFFETS",
			"CHEEZ-IT",
			"CHICHARRON",
			"CHIHUAHUA OATS",
			"CHIHUAHUA TORTILLA",
			"CHIPYDALE NUTS",
			"CHIPYDALE PELLET",
			"CHIPYDALE POPCORN",
			"CHIPYDALE TORTILLA",
			"CHIPYDALE VARIETY",
			"CHISEMEX",
			"CHITOS",
			"CHOCO BLAST",
			"CHOCOLATINES",
			"CHOCONEJO",
			"CHOKIS",
			"CHOKIS MIX",
			"CHURRUMAIS",
			"CIMARRON POPCORN",
			"CISMAR",
			"CLAUDIA",
			"COCOS",
			"COFFEE JOY",
			"COLUSSI",
			"COMDUL",
			"COOL NUTS",
			"CORINTHIANS",
			"CORONA",
			"CORTEZ",
			"COSTEO",
			"CRACKETS",
			"CREAM OF WHEAT",
			"CREMAX",
			"CRISTINA",
			"CRISTY",
			"CROCANTISSIMO",
			"CRUJIMAX CHARRITOS",
			"CRUJIMAX CHICHARRON",
			"CRUJIMAX NACHOS",
			"CRUJIMAX NUTS",
			"CRUJIMAX PAPAS",
			"CRUJIMAX PLATANITOS",
			"CRUJITOS",
			"CUBERO CACAHUATE",
			"CUBERO CHURRO",
			"CULTURA",
			"CUMA",
			"CURPI",
			"DADDY RAYS",
			"DAELMANS",
			"DANISA",
			"DARE COOKIES",
			"DARE CRACKERS",
			"DASAVENA",
			"DE LA",
			"DE LA ROSA",
			"DE MI HOGAR NUTS",
			"DE MI HOGAR OATS",
			"DE MI TIERRA",
			"DECOCOOKIES",
			"DEDOS",
			"DEL REY",
			"DELACRE",
			"DELALLO",
			"DELGA RICAS CHETO",
			"DELGA RICAS CORN",
			"DELGA RICAS VTY PK",
			"DELI",
			"DELICADAS",
			"DESCO CHURRITOS",
			"DESCO EXTRUDED",
			"DESCO NUTS",
			"DESCO OTHER SNACKS",
			"DESCO PELLET",
			"DESCO POPCORN RTE",
			"DESCO PORK RINDS",
			"DESCO POTATO",
			"DESCO SNACKS MIX",
			"DESCO TORTILLA",
			"DESHIDRATADOS",
			"DESOBRY",
			"DETERSOL",
			"DIAMANTE",
			"DIANIS",
			"DIANITA",
			"DIET FIBRA",
			"DIET NATURE",
			"DIET RADISSON TORTITAS",
			"DIET RADISSON WAFERS",
			"DINOS",
			"DMEALS COOKIES",
			"DOBLE RR",
			"DON CHEVO OTHER",
			"DON CHEVO PELLET",
			"DON FRIJOL",
			"DON NOPAL",
			"DON PALANQUETO",
			"DON SILVIO",
			"DON SIMON",
			"DON TEODOLO",
			"DONA",
			"DORIS PAPAS",
			"DORIS PORKRIND",
			"DORIS SNACK MIX",
			"DORITOS",
			"DORITOS DINAMITA",
			"DPD",
			"DPD PELLET",
			"DULCE",
			"DULYBOT NUTS",
			"DULYBOT PELLET",
			"DULYBOT POPCORN",
			"DULYBOT POTATO",
			"DULYBOT VTY",
			"DUPAQ",
			"DURAN CORN",
			"DURAN MIX",
			"DURAN POPCORN",
			"DURAN POTATO",
			"DZ",
			"EARTHS BEST",
			"EAT NATURAL OATS MLD PWD",
			"EATSMART SNYDERS NAT",
			"ECONOMAX",
			"EL",
			"EL ANGEL CORN",
			"EL ANGEL NUTS",
			"EL ANGEL POTATO",
			"EL CHAVO",
			"EL CHOCO",
			"EL COMPA",
			"EL CORTE CKYS",
			"EL CORTE CKYS MIX",
			"EL CORTE CRKS",
			"EL CORTE WAFER",
			"EL GORRIAGA",
			"EL MAICITO",
			"EL MAYO",
			"EL ORIGINAL",
			"EL PANQUE",
			"EL REPULGO",
			"EL REY",
			"EL RINCON",
			"EL RINCON CHOC CHIP",
			"ELLEDI WAFER",
			"EMPERADOR",
			"EMPERADOR RELLENA",
			"ENATURE",
			"ENCANTO CORN",
			"ENCANTO EXTRUDED",
			"ENCANTO NUTS",
			"ENCANTO PAPAS",
			"ENCANTO PELLET",
			"ENCANTO PEPITA",
			"ENCANTO PORKRIND",
			"ENCANTO TORTILLA",
			"ENCANTO VARIETY",
			"ENCREMADITAS",
			"ESCAMILLAGAR",
			"ESCOLAR",
			"ESCOSA",
			"ESMERALDA",
			"ESNAFER",
			"ESPIGA",
			"ESTEPHANIA CORN",
			"ESTEPHANIA MIX",
			"ESTEPHANIA OTHER",
			"ESTEPHANIA POPCORN",
			"ESTRELLA",
			"EXCELL CORN",
			"EXCELL EXTRUDED",
			"EXCELL NUTS",
			"EXCELL TORTILLA",
			"EXCELL VTY",
			"FAMOUS AMOS",
			"FARM NUTS NUECES",
			"FCACHURROS CORN",
			"FCACHURROS PELLET",
			"FCACHURROS POTATO",
			"FIESTA FRITURAS",
			"FIESTA FRITURAS CORN",
			"FIESTA FRITURAS POPCORN",
			"FIESTA FRITURAS POTATO",
			"FIGGIN",
			"FIILLER",
			"FIORE DI",
			"FLOR NARANJO",
			"FLORECER",
			"FORNO",
			"FORTNUA",
			"FOSSIER",
			"FRANCESITAS",
			"FRIMEX CHICHARRON CERDO",
			"FRIMEX CHICHARRON HARINA",
			"FRITEHSA CHICHARRON",
			"FRITEHSA NUTS",
			"FRITEHSA VTY PK",
			"FRITOS",
			"FRITURIN EXTRUDED",
			"FRITURIN MIX",
			"FRITURIN NUTS",
			"FRITURIN PELLET",
			"FRITURIN POPCORN RTE",
			"FRITURIN POTATO",
			"FRUBOTANAS NUTS",
			"FRUITS",
			"FRUTI",
			"FRUTY OHS",
			"FUNYUNS",
			"FYC NUTS",
			"FYC VTY",
			"GABI CLASICAS",
			"GABI COOKIES",
			"GABI COOKIES CHOC",
			"GABI COOKIES MIX",
			"GABI TARTAS",
			"GABI WAFER",
			"GAMABA",
			"GAMES",
			"GAMESA BIZCOCHITOS",
			"GAMESA MARIAS",
			"GAMESA SALADITAS",
			"GAMESA TRAD CONSEN",
			"GAMESA TRAD COOKIES",
			"GAMESA TRAD FRUIT",
			"GAMESA TRAD MEXICANITAS",
			"GAMESA TRAD OTHER",
			"GAMESA TRAD OTHER LE",
			"GAMESA TRAD OTHER MIX",
			"GAMESA VARIETY",
			"GANSITO",
			"GAPI",
			"GAPSA CRACKER",
			"GAPSA MARIAS",
			"GAPSA OTHER COOKIES",
			"GAPSA OTHER COOKIES LE",
			"GAPSA OTHER COOKIES MIX",
			"GAPSA SALTINE",
			"GAPSA SANDWICH",
			"GAPSA TRAD",
			"GAPSA TRAD MIX",
			"GAPSA WAFER",
			"GARCIA",
			"GARDI",
			"GARIBATOS",
			"GARZETA",
			"GAVOTTES",
			"GEIZHA",
			"GERBER",
			"GERMINOLA",
			"GHIRADELLI",
			"GI DESCONOCIDO",
			"GIRO",
			"GISA MARIAS",
			"GISA OTHER CKYS",
			"GISA OTHER CKYS CHOC",
			"GISA OTHER CKYS LE",
			"GISA OTHER CKYS MIX",
			"GISA SANDWICH",
			"GISA TRAD",
			"GLICO",
			"GLORIA",
			"GLUCK BOTANA VEGGIE",
			"GLUTINO WAFER",
			"GONAC CORN",
			"GONAC EXTRUDED",
			"GONAC MIX",
			"GONAC NUTS",
			"GONAC PELLET",
			"GONAC POPCORN RTE",
			"GONAC POTATO",
			"GONAC TORTILLA",
			"GONAC VARIETY",
			"GONZALEZ EXTRUDED",
			"GONZALEZ NUTS",
			"GONZALEZ POTATO",
			"GOT MILK WAFER",
			"GRANDMAS",
			"GRANELES",
			"GRANO DE ORO",
			"GRANOLA FLAKES",
			"GRISBI",
			"GRISS",
			"GRUPO18 CUADROS",
			"GRUPO18 LAGRIMA",
			"GRUPO18 PALOMITAS",
			"GRUPO18 RUEDA",
			"GRUPO18 TAKIN",
			"GRUPO18 VARIETY",
			"GUAMAZO",
			"GUATOSO CORN",
			"GUERRERO NUTS",
			"GUERRERO OTHER",
			"GUERRERO POTATO",
			"GUILLEN",
			"GUIMORI CORN",
			"GUIMORI FRITURAS",
			"GUIMORI NUTS",
			"GUIMORI PALOMITAS",
			"GUIMORI PELLET",
			"GUIMORI POTATO BRAND",
			"GUIMORI VTY PK",
			"GULLON CKY",
			"GULLON MARIAS",
			"GULLON SALTINE",
			"GULLON WAFER",
			"GUTI",
			"HABANERAS HONEY",
			"HABANERO",
			"HABIBI NUTS",
			"HABIBI SEEDS",
			"HAPPY SNACKS",
			"HARLEQUIN",
			"HAYDEN",
			"HEB COOKIES",
			"HECHO A MANO",
			"HENRYS BAKERY",
			"HOLICHURROS",
			"HOUBER",
			"IGOMEZ PORK RINDS",
			"IGOMEZ TORTILLA",
			"ITAKATE CORN",
			"ITAKATE NUTS",
			"ITAKATE PELLET",
			"JAVY CORN",
			"JAVY PELLET",
			"JAVY POT",
			"JAVY VTY",
			"JHONY",
			"JOMET NUTS",
			"JOMET PAPA",
			"JOMET TORTILLA",
			"JOSANPO",
			"JOSO PELLET",
			"JOSO VTY PK",
			"JOVI CHICHARRON",
			"JOVI NUTS",
			"JOVI TORTILLA",
			"JUAN",
			"JUANINAS",
			"JUANITA CHOC CHIP",
			"JUANITA FRUIT",
			"JUANITA OTHER COOKIES",
			"JUANITA TRAD",
			"JULIMOY CHICH",
			"JULIMOY CORN",
			"JULIMOY EXTRUDED",
			"JULIMOY NUTS",
			"JULIMOY POPCORN RTE",
			"JULIMOY PORK RIND",
			"JULIMOY POTATO",
			"JULIMOY VARIETY",
			"JVENTURA",
			"K-NELA",
			"KACANG",
			"KACANG PEPITA",
			"KAKAWUE",
			"KANIMI",
			"KARATE",
			"KARINA",
			"KARLA",
			"KATANA",
			"KATOS",
			"KCHETONES",
			"KCHETONES CHEETOS",
			"KE CHURRITOS",
			"KEEBLER CHOC CHIP",
			"KEEBLER FUDGE",
			"KEEBLER OTHER",
			"KEEBLER OTHER BRAND",
			"KEEBLER SANDWICH",
			"KELSEN",
			"KENPO",
			"KEOPS",
			"KETTLE",
			"KI SNACKS",
			"KIKIRIK",
			"KIPI CORN",
			"KIPI EXTRUDED",
			"KIPI MIX",
			"KIPI TORTILLA",
			"KKWATE",
			"KKWATE PEPITA",
			"KOCHI",
			"KOIN KOIN",
			"KOIO PAPAS",
			"KOIO PELLET",
			"KOIO POPCORN",
			"KOKIS NUTS",
			"KOKIS VTY PK",
			"KRIS",
			"KUCHENMEISTER",
			"KURUMAYA",
			"LA BOTANA",
			"LA CASA DE",
			"LA FE TOTOPO RANCHERO",
			"LA FUENTE",
			"LA GIRALDA",
			"LA INTEGRAL",
			"LA ITALIANA CKY",
			"LA ITALIANA CKY MARIAS",
			"LA ITALIANA TRAD",
			"LA LIBANESA",
			"LA MARISCA",
			"LA MEXICANA CACHUATES",
			"LA MEXICANA CHETO",
			"LA MEXICANA CHICHARRON",
			"LA MEXICANA CHURRITOS",
			"LA MEXICANA PALOMITAS",
			"LA MEXICANA PAPAS",
			"LA MEXICANA PELLET",
			"LA MEXICANA PLATANITOS",
			"LA MEXICANA SALTY MIX",
			"LA MORISCA",
			"LA OLIVA",
			"LA PANZANELLA CKYS",
			"LA PECOSITA",
			"LA PECOSITA MIX",
			"LA PECOSITA TRAD",
			"LA PROVIDENCIA",
			"LAMBERTZ",
			"LANCE CRACKER",
			"LANCE SANDWICH",
			"LARA CANAPINAS",
			"LARA CHOCO CHIPS",
			"LARA CREMAS",
			"LARA CRENETAS",
			"LARA DELICIOSAS CHOCOLATE CHIP",
			"LARA DELICIOSAS MARSHMALLOW",
			"LARA MARIAS",
			"LARA OTHER SUBBRANDS",
			"LARA OTHER SUBBRANDS LE",
			"LARA OTHER SUBBRANDS MIX",
			"LARA SALADAS",
			"LARA TARTINAS",
			"LARA TRADITIONAL COOKIES",
			"LAS DELICIAS CHOCO",
			"LAS DELICIAS NUTS",
			"LAS DELICIAS SANDWICH",
			"LAS DELICIAS WAFER",
			"LAS DOS NACIONES CRACKER",
			"LAS DOS NACIONES TRAD",
			"LAS LECHITAS",
			"LAS SOMBRILLAS",
			"LAYS",
			"LAYS DIPS",
			"LECLERC",
			"LENNY LARRY",
			"LEO CACAHUATE",
			"LEO CHICH DE CERDO",
			"LEO CHICH HARINA",
			"LEO CORN",
			"LEO EXTRUDED",
			"LEO PAPAS",
			"LEO PEPITA",
			"LEO POPCORN",
			"LEO TORTILLA",
			"LEO VARIETY",
			"LEON",
			"LEON CRN CHIPS",
			"LEON PRKRIND",
			"LIL DUTCH",
			"LIL DUTCH MIX",
			"LILI",
			"LILI MIX",
			"LILYOCC",
			"LITTLE DEBBIE",
			"LIVEM",
			"LOACKER CKY",
			"LOACKER WAFER",
			"LOC",
			"LORENZ CKYS",
			"LORENZ SALADITAS",
			"LORIN NUTS",
			"LORIN POPCORN RTE",
			"LORS",
			"LOS CAMPEONES",
			"LOS COYOTES CORN",
			"LOS COYOTES MIX",
			"LOS COYOTES OTHER",
			"LOS COYOTES PELLET",
			"LOS COYOTES POPCORN",
			"LOS COYOTES PORK RINDS",
			"LOS COYOTES POTATO",
			"LOS COYOTES TORTILLA",
			"LOS COYOTES VARIETY PK",
			"LOS REYES EXTRUDED",
			"LOS REYES MIX",
			"LOS REYES NUTS",
			"LOS REYES POTATO",
			"LOS REYES TORTILLA",
			"LOS TRADICIONALES",
			"LOTUS",
			"LOURDES CORN",
			"LOURDES EXTRUDED",
			"LOURDES PAPAS",
			"LOURDES PORK RINDS",
			"LOVIES NUTS",
			"LOVIES PORK",
			"LOVIES TORTILLA",
			"LU CKYS",
			"LUCKY COOKIES",
			"LUICAM",
			"LULITAS",
			"LULU TRAD",
			"LULU VTY",
			"LUNA CHURRITOS",
			"LUNDBERG CRK RICE",
			"LUPITA CKYS",
			"LUPITA SANDWICH",
			"LUZMA CORN",
			"LUZMA PORK RIND",
			"LUZMA TORTILLA",
			"M.G. CHURRITOS",
			"M.G. PALOMITAS",
			"MACARENAS",
			"MACMA",
			"MACMA CHOCOLATE",
			"MACMA MIX",
			"MACMA TRADICIONAL",
			"MACMA VARIEDADES",
			"MACMA WAFER",
			"MADERA",
			"MAFER",
			"MAGIC",
			"MAIZORO",
			"MAIZORO CORN FLAKES",
			"MAKANA",
			"MAKY FRITURAS HARINA",
			"MAKY TORCIDOS",
			"MAMA CONEJA",
			"MAMUT",
			"MANASUR",
			"MANOLITO PELLET",
			"MANOLITO POPCORN",
			"MANOLITO POTATO",
			"MANOLITO SNACK MIX",
			"MANZELA CHURRITOS",
			"MANZELA NUTS",
			"MARIAN OTHERS",
			"MARIAN TARTALETA",
			"MARIAN TRAD",
			"MARIAN VARIETY",
			"MARIBEL CHOCOLATE",
			"MARIBEL COOKIES",
			"MARIBEL COOKIES LE",
			"MARIBEL CRACKER",
			"MARIBEL IN HOME",
			"MARIBEL MARIAS",
			"MARIBEL NAPOLI",
			"MARIBEL SALADITAS",
			"MARIBEL SANDWICH",
			"MARIBEL SANDWICH MIX",
			"MARINAS",
			"MARINELA CANELITAS",
			"MARINELA FLOW",
			"MARINELA PINGUINOS REGULAR",
			"MARINELA POLVORON",
			"MARINELA POLVORON BITES MIX",
			"MARINELA PRINCIPE REGULAR",
			"MARINI",
			"MARIQUITAS",
			"MARISEL",
			"MARISOL",
			"MARITELY",
			"MARK",
			"MARSAM",
			"MARTINEZ",
			"MARTINEZ CORN",
			"MARTINS",
			"MARY",
			"MAS BOTANAS",
			"MASCOTAS",
			"MASQUERADE",
			"MATCHA KAORI",
			"MATILDE",
			"MATRACAS",
			"MAXIMA",
			"MAYORAL",
			"MCKEE",
			"MCVITIES",
			"MEBER",
			"MEIJI",
			"MELY",
			"MERBA",
			"MERZA",
			"METRO",
			"MEXIPON",
			"MI CARRITO CHURRO",
			"MI CARRITO EXTRUIDO",
			"MI CARRITO SAL",
			"MI REINA",
			"MILAGROS",
			"MILI",
			"MILLERS INSTANT",
			"MILLERS OATS",
			"MILTONS",
			"MISURA CHOC CHIP",
			"MISURA CKY FILLED",
			"MISURA CRACKERS",
			"MISURA OTHER COOKIES",
			"MM",
			"MONTERREY",
			"MORIMOTO",
			"MORO EXTRUDED",
			"MORO NUTS",
			"MORO VTY MX",
			"MORUSAS",
			"MOY CORN",
			"MOY PELLET",
			"MOY PORK RIND",
			"MOY POTATO",
			"MOY VTY PK",
			"MR. SAULO",
			"MRS FIELDS",
			"MRS FRESHLEYS",
			"MULIX",
			"MULTI",
			"MUNCHIES",
			"MURA",
			"MUY NATURAL AVENA",
			"MUY NATURAL CHURRITOS",
			"NACHOS TITO PLATANITOS",
			"NACHOS TITO SURTIDO",
			"NACIONAL",
			"NALFRE",
			"NANCY",
			"NATUCHIPS",
			"NATURAL BALANCE",
			"NATURAL BITS",
			"NATURAL NECTAR",
			"NATURES GARDEN",
			"NATURES HEART BARS",
			"NATURES HEART NUTS",
			"NATURES HEART OATS",
			"NB CORN",
			"NB EXTRUDED",
			"NB NACIONAL",
			"NEY CACAHUATE",
			"NEY CHETO",
			"NEY CHURRITOS",
			"NEY DONITA",
			"NEY PALOMITA",
			"NINJA",
			"NIPON",
			"NITSUGA",
			"NOGLUT",
			"NOPALIA CHURRITOS",
			"NOPALIA TOSTADAS",
			"NORIKO",
			"NORVER",
			"NUECES",
			"NUEVO LEON",
			"NUEVO LEON POTATO",
			"NUITRIMAX",
			"NUTELLA",
			"NUTRESA",
			"NUTRESA COOL",
			"NUTRI",
			"NUTRI WEEL",
			"NUTRIBERRI",
			"NUTRIFLAKES",
			"NUTRIGAL",
			"NUTRIGAL BFY SWEET",
			"NUTRIGAL TRAD",
			"NUTRIGAL VARIETY",
			"NUTRISA",
			"NUTRITAS",
			"NUTRIVIT",
			"NUTRIWELL BARRAS",
			"NYKIS",
			"OAT REVOLUTION",
			"OCAMPO",
			"OCHOA",
			"OKKO RICE",
			"OLD LONDON",
			"OLVERA",
			"ORIGINAL GOURMET",
			"OSMA",
			"OSMIT",
			"OTHER BARS",
			"OTHER BFYCRK SALTY BRAND",
			"OTHER BISCUITS BRAND",
			"OTHER BISCUITS VARIETY",
			"OTHER CEREAL ADULT BRAND",
			"OTHER CKY BFY BRAND",
			"OTHER CKY CHOC BRAND",
			"OTHER CKY OTHER BRAND",
			"OTHER CKY OTHER BRAND SOPA",
			"OTHER CKY TRAD BRAND",
			"OTHER COOKIES LE",
			"OTHER CRK IN HOME OTHER BRAND",
			"OTHER CRK OTHER SUBBRAND",
			"OTHER CRK PLAIN BRAND",
			"OTHER CRK RICE BRAND",
			"OTHER CRK SALTINE BRAND",
			"OTHER LABEL BARS NUTR",
			"OTHER LABEL CEREAL ADULT",
			"OTHER LABEL CEREAL KIDS",
			"OTHER LABEL CHOC CHIP",
			"OTHER LABEL CHOC COVERED",
			"OTHER LABEL FRUIT",
			"OTHER LABEL GRANOLA",
			"OTHER LABEL MARIAS",
			"OTHER LABEL OATS INSTANT",
			"OTHER LABEL OATS MILD PWD",
			"OTHER LABEL OATS NATURAL",
			"OTHER LABEL OTHER CKYS",
			"OTHER LABEL SANDWICH",
			"OTHER LABEL SAV WHEAT",
			"OTHER LABEL SAVORY CORN",
			"OTHER LABEL SAVORY EXTRUDED",
			"OTHER LABEL SAVORY NUTS",
			"OTHER LABEL SAVORY OTHER",
			"OTHER LABEL SAVORY PELLET",
			"OTHER LABEL SAVORY POPCORN MW",
			"OTHER LABEL SAVORY POPCORN RTE",
			"OTHER LABEL SAVORY PORK RINDS",
			"OTHER LABEL SAVORY POTATO",
			"OTHER LABEL SAVORY SALTY MIX",
			"OTHER LABEL SEMILLAS",
			"OTHER LABEL TORTILLA",
			"OTHER LABEL VARIETY PK",
			"OTHER LABEL WAFERS",
			"OTHER NUTS BRAND",
			"OTHER OATS INSTANT BRAND",
			"OTHER OATS MILD PWD BRAND",
			"OTHER OATS NATURAL BRAND",
			"OTHER OATS TRAD BRAND",
			"OTHER PELLET BRAND",
			"OTHER PEPSICO BISCUITS BRAND",
			"OTHER PEPSICO FOODS OTHER",
			"OTHER PEPSICO SAVORY BRAND",
			"OTHER POPCORN MW BRAND",
			"OTHER POPCORN RTE BRAND",
			"OTHER PORK RINDS BRAND",
			"OTHER POTATO BRAND",
			"OTHER SALTY MIX BRAND",
			"OTHER SAV CORN BRAND",
			"OTHER SAV EXTRUDED BRAND",
			"OTHER SAV OTHER BRAND",
			"OTHER TORTILLA BRAND",
			"OYIUKY",
			"OYUKI CORN",
			"OYUKI FRITURAS",
			"OYUKI NUTS",
			"OYUKI PALOMITAS",
			"OYUKI PORK RIND",
			"OYUKI RANCHERO",
			"PACOYOS FRITURA HARINA",
			"PACOYOS MIXTO",
			"PAGASA MARIAS",
			"PAGASA OTHER COOKIES",
			"PAGASA OTHER COOKIES CHOC",
			"PAGASA OTHER COOKIES LE",
			"PAGASA SDWCH",
			"PAGASA TRAD",
			"PAGASA WAFER",
			"PAKETAXO",
			"PAN BUENO",
			"PAN BUENO BFY SWEET",
			"PAN DUCALE",
			"PAN EUROPENO",
			"PANCREMA",
			"PAPADOPOULOS",
			"PAPATINAS",
			"PAPATITAS FER",
			"PAQUETE PINATA",
			"PARIOS CHIPS",
			"PARLE",
			"PARLE SANDWICH",
			"PARRAS",
			"PELAYO CORN",
			"PELAYO POTATO",
			"PEPE",
			"PEPERO",
			"PEPITO CHURRITOS",
			"PEPITO DONAS",
			"PEPITO NUTS",
			"PEPON NUTS",
			"PEPON POPCORN",
			"PEPPERIDGE FARM CHOC CHIP",
			"PEPPERIDGE FARM GOLDFISH",
			"PEPPERIDGE FARM SNADWICH",
			"PEPPERIDGE FARM TRAD",
			"PICOREY CHARRITOS",
			"PICOREY CHICHARRON",
			"PICOREY EXTRUDED",
			"PICOREY NUTS",
			"PICOREY PELLET",
			"PICOREY POPCORN",
			"PICOREY POTATO",
			"PICOREY VARIETY PK",
			"PIKIS CACAHUATE",
			"PIKIS CUADROS",
			"PIKIS DONITAS",
			"PIKIS PAPAS",
			"PILLSBURY",
			"PINOLITAS",
			"PIPO CORN",
			"PIPO NUTS",
			"PIZZEROLAS",
			"PLATIVOLOS",
			"POFFETS",
			"POFFETS MW",
			"POPCORNERS",
			"POPWORKS",
			"PORK RIND",
			"PORKI",
			"PORTICO",
			"PRIMA ANIMALITOS",
			"PRIMA MARIAS",
			"PRIMEAL",
			"PRINGUITAS",
			"PROFE",
			"PROPLASTEC",
			"PROVI CORN",
			"PROVI MIX",
			"PROVI NUTS",
			"PROVI PORK RINDS",
			"PROVI POTATO",
			"PUMA NUTS",
			"PUMA VTY PK",
			"PVL BARS",
			"PVL COOKIES",
			"PVL COOKIES LE",
			"PVL COOKIES TRAD",
			"PVL CORN",
			"PVL EXTRUDED",
			"PVL HEALTHY SNACK",
			"PVL MARIAS",
			"PVL OATS",
			"PVL OTHER",
			"PVL POPCORN MW",
			"PVL POPCORN RTE",
			"PVL PORK RINDS",
			"PVL POTATO",
			"PVL SALTY MIX",
			"PVL SAVORY",
			"PVL SAVORY NUTS & SEEDS",
			"PVL TORTILLA",
			"PVL VARIETY PK",
			"Q PAPAS",
			"QUAKER BARS CHEWY",
			"QUAKER BARS FRUIT",
			"QUAKER BARS GRANOLA",
			"QUAKER CEREAL",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL KIDS",
			"QUAKER GALLETAS",
			"QUAKER OATS",
			"QUAKER OATS INSTANT",
			"QUAKER OATS OLD FASH",
			"QUAKER RICE",
			"QUAKER SUPERMIX",
			"QUAKER SUPERMIX CEREAL",
			"QUALA KIPITOS",
			"QUEEN COOKIES",
			"QUEEN COOKIES CHOC",
			"QUELY",
			"QUESABRITAS",
			"QUICKBURY",
			"QUICKBURY LE",
			"RAMAPE",
			"RAMIREZ",
			"RANCHERITOS",
			"RANCHIPS",
			"RANCHO SANO",
			"RANGEL",
			"RAYADITAS",
			"RD BOTANA",
			"RD CORN",
			"RD EXTRUDED",
			"RD PALOMITAS",
			"RD PAPAS",
			"RD PELLET",
			"REBOTANA CORN",
			"REBOTANA POTATO",
			"RECETA CRUJIENTE",
			"RED ROCK DELI",
			"REGIONAL",
			"REMEDIOS",
			"REY",
			"REY CANDY",
			"REY OTHER",
			"REYES",
			"REYNOSO",
			"RIC",
			"RICA PAPA",
			"RICA PLATANITO",
			"RICASSON",
			"RICHAUD CORN",
			"RICHAUD LE",
			"RICHAUD MARIAS",
			"RICHAUD OTHER",
			"RICHAUD PORK RIND",
			"RICHAUD SALTINE",
			"RICHAUD SOPA",
			"RICHAUD TRAD",
			"RICHEESE",
			"RICOMAIZ",
			"RINCON TARASCO NUTS",
			"RINCON TARASCO PORK RINDS",
			"ROBELIS",
			"ROCKO",
			"ROLAND TARTALETAS",
			"ROLD GOLD",
			"ROLIGO CORN",
			"ROLIGO POTATO",
			"ROSITA",
			"ROSSY PELLET",
			"ROSSY POPCORN",
			"ROYCA",
			"RUFFLES",
			"SABRIMINIS",
			"SABRITAS DIPS",
			"SABRITAS OTHER",
			"SABRITAS PC",
			"SABRITAS POPPLERS",
			"SABRITAS SALSA",
			"SABRITAS VARIETY",
			"SABRITONES",
			"SADY",
			"SAIN MICHEL",
			"SALADINO",
			"SAN PABLO",
			"SANCHEZ",
			"SANISSIMO GALLETAS",
			"SANISSIMO ROSETAS",
			"SANSON",
			"SANTA CECILIA",
			"SANTIVERI CHOC",
			"SANTIVERI MARIAS",
			"SANTIVERI OATS",
			"SANTIVERI OTHER",
			"SANZON",
			"SATORU",
			"SCHAR CKYS",
			"SCHAR MARIAS",
			"SCHAR SALTINE",
			"SCHAR WAFER",
			"SCHETTINO",
			"SCHNITZER CRKS",
			"SELECCION DE LA CASA",
			"SHEILA G",
			"SINALOENSE",
			"SION PELLET",
			"SION POPCORN",
			"SKANDIA BETTER FOR YOU",
			"SKANDIA CHOC CHIP",
			"SLIM POP",
			"SLIM POP MW",
			"SNACK FACTORY",
			"SNAPS",
			"SNYDERS",
			"SNYDERS CRACKERS",
			"SODA REAL",
			"SOE",
			"SOFY",
			"SPECIAL VALUE",
			"SPIRRONES",
			"SPONCH",
			"ST MICHEL",
			"STACYS",
			"STAX",
			"STIEFFEL",
			"STILA",
			"STILA FIT",
			"SUANDY",
			"SUANDY VARIETY",
			"SUAVICREMAS",
			"SUKY EXTRUDED",
			"SUKY PELLET",
			"SUN CHIPS",
			"SUNBITES",
			"SUNBITES POPCORN",
			"SURTIDO",
			"SUSALIA",
			"SUSALIA CRACKER",
			"SWEET FLAKES",
			"SWEETWELL",
			"TA ITTO",
			"TA ITTO FRITURAS",
			"TA ITTO VTY PK",
			"TAGO",
			"TAIFELDS BFY SWEET",
			"TAIFELDS MIX",
			"TAIFELDS OTHER",
			"TAKIS",
			"TANE",
			"TANITOS",
			"TARRIN",
			"TARRIN CHETO",
			"TEPIKENAS",
			"TERRAFERTIL CACAHUATE",
			"TERRAFERTIL CORN",
			"TIA ANGELITA",
			"TIA OFILIA AVENA",
			"TIA ROSA BFY SWEET",
			"TIA ROSA CHOC CHIP",
			"TIA ROSA TARTINAS",
			"TIA ROSA TRADITIONALS",
			"TIGRILLO CORN",
			"TIGRILLO MIX",
			"TIGRILLO POPCORN",
			"TINAJITA",
			"TIPIKOS",
			"TIQUA",
			"TITANIC",
			"TOKIWA",
			"TOLOSA",
			"TOLUQUENAS CORN",
			"TOLUQUENAS MIX",
			"TOLUQUENAS OTHER",
			"TOLUQUENAS PAPAS",
			"TOLUQUENAS POPCORN",
			"TOLUQUENAS PORKRIND",
			"TONIS",
			"TORRITOS",
			"TORTRIX",
			"TOSTACHOS",
			"TOSTARICA MARIAS",
			"TOSTARICA OTHER",
			"TOSTARICA TRAD",
			"TOSTIDADAS",
			"TOSTITOS",
			"TOTAL",
			"TRES REYES",
			"TREVI",
			"TRIKI-TRAKES",
			"TRONQUITO BOTANA",
			"TRONQUITO FRITOS",
			"TRONQUITO MIX",
			"TRONQUITO PALOMITAS",
			"TRONQUITO PELLET",
			"TRUSNACK",
			"TRYOSS",
			"TUKAN CACAHUATE",
			"TUKAN FRITURA",
			"TUKAN PORKRIND",
			"TULEYDA",
			"TURKOS",
			"TWISTOS",
			"UNIVERSITARIO",
			"VALENTONES",
			"VAN DER BERG",
			"VAZQUEZ",
			"VELOZ MOVIE POP",
			"VENSE",
			"VICTORIA",
			"VIEIRA CHOC COVER",
			"VIEIRA CKYS",
			"VIEIRA CRACKER",
			"VIEIRA VARIETY",
			"VIEIRA WAFER",
			"VIKINGO CHICH",
			"VIKINGO PALOMITA",
			"VIKINGO PAPAS",
			"VIKINGOS CHICHARRONES",
			"VIKINGOS CHURRO",
			"VILLA SAN MIGUEL",
			"VIRGEN",
			"VIRGINIA",
			"VIRIDIANA EXTRUDED",
			"VIRIDIANA PAPAS",
			"VIRIDIANA PELLET",
			"VIRIDIANA VTY PK",
			"VIRTUDES CKY",
			"VITALI CORN CHIP",
			"VITALI HEALTHY",
			"VIVRA",
			"VOORTMAN",
			"VUALA",
			"WALKERS",
			"WANDS CHICHARRON",
			"WANDS CORN",
			"WASA",
			"WHEAT BRAN",
			"WIKTORKI",
			"WINNUTS EXTRUDED",
			"WINNUTS NUTS",
			"WINNUTS POTATO",
			"WIPO CORN",
			"WIPO EXTRUDED",
			"WIPO PELLET",
			"WIPO POPCORN",
			"WIPO POTATO",
			"WIPO VTY",
			"WISE CHIPS",
			"WISE POPCORN",
			"WONDERFUL",
			"WOODPECKER",
			"XALIL",
			"YENS",
			"YORICA",
			"YORSH",
			"YOUTZI",
			"YUDITH",
			"YUMI",
			"ZAKS CHOC COVER",
			"ZAKS CKY",
			"ZAKS FRUIT",
			"ZAPATA CHICHARRON",
			"ZAPATA CHURRITOS",
			"ZAPATA MIX",
			"ZORRITOS",
		],
		subBrand: [
			"3DS FLAMIN HOT",
			"3DS REGULAR",
			"4 BUDDIES CLASSIC WHITE",
			"ABETO AVENA",
			"ACHTLI SALUDABLE CHICHARITOS CHIPOTLE",
			"AGROPEL REGULAR",
			"AIRES DEL CAMPO BARRAS REGULAR",
			"AIRES DEL CAMPO REGULAR",
			"ALAZAN OAT REGULAR",
			"ALAZAN REGULAR",
			"ALDERACA CHILE LEMON",
			"ALDERACA ENCHILADO",
			"ALEX CHURRO MAIZ",
			"ALFA OMEGA",
			"ALFARIN CHICHARRONES",
			"ALIBOMBO CHICHAROMBO POPCORN REGULAR",
			"ALIBOMBO CHICHAROMBO VARIETY REGULAR",
			"ALIBOMBO DONA REGULAR",
			"ALL FIBRAN REGULAR",
			"ALMANARA REGULAR",
			"ALMASA REGULAR",
			"AMIEVA REGULAR",
			"AMULETO CASCARA",
			"AMULETO CHILE",
			"AMULETO MACHITO",
			"AMULETO SAL",
			"AMULETO VARIETY",
			"ANEXI TOMASITO",
			"ANGELINAS REGULAR",
			"ANITA CHILE",
			"ANITA FUEGO",
			"ANITA NACHO",
			"ANITA QUESO",
			"ANNIES ORGANIC VARIETY",
			"ANZURES ENCHILADOS",
			"ANZURES JAPONES",
			"ANZURES REGULAR",
			"ANZURES VARIETY",
			"APROZA CHEETOS BALON",
			"APROZA CHILE",
			"APROZA CHIPOTLE",
			"APROZA CON QUESO",
			"APROZA FIESTA",
			"APROZA LIMON",
			"APROZA MIX",
			"APROZA PIZZA",
			"APROZA QUESO",
			"APROZA REGULAR",
			"ARAGON JAPONES",
			"ARAGON REGULAR",
			"ARANZA CHURRO",
			"ARANZA LIMON",
			"ARCOIRIS REGULAR",
			"ARLUY REGULAR",
			"ARTESANALES REGULAR",
			"ARTIACH DINOSAURUS",
			"ASINEZ REGULAR",
			"ASINEZ REGULAR LE",
			"ASTOR WAFER",
			"AURRERA PAQUE MIX BOTANA SURTIDA REGULAR",
			"AUSTIN REGULAR",
			"AUTAN REGULAR",
			"AVECAO REGULAR",
			"AVENA EL MADRUGADOR",
			"AVILA CHILE",
			"AVILA JAPONES",
			"AVILA REGULAR",
			"AVILA SAL",
			"AVILA VARIETY",
			"AYALA CHURRO",
			"AYALA REGULAR",
			"AZTECA COOKIES REGULAR",
			"AZTECA FRITOS MAIZ CHILE",
			"AZTECA FRITOS MAIZ CON SAL",
			"AZTECA JAPONES REGULAR",
			"BAEZ COOKIES REGULAR",
			"BAEZ OAT REGULAR",
			"BAKERS OREJITAS",
			"BAKES BITES",
			"BALOCCO REGULAR",
			"BANA RICOS PLATANITOS CON CHILE",
			"BANA RICOS PLATANITOS NATURAL",
			"BARCEL ARELOS QUESO",
			"BARCEL BIG MIX FUEGO",
			"BARCEL BIG MIX OTHER SUBBRAND",
			"BARCEL BIG MIX QUESO",
			"BARCEL BIG MIX REGULAR",
			"BARCEL BIG MIX SALSA INGLESA",
			"BARCEL CHICH DE CERDO REGULAR",
			"BARCEL CHIPOTLES REGULAR",
			"BARCEL CHIPS A LA DIABLA",
			"BARCEL CHIPS ADOBADAS",
			"BARCEL CHIPS CHIPOTLE",
			"BARCEL CHIPS FUEGO",
			"BARCEL CHIPS HABANERO",
			"BARCEL CHIPS JALAPENO",
			"BARCEL CHIPS LIMON",
			"BARCEL CHIPS MIX",
			"BARCEL CHIPS OTHER SUBBRAND",
			"BARCEL CHIPS QUESO",
			"BARCEL CHIPS SAL",
			"BARCEL CHIPS TOREADAS",
			"BARCEL CHIPS VARIETY",
			"BARCEL CHURRITOS FUEGO",
			"BARCEL CHURRITOS REGULAR",
			"BARCEL CORN OTHER SUBBRAND",
			"BARCEL DE LA FERIA OTHER SUBBRAND",
			"BARCEL KIYAKIS JAPONES",
			"BARCEL KIYAKIS REGULAR",
			"BARCEL PAPA FLAT ADOBO MEXICANO",
			"BARCEL PAPA FLAT FUEGO",
			"BARCEL PAPA FLAT SAL",
			"BARCEL PELLET OTHER SUBBRAND",
			"BARCEL POP CHOX",
			"BARCEL POP KARAMELADAS",
			"BARCEL POP OTHER SUBBRAND",
			"BARCEL POPCORN RTE OTHER SUBBRAND",
			"BARCEL RUNNERS FUEGO",
			"BARCEL RUNNERS HOT SAUCE",
			"BARCEL RUNNERS MINI",
			"BARCEL RUNNERS OTHER SUBBRAND",
			"BARCEL RUNNERS SALSA",
			"BARCEL SALTYMIX OTHER SUBBRAND",
			"BARCEL TOREADAS HABANERAS",
			"BARCEL TOREADAS SERRANO",
			"BARCEL TOREADAS TRO PICOSAS",
			"BARCEL TORTILLA OTHER SUBBRAND",
			"BARCEL VARIETY OTHER SUBBRAND",
			"BARCEL VARIETY REGULAR",
			"BARCEL WAPAS LIMON",
			"BARCEL WAPAS QUESO",
			"BARCEL WAPAS SALSA",
			"BARCEL WATZ REGULAR",
			"BARILLA MULINO BIANCO",
			"BARRITAS MINI",
			"BARRITAS REGULAR",
			"BARRITAS SALVADO",
			"BEAUTIFUL DENMARK REGULAR",
			"BEL ARA CORN REGULAR",
			"BEL ARA NUT REGULAR",
			"BEL ARA PEPITA REGULAR",
			"BEL ARA SAVOTHER REGULAR",
			"BELGIAN BOYS REGULAR",
			"BEST SELECTION VARIETY",
			"BETO CHILE",
			"BETO CHIPOTLE",
			"BETO FUEGO",
			"BETO LIMON",
			"BETO PAPA SAL",
			"BETO PELLET REGULAR",
			"BETO POP CORN CHILE",
			"BETO POP CORN QUESO",
			"BETO POP CORN SAL",
			"BETO QUESO",
			"BETO REGULAR",
			"BETTER OATS INSTANT",
			"BETTINO REGULAR",
			"BETTY CHURRO",
			"BIBLOS ROSCAS",
			"BIG COUNTRY REGULAR",
			"BIMBO CRACKER REGULAR",
			"BIMBO MULTIGRANO REGULAR",
			"BIMBO NATURA REGULAR",
			"BIMBO OTHER BARS FRUIT",
			"BIMBO OTHER BARS OTHER",
			"BIMBO PRO-T PROTEIN REGULAR",
			"BIMBO SILUETA REGULAR",
			"BIMBO SPIGA GALLETA REGULAR",
			"BIOLICIOUS OAT REGULAR",
			"BIRBA REGULAR",
			"BISTRO GOURMET REGULAR",
			"BLANCAS TOTOPO",
			"BLIVE REGULAR",
			"BOGOS CORN REGULAR",
			"BOGOS NUTS REGULAR",
			"BOGOS PORK RINDS REGULAR",
			"BOGOS TORTILLA REGULAR",
			"BOKA CHICH DE CERDO REGULAR",
			"BOKA CORN BOKADITAS",
			"BOKA CORN CHURRITOS",
			"BOKA CORN FRIKOS",
			"BOKA CORN NACHOS",
			"BOKA CORN OTHER SUBBRAND",
			"BOKA CORN STRIPS",
			"BOKA ENRE REGULAR",
			"BOKA EXTRUDED CHITOS EXTREME",
			"BOKA EXTRUDED CHITOS PUFFED",
			"BOKA EXTRUDED CHITOS TORNILLO",
			"BOKA HUEKOS REGULAR",
			"BOKA MIX D-TOCHOS",
			"BOKA MIX VARIADITO",
			"BOKA PELLET CHICH DE HARINA",
			"BOKA PELLET ENRE",
			"BOKA PELLET PRISPAS ADOBADAS",
			"BOKA PELLET PRISPAS CHAMOY",
			"BOKA PELLET PRISPAS HABANERO",
			"BOKA PELLET PRISPAS PICOSITAS",
			"BOKA PELLET RUEDAS",
			"BOKA PEPITA REGULAR",
			"BOKA TOPITOS GRILL",
			"BOKA TOPITOS JALAPENO",
			"BOKA TOPITOS LINAZA",
			"BOKA TOPITOS OTHER",
			"BOKA TOPITOS PIZZA",
			"BOKA TOPITOS QUESO",
			"BOKA TOPITOS REGULAR",
			"BOKA TOPITOS SALSA VERDE",
			"BOKA TOSTADITA REGULAR",
			"BOKADOS ENCHILADO",
			"BOKADOS JAPONES",
			"BOKADOS MIX",
			"BOKADOS PAPAS REGULAR",
			"BOKADOS SAL",
			"BOLITAS REGULAR",
			"BOMBITOS REGULAR",
			"BOMBONETES REGULAR",
			"BONNE MAMAN REGULAR",
			"BONNE MAMAN TARTALETA",
			"BONYBON REGULAR",
			"BOT DER ENCANTO EXTRUDED",
			"BOT DER ENCANTO POTATO",
			"BOT DER PIZZA",
			"BOT DER QUESO",
			"BOT DER REGULAR",
			"BOT DER SALSA",
			"BOTANAC ENCHILADOS",
			"BOTANAC VARIETY",
			"BOTANAS ANITA ANICHETOS",
			"BOTANAS ANITA ANILLO",
			"BOTANAS ANITA CHICHARRONES REGULAR",
			"BOTANAS ANITA CHURRIKOS",
			"BOTANAS ANITA PALOMITAS",
			"BOTANAS ANITA SURTIDO",
			"BOTANAS REGULAR",
			"BOTANAS SELECTAS FRITO CON AJONJOLI",
			"BOTANAS SELECTAS FRITO MINI",
			"BOTANAS SELECTAS JAPONES",
			"BOTANAS SELECTAS TIRITAS",
			"BOTANERO CHICH PUERCO",
			"BOTANON VARIETY",
			"BOTANZ CHILE",
			"BOTANZ ENCHILADO",
			"BOTANZ MIX",
			"BOTANZ REGULAR",
			"BOTATAS PAPAS ADOBADA",
			"BOTATAS PAPAS ORIGINALES",
			"BRABUCONES CHICHARRON TRADICIONAL",
			"BRABUCONES ENCHILADAS",
			"BRABUCONES MANTEQUILLA",
			"BRABUCONES QUESO",
			"BRAN FRUT REGULAR",
			"BSD FOODS REGULAR",
			"BSUSY REGULAR",
			"BUENO REGULAR",
			"BUFALO DONITAS CHILE LIMON",
			"BUFALO DONITAS SAL LIMON",
			"BUFALO NACHOS CHILE SALVAJE",
			"BUFALO NACHOS QUESO CHILE",
			"BUFALO PAPAS CLASICA SAL",
			"BUFALO PAPAS FUEGO REJILLA",
			"BUFALO PAPAS JALAPENO REJILLA",
			"BUFALO PAPAS MIX",
			"BUFALO PAPAS QUESO REJILLA",
			"BUFALO PAPAS SAL DE MAR",
			"BUMOS CHETO PUFFY",
			"BUMOS FIESTA",
			"BUMOS PALOMITAS",
			"BUMOS REGULAR",
			"CABARET BFY SALTY REGULAR",
			"CABARET REGULAR",
			"CACAHUA TITO JAPONES",
			"CACHITOS REGULAR",
			"CACHITOS REGULAR CORN",
			"CACHITOS REGULAR EXTRUDED",
			"CACHITOS REGULAR MIX",
			"CACHITOS REGULAR PELLET",
			"CACHITOS REGULAR TORTILLA",
			"CAMPO BUENO REGULAR",
			"CAMPO FRESCO REGULAR",
			"CANADA TRUE REGULAR",
			"CANASTA VERDE REGULAR",
			"CANELITAS BITES",
			"CANELITAS REGULAR",
			"CANELITAS TENTACION",
			"CANTUCCINI SAPORI",
			"CAPED REGULAR",
			"CAPN CRUNCH BOTE",
			"CAPN CRUNCH REGULAR",
			"CAPRI ENCHILADOS",
			"CAPRI JAPONES",
			"CAPRI REGULAR",
			"CAPRICE LATA",
			"CAPTAINS WAFER",
			"CARITO NACHO",
			"CASA RINALDI REGULAR",
			"CASCARIN REGULAR",
			"CASERAS REGULAR",
			"CASHITAS MIXTO",
			"CASHITAS NUEZ",
			"CASHITAS PALOMITAS",
			"CASHITAS PEPITA",
			"CAYRO REGULAR",
			"CAZARES CACAHUATE ENCHILADO",
			"CAZARES CACAHUATE MIXTO",
			"CAZARES CACAHUATE REGULAR",
			"CAZARES CHICHARRON REGULAR",
			"CAZARES DONITAS SAL Y LIMON",
			"CAZARES HOT CORN REGULAR",
			"CAZARES MULTISURTIDO REGULAR",
			"CAZARES PALOMITAS REGULAR",
			"CAZARES PORK RINDS REGULAR",
			"CEREALES REGULAR",
			"CHALITO REGULAR",
			"CHARLY ENCHILADOS",
			"CHARLY REGULAR",
			"CHARLY SALSA",
			"CHARRAS PELLET REGULAR",
			"CHARRAS REGULAR",
			"CHARRINES CHILE",
			"CHARRINES FUEGO",
			"CHARRINES JALAPENO",
			"CHARRINES QUESO",
			"CHARRINES REGULAR",
			"CHARRITOS CHILE",
			"CHE CHE CHE CANTINERO",
			"CHE CHE CHE CHICHARRON",
			"CHE CHE CHE CORNCHIPS",
			"CHE CHE CHE ENCHILADO",
			"CHE CHE CHE GARAPINADO",
			"CHE CHE CHE JAPONES",
			"CHE CHE CHE MIXTO",
			"CHE CHE CHE NUEZ",
			"CHE CHE CHE NUTS REGULAR",
			"CHE CHE CHE PEPITA",
			"CHE CHE CHE SALADAS",
			"CHECHERECHE ENCHILADOS",
			"CHECHERECHE REGULAR",
			"CHECHITOS CORN CHIPS",
			"CHECHITOS EXTRUDED REGULAR",
			"CHECHITOS PALOMITAS REGULAR",
			"CHECHITOS TORTILLA REGULAR",
			"CHEETOS BAKED BIGOCHOS",
			"CHEETOS BAKED BOLITAS",
			"CHEETOS BAKED HUELLITAS",
			"CHEETOS BAKED HUESUDOS",
			"CHEETOS BAKED KALAKETOS",
			"CHEETOS BAKED MIX",
			"CHEETOS BAKED OTHER SUBBRAND",
			"CHEETOS BAKED POFFCORN",
			"CHEETOS BAKED POFFS",
			"CHEETOS BAKED POFFS FLAMIN HOT",
			"CHEETOS BAKED POPCORN MIX",
			"CHEETOS BAKED TOINGS",
			"CHEETOS COLMILLOS REGULAR",
			"CHEETOS FRIED FLAMIN HOT",
			"CHEETOS FRIED NACHO",
			"CHEETOS FRIED OTHER SUBBRAND",
			"CHEETOS FRIED TORCIDITOS",
			"CHEETOS HABANERO BITES",
			"CHEETOS POFFETS REGULAR",
			"CHEEZ-IT REGULAR",
			"CHICHARRON REGULAR",
			"CHIHUAHUA NACHO",
			"CHIHUAHUA REGULAR",
			"CHIPYDALE DONA GDE CON CHILE",
			"CHIPYDALE ENCHILADOS",
			"CHIPYDALE MIX",
			"CHIPYDALE RANCHERITOS",
			"CHIPYDALE REGULAR",
			"CHIPYDALE SURTIDAS",
			"CHISEMEX REGULAR",
			"CHITOS CHETTO",
			"CHOCO BLAST REGULAR",
			"CHOCOLATINES REGULAR",
			"CHOCONEJO REGULAR",
			"CHOKIS BLACK",
			"CHOKIS BROWNIE",
			"CHOKIS CHOKO",
			"CHOKIS CHOKO MIX",
			"CHOKIS CHOKOMAX",
			"CHOKIS MINI",
			"CHOKIS OTHER SUBBRAND",
			"CHOKIS REGULAR",
			"CHOKIS VARIETY",
			"CHURRUMAIS FLAMAS",
			"CHURRUMAIS REGULAR",
			"CIMARRON POPCORN REGULAR",
			"CISMAR CHILE",
			"CLAUDIA ENCHILADOS",
			"CLAUDIA JAPONES",
			"CLAUDIA REGULAR",
			"COCOS REGULAR",
			"COFFEE JOY REGULAR",
			"COLUSSI REGULAR",
			"COMDUL JAPONES",
			"COOL NUTS JAPONES",
			"COOL NUTS REGULAR",
			"CORINTHIANS REGULAR",
			"CORONA AVENA",
			"CORTEZ ENCHILADOS",
			"CORTEZ JAPONES",
			"CORTEZ REGULAR",
			"CORTEZ SAL",
			"CORTEZ VARIETY",
			"COSTEO SALADAS",
			"CRACKETS DUO",
			"CRACKETS MINI",
			"CRACKETS REGULAR",
			"CREAM OF WHEAT REGULAR",
			"CREMAX NO SUGAR",
			"CREMAX REGULAR",
			"CRISTINA REGULAR",
			"CRISTY REGULAR",
			"CROCANTISSIMO REGULAR",
			"CRUJI NACHOS CHARRAS",
			"CRUJIMAX CHARRITOS CHILE Y LIMON",
			"CRUJIMAX CHARRITOS JALAPENO",
			"CRUJIMAX CHICHARRON",
			"CRUJIMAX JAPONES",
			"CRUJIMAX PAPA BRAVO",
			"CRUJIMAX PAPA QUESO",
			"CRUJIMAX PLATANITOS SAL",
			"CRUJITOS FLAMIN HOT",
			"CRUJITOS REGULAR",
			"CUBERO CHILE",
			"CUBERO CHIPOTLE",
			"CUBERO HABANERO",
			"CUBERO JALAPENO",
			"CUBERO JAPONES",
			"CUBERO QUESO",
			"CUBERO REGULAR",
			"CULTURA ALIMENTICIA",
			"CUMA RUNERS",
			"CURPI CERDO",
			"DADDY RAYS REGULAR",
			"DAELMANS WAFERS",
			"DANISA TRADITIONAL",
			"DARE BRETON",
			"DARE REGULAR",
			"DASAVENA REGULAR",
			"DE LA REGULAR",
			"DE LA ROSA CASCARA",
			"DE LA ROSA ENCHILADO",
			"DE LA ROSA JAPONES",
			"DE LA ROSA SALADO",
			"DE MI HOGAR CHILE",
			"DE MI HOGAR ENCHILADOS",
			"DE MI HOGAR JAPONES",
			"DE MI HOGAR REGULAR",
			"DE MI HOGAR SALADO",
			"DE MI TIERRA TOTOPO CON SAL",
			"DECOCOOKIES REGULAR",
			"DEDOS REGULAR",
			"DEL REY CHIPOTLE",
			"DEL REY HABANERO",
			"DEL REY JAPONES",
			"DEL REY NACHO",
			"DEL REY REGULAR",
			"DELACRE TEA TIME",
			"DELALLO REGULAR",
			"DELGA RICAS CHURRO",
			"DELGA RICAS FUEGO",
			"DELGA RICAS QUESO",
			"DELGA RICAS REGULAR",
			"DELI REGULAR",
			"DELICADAS REGULAR",
			"DESCO BOTANA",
			"DESCO CACAHUATE REGULAR",
			"DESCO CHICHARRON REGULAR",
			"DESCO CHILE",
			"DESCO CHURRITOS REGULAR",
			"DESCO DURITO",
			"DESCO ENCHILADOS",
			"DESCO JAPONES",
			"DESCO MIX",
			"DESCO NATURAL",
			"DESCO PALOMITAS",
			"DESCO PAPAS SAL DE MAR",
			"DESCO PLATANITOS CHILE",
			"DESCO RANCHEVITOS",
			"DESCO RUEDA",
			"DESCO RUEDA CHILE",
			"DESCO SAVORY EXTRUDED",
			"DESCO TOTOPO",
			"DESHIDRATADOS REGULAR",
			"DESOBRY REGULAR",
			"DETERSOL REGULAR",
			"DIAMANTE ENCHILADO",
			"DIAMANTE JAPONES",
			"DIAMANTE SALADAS",
			"DIANIS P CHILAQUILES",
			"DIANITA ENCHILADOS",
			"DIANITA JAPONES",
			"DIANITA REGULAR",
			"DIET FIBRA REGULAR",
			"DIET NATURE REGULAR",
			"DIET RADISSON TORTITAS",
			"DIET RADISSON WAFERS",
			"DINOS ANIMALITOS",
			"DMEALS REGULAR",
			"DOBLE RR NATURAL",
			"DON CHEVO CHILE",
			"DON CHEVO REGULAR",
			"DON FRIJOL NATURAL",
			"DON NOPAL CHILE",
			"DON PALANQUETO JAPONES",
			"DON PALANQUETO REGULAR",
			"DON SILVIO",
			"DON SIMON REGULAR",
			"DON TEODOLO REGULAR",
			"DONA JIMENA",
			"DORIS PAPAS REGULAR",
			"DORIS PORKRIND REGULAR",
			"DORIS SNACK MIX REGULAR",
			"DORITOS BLAST",
			"DORITOS CHILE",
			"DORITOS DINAMITA CHILE Y LIMON",
			"DORITOS DINAMITA FLAMIN HOT",
			"DORITOS FLAMIN HOT",
			"DORITOS HABANERO BITES",
			"DORITOS INCOGNITA",
			"DORITOS NACHO",
			"DORITOS OTHER SUBBRAND",
			"DORITOS PIZZEROLA",
			"DORITOS POPMIX",
			"DORITOS RAINBOW",
			"DORITOS VARIETY",
			"DPD ENCHILADOS",
			"DPD FIESTA",
			"DPD PELLET REGULAR",
			"DPD REGULAR",
			"DULCE PATRIA REGULAR",
			"DULYBOT DE MAIZ",
			"DULYBOT ENCHILADOS",
			"DULYBOT GARAPINADO",
			"DULYBOT MINI CUADRO",
			"DULYBOT MINI RUEDA",
			"DULYBOT PALITO",
			"DULYBOT PAPA",
			"DULYBOT SURTIDO",
			"DUPAQ BOTANITA",
			"DURAN CHURRO REGULAR",
			"DURAN MIX REGULAR",
			"DURAN PAPAS REGULAR",
			"DURAN POPCORN REGULAR",
			"DZ REGULAR",
			"EARTHS BEST REGULAR",
			"EAT NATURAL OATS MLD PWD REGULAR",
			"EATSMART SNYDERS NAT GARDEN VEGGIE STICKS",
			"ECONOMAX REGULAR",
			"EL ANGEL CORN REGULAR",
			"EL ANGEL NUTS REGULAR",
			"EL ANGEL POTATO REGULAR",
			"EL CHARRITO REGULAR",
			"EL CHAVO DEL OCHO VARIETY",
			"EL CHOCO REGULAR",
			"EL COMPA REGULAR",
			"EL CORTE CRACKERS",
			"EL CORTE INGLES REGULAR",
			"EL CORTE INGLES VARIETY",
			"EL CORTE REGULAR",
			"EL CORTE WAFER",
			"EL GORRIAGA REGULAR",
			"EL MAICITO REGULAR",
			"EL MAYO REGULAR",
			"EL ORIGINAL REGULAR",
			"EL PANQUE REGULAR",
			"EL REPULGO REGULAR",
			"EL REY REGULAR",
			"EL RINCON REGULAR",
			"EL RINCON REGULAR CHIP",
			"ELLEDI PARTY WAFER",
			"ELLEDI POKER WAFER",
			"EMPERADOR INTENSO",
			"EMPERADOR NOCTURNO",
			"EMPERADOR OTHER SUBBRAND",
			"EMPERADOR REGULAR",
			"EMPERADOR SENZO MIX",
			"EMPERADOR SIN AZUCAR",
			"EMPERADOR VARIETY",
			"EMPERADOR ZENTRO",
			"ENATURE REGULAR",
			"ENCANTO CHARRONES",
			"ENCANTO CHICHARRON DE CERDO",
			"ENCANTO CHICHOS",
			"ENCANTO CONCHITAS",
			"ENCANTO EXTRUDED REGULAR",
			"ENCANTO NUTS ENCHILADO",
			"ENCANTO NUTS SALADAS",
			"ENCANTO NUTS SEMILLA GIRASOL",
			"ENCANTO PAPAS ADOBADAS",
			"ENCANTO PAPAS QUESO",
			"ENCANTO PAPAS REGULAR",
			"ENCANTO PEPITA REGULAR",
			"ENCANTO TACHOS",
			"ENCANTO VARIETY REGULAR",
			"ENCREMADITAS REGULAR",
			"ESCAMILLAGAR REGULAR",
			"ESCOLAR REGULAR",
			"ESCOSA REGULAR",
			"ESMERALDA ENCHILADOS",
			"ESMERALDA REGULAR",
			"ESNAFER ENCHILADOS",
			"ESNAFER JAPONES",
			"ESNAFER REGULAR",
			"ESPIGA DORADA MARYS",
			"ESTEPHANIA FRITOS REGULAR",
			"ESTEPHANIA MIX",
			"ESTEPHANIA PALOMITAS REGULAR",
			"ESTEPHANIA VARIETY",
			"ESTRELLA REGULAR",
			"EXCELL CACAHUATE",
			"EXCELL CORN REGULAR",
			"EXCELL EXTRUDED REGULAR",
			"EXCELL TORTILLA REGULAR",
			"EXCELL VARIETY",
			"FAMOUS AMOS REGULAR",
			"FARM NUTS NUECES GOURMET HORNEADAS",
			"FCACHURROS CHURRO",
			"FCACHURROS FRITURA",
			"FCACHURROS PAPAS",
			"FIESTA CHURRITO PICOSITO",
			"FIESTA FRITURAS ANILLO",
			"FIESTA FRITURAS BOLITAS DE QUESO",
			"FIESTA FRITURAS MIX",
			"FIESTA FRITURAS OTHER",
			"FIESTA FRITURAS PALILLO",
			"FIESTA FRITURAS QUADRITO",
			"FIESTA FRITURAS RUEDIN",
			"FIESTA POPCORN RTE",
			"FIESTA POTATO REGULAR",
			"FIGGIN REGULAR",
			"FIILLER REGULAR",
			"FIORE DI PUGLIA",
			"FLOR DE NARANJO",
			"FLORECER REGULAR",
			"FORNO BONOMI",
			"FORTUNA REGULAR",
			"FOSSIER MACARON",
			"FRANCESITAS REGULAR",
			"FRIMEX CHICHARRON CERDO REGULAR",
			"FRIMEX CHICHARRON HARINA REGULAR",
			"FRIMEX PAPA DE HARINA REGULAR",
			"FRITEHSA CHICHARRON",
			"FRITEHSA ENCHILADOS",
			"FRITEHSA JAPONES",
			"FRITEHSA MIX",
			"FRITEHSA REGULAR",
			"FRITEHSA VARIETY",
			"FRITOS CHILE",
			"FRITOS CHORIZO",
			"FRITOS OTHER SUBBRAND",
			"FRITOS SAL",
			"FRITURIN CHICHARRON ENCHILADOS",
			"FRITURIN FRITURAS REGULAR",
			"FRITURIN NUTS REGULAR",
			"FRITURIN PAPAS",
			"FRITURIN POPCORN RTE REGULAR",
			"FRITURIN VARIETY MIX",
			"FRUBOTANAS ALMENDRA",
			"FRUBOTANAS MIXTO",
			"FRUITS REGULAR",
			"FRUTI ON LINE JAPONES",
			"FRUTY OHS REGULAR",
			"FUNYUNS REGULAR",
			"FYC CACAHUATE REGULAR",
			"FYC FRITOS REGULAR",
			"GABI BESOS",
			"GABI BUTTER",
			"GABI CHOCO CHOX",
			"GABI CLASICAS REGULAR",
			"GABI DANESAS",
			"GABI DISTROLLER",
			"GABI FINA VARIEDAD",
			"GABI LATA",
			"GABI MINI OREJA",
			"GABI OREJITAS",
			"GABI POLVORON",
			"GABI REGULAR",
			"GABI TARTAS REGULAR",
			"GABI VARIETY",
			"GABI WAFER REGULAR",
			"GAMABA REGULAR",
			"GAMES REGULAR",
			"GAMESA BIZCOCHITOS REGULAR",
			"GAMESA MARIAS AZUCARADAS",
			"GAMESA MARIAS CLASICA",
			"GAMESA MARIAS DORADAS",
			"GAMESA MARIAS MINI",
			"GAMESA SALADITAS CLASICA",
			"GAMESA TRAD ANIMALITOS REGULAR",
			"GAMESA TRAD CONSEN REGULAR",
			"GAMESA TRAD COOKIES BARRAS DE COCO",
			"GAMESA TRAD COOKIES DELICIAS",
			"GAMESA TRAD COOKIES HAWAIANAS",
			"GAMESA TRAD COOKIES MARAVILLAS",
			"GAMESA TRAD COOKIES MINI MARAVILLAS",
			"GAMESA TRAD COOKIES OTHER SUBBRAND",
			"GAMESA TRAD COOKIES POLVORON",
			"GAMESA TRAD COOKIES RICANELAS",
			"GAMESA TRAD COOKIES RULECITOS",
			"GAMESA TRAD FLORENTINAS",
			"GAMESA TRAD FRUTANA",
			"GAMESA TRAD MEXICANITAS REGULAR",
			"GAMESA TRAD PAKETINES",
			"GAMESA TRAD POPULARES",
			"GAMESA TRAD TENTACIONES PALITOS",
			"GAMESA TRAD TENTACIONES REALZADO",
			"GAMESA VARIETY PEKEPAKES",
			"GAMESA VARIETY REGULAR",
			"GANSITO REGULAR",
			"GAPI NATURAL",
			"GAPI REGULAR",
			"GAPSA ANGEL",
			"GAPSA ANIMALITOS",
			"GAPSA AVEO",
			"GAPSA BRINDIS",
			"GAPSA DORAS COCONUT",
			"GAPSA FELICIDAD",
			"GAPSA FESTIVAL",
			"GAPSA FIESTA",
			"GAPSA GOYESCA",
			"GAPSA MARIAS REGULAR",
			"GAPSA NAPOLITANA",
			"GAPSA OVALADAS",
			"GAPSA POBLANITA",
			"GAPSA REDONDAS",
			"GAPSA ROSSI",
			"GAPSA SALADAS",
			"GAPSA TRUKIS",
			"GARCIA REGULAR",
			"GARDI AVENA",
			"GARIBATOS ANILLO",
			"GARIBATOS CUADRO",
			"GARIBATOS RUEDA",
			"GARIBATOS TORNILLO",
			"GARZETA JAPONES",
			"GAVOTTES REGULAR",
			"GEIZHA JAPONES",
			"GERBER GRADUADOS",
			"GERMINOLA REGULAR",
			"GHIRADELLI REGULAR",
			"GI DESCONOCIDO REGULAR",
			"GIRO CHOCOLATE",
			"GISA ADORNADA",
			"GISA ANGELINA",
			"GISA ANIMALITOS",
			"GISA CHAMPAGNE",
			"GISA MARIAS REGULAR",
			"GISA OVALADAS",
			"GISA POLVORON",
			"GISA PRINGUITAS",
			"GISA REGULAR",
			"GISA VARIETY",
			"GLICO POCKY",
			"GLORIA ENCHILADOS",
			"GLORIA REGULAR",
			"GLUCK BOTANA VEGGIE STICKS NATURAL",
			"GLUTINO WAFERS",
			"GOLDEN NUTS ENCHILADO",
			"GOLDEN NUTS JAPONES",
			"GOLDEN NUTS LIMON",
			"GOLDEN NUTS MIXTO",
			"GOLDEN NUTS PEPITA REGULAR",
			"GOLDEN NUTS SALADAS",
			"GOLDEN NUTS TOSTADO",
			"GONAC CHECHI CHARRON CON CHILE",
			"GONAC CHECHI CHARRON ENCHILADOS",
			"GONAC CHECHI CHARRON HOT CHILE",
			"GONAC CHECHI CHARRON REGULAR",
			"GONAC CHECHI CHECHOS",
			"GONAC CHECHI CHECHOS CHILE",
			"GONAC CHECHI CHECHOS CON QUESO",
			"GONAC CHECHI CHECHOS QUESO",
			"GONAC CHECHI CHECHOS REGULAR",
			"GONAC CHECHI CON MANTEQUILLA",
			"GONAC CHECHI DONA",
			"GONAC CHECHI DONITA CON CHILE",
			"GONAC CHECHI DONITA CON LIMON",
			"GONAC CHECHI DONITA MEGA SAL CON LIMON",
			"GONAC CHECHI FRANCESA REGULAR",
			"GONAC CHECHI GIRAMIX REGULAR",
			"GONAC CHECHI HOT CHILE MIX",
			"GONAC CHECHI KIKYS CHILE",
			"GONAC CHECHI KIKYS FUEGO",
			"GONAC CHECHI KIKYS INTENSO",
			"GONAC CHECHI KIKYS MEGA AHUMADO",
			"GONAC CHECHI KIKYS MEGA HOT CHILE",
			"GONAC CHECHI KIKYS MEGA QUESO",
			"GONAC CHECHI KIKYS MINI AHUMADO",
			"GONAC CHECHI KIKYS MINI QUESO",
			"GONAC CHECHI KIKYS QUESO",
			"GONAC CHECHI KIKYS REGULAR",
			"GONAC CHECHI KIKYS SALSA NEGRA",
			"GONAC CHECHI MEGA QUESO",
			"GONAC CHECHI MICROMIX INTERMEDIA",
			"GONAC CHECHI MICROMIX PERSONAL",
			"GONAC CHECHI PALITOS",
			"GONAC CHECHI PALITOS CHILE",
			"GONAC CHECHI PALITOS CHILE CON LIMON",
			"GONAC CHECHI PALITOS HOT CHILE",
			"GONAC CHECHI PALITOS MEGA",
			"GONAC CHECHI POPS QUESO",
			"GONAC CHECHI POPS REGULAR",
			"GONAC CHECHI SALSA NEGRA",
			"GONAC CHECHI SURTIDO",
			"GONAC CHECHI TORCIDO QUESO",
			"GONAC CHECHI TORNADITOS VARIETY",
			"GONAC CHECHI TORNILLO CHILE",
			"GONAC CHECHI TORTILLA",
			"GONAC CHECHI TOS CHEETOS",
			"GONAC CHECHOS CHECHIMEGA CON QUESO",
			"GONAC CHECHOS QUESO",
			"GONAC CHILE",
			"GONAC KIUBO CHECHI TOTOPO KIUBO INTENSO",
			"GONAC KIUBO DIPACHOS",
			"GONAC KIUBO OH RINGS REGULAR",
			"GONAC KIUBO PAPA CASERA",
			"GONAC KIUBO PAPA CASERA FUEGO",
			"GONAC KIUBO PAPA CASERA HABANERO",
			"GONAC KIUBO PAPA CASERA MEGA FUEGO",
			"GONAC KIUBO PAPA CASERA MEGA ONDULADAS ZIGZAG QUESO",
			"GONAC KIUBO PAPA CASERA SAL DE MAR",
			"GONAC KIUBO PAPA CASERA SALSA NEGRA",
			"GONAC KIUBO PAPA ONDULADA",
			"GONAC KIUBO PAPAS FUEGO",
			"GONAC KIUBO PAPAS QUESO",
			"GONAC KIUBO PAPAS REGULAR",
			"GONAC KIUBO PAPAS SAL",
			"GONAC KIUBO PIQUESO CON CHILE",
			"GONAC KIUBO RE MIX INTENSO",
			"GONAC KIUBO RE MIX MEGA",
			"GONAC KIUBO RE MIX QUESO CHILE",
			"GONAC KIUBO RE MIX QUESO SALSA VERDE",
			"GONAC KIUBO RE MIX REGULAR",
			"GONAC KIUBO REMIX JUMBO",
			"GONAC KIUBO TOTOPO INTENSO",
			"GONAC KIUBO TOTOPO MEGA EXTREMO",
			"GONAC KIUBO TOTOPO MEGA MEXICANA",
			"GONAC KIUBO TOTOPO MEGA NACHO",
			"GONAC KIUBO TOTOPO REGULAR",
			"GONAC KIUBO TOTOPO SALSA NEGRA",
			"GONAC KIUBO TOTOPO TOSTACOS",
			"GONAC KIUBO TROMPIQUESO",
			"GONAC OTHER",
			"GONZALEZ BANDERAS",
			"GONZALEZ ENCHILADOS",
			"GONZALEZ JAPONES",
			"GONZALEZ PAPA",
			"GONZALEZ REGULAR",
			"GOT MILK WAFERS",
			"GRANDMAS FACTORY",
			"GRANELES AROS DE FRUTA",
			"GRANO DE ORO REGULAR",
			"GRANOLA FLAKES REGULAR",
			"GRISBI REGULAR",
			"GRISS PALITOS",
			"GRUPO18 CUADROS ENCHILADOS",
			"GRUPO18 LAGRIMA NATURAL",
			"GRUPO18 PALOMITAS SAL",
			"GRUPO18 RUEDA NATURAL",
			"GRUPO18 TAKIN REGULAR",
			"GRUPO18 VARIETY SURTIDO",
			"GUAMAZO JAPONES",
			"GUATOSO CORN REGULAR",
			"GUERRERO PAPAS ORIGINAL",
			"GUERRERO REGULAR",
			"GUERRERO SURTIDO",
			"GUILLEN FUEGO",
			"GUILLEN HABANERO",
			"GUILLEN JALAPENO",
			"GUILLEN QUESO",
			"GUILLEN REGULAR",
			"GUIMORI CHURRO",
			"GUIMORI FRICOSA",
			"GUIMORI JAPONES",
			"GUIMORI PALOMITAS REGULAR",
			"GUIMORI PELLET REGULAR",
			"GUIMORI POTATO SUBBRAND",
			"GUIMORI PUKU",
			"GULLON CKY REGULAR",
			"GULLON DIET NATURE",
			"GULLON MARIAS REGULAR",
			"GULLON SALADAS",
			"GUTI REGULAR",
			"HABANERAS BRAN",
			"HABANERAS CLASICAS",
			"HABANERAS HONEY REGULAR",
			"HABANERAS INTEGRALES",
			"HABIBI JAPONES",
			"HABIBI MIXTA",
			"HABIBI PEPITA",
			"HABIBI VARIETY",
			"HAPPY SNACKS REGULAR",
			"HARLEQUIN REGULAR",
			"HAYDEN PRETZELS",
			"HEB OTHER COOKIES",
			"HECHO A MANO REGULAR",
			"HENRYS BAKERY REGULAR",
			"HOLICHURROS REGULAR",
			"HOT NUTS CUBIERTO DE CHILE",
			"HOT NUTS FUEGO",
			"HOUBER REGULAR",
			"IGOMEZ NACHO",
			"IGOMEZ REGULAR",
			"ITAKATE CACAHUATE REGULAR",
			"ITAKATE CHICHARRON",
			"ITAKATE CHURRITO",
			"JAVY CHURRO",
			"JAVY POTATO",
			"JAVY REGULAR",
			"JAVY VARIETY",
			"JHONY TOTOPO",
			"JOMET PAPA SAL",
			"JOMET RANCHERITOS",
			"JOMET REGULAR",
			"JOSANPO REGULAR",
			"JOSO REGULAR",
			"JOSO VARIETY",
			"JOVI CHICHARRONES",
			"JOVI CHILE",
			"JOVI RANCHERITOS",
			"JOVI REGULAR",
			"JUAN REGULAR",
			"JUANINAS TARTALETAS",
			"JUANINAS VARIETY",
			"JUANITA BANDERILLA",
			"JUANITA BARRA",
			"JUANITA CHOCOLATE CHIPS",
			"JUANITA DANISA",
			"JUANITA ESTRELLA",
			"JUANITA MEDIA LUNA",
			"JUANITA OREJA",
			"JUANITA PASTISETA",
			"JUANITA POLVORON",
			"JUANITA REGULAR",
			"JUANITA TARTALETA",
			"JUANITA VARIETY",
			"JULIMOY CHICH CERDO",
			"JULIMOY CHICH HARINA",
			"JULIMOY CORNCHIPS",
			"JULIMOY ENCHILADOS",
			"JULIMOY FUEGO",
			"JULIMOY HABANERO",
			"JULIMOY JALAPENO",
			"JULIMOY LIMON",
			"JULIMOY MOYSHITOS",
			"JULIMOY PALOMITAS",
			"JULIMOY QUESO",
			"JULIMOY REGULAR",
			"JULIMOY SAL",
			"JULIMOY VARIETY",
			"JULIMOY VARIETY MIX",
			"JVENTURA REGULAR",
			"K-NELA REGULAR",
			"KACANG AJO Y CHILE",
			"KACANG BLAZIN",
			"KACANG ENCHILADO",
			"KACANG FLAMIN HOT",
			"KACANG HABANERO",
			"KACANG INCOGNITA",
			"KACANG JAPONES",
			"KACANG NUT MIX",
			"KACANG OTHER SUBBRAND",
			"KACANG PEPITA REGULAR",
			"KACANG PLANILLA",
			"KACANG SAL",
			"KAKAWUE ENCHILADOS",
			"KAKAWUE JAPONES",
			"KAKAWUE REGULAR",
			"KANIMI LENTEJAS",
			"KARATE ENCHILADO",
			"KARATE JAPONES",
			"KARATE OTHER SUBBRAND",
			"KARATE SALSA NEGRA",
			"KARINA JAPONES",
			"KARLA JAPONES",
			"KATANA JAPONES",
			"KATANA VARIETY",
			"KATOS JAPONES",
			"KCHETONES CHEETOS FUEGO",
			"KCHETONES CHEETOS JALAPENO",
			"KCHETONES QUESO",
			"KE CHURRITOS CON AJONJOLI",
			"KEEBLER CHIPS DELUXE",
			"KEEBLER FUDGE STRIPES",
			"KEEBLER OTHER COOKIES",
			"KEEBLER OTHER REGULAR",
			"KEEBLER SANDIES",
			"KELSEN DANES",
			"KENPO JAPONES",
			"KEOPS ALMENDRA",
			"KETTLE REGULAR",
			"KI SNACKS HABANERO",
			"KI SNACKS MIX",
			"KI SNACKS QUESO",
			"KI SNACKS REGULAR",
			"KI SNACKS SAL",
			"KIKIRIK ENCHILADOS",
			"KIKIRIK JAPONES",
			"KIKIRIK REGULAR",
			"KIMONO JAPONES",
			"KIMONO REGULAR",
			"KIPI BOFITOS",
			"KIPI CHURRITOS",
			"KIPI FUEGO",
			"KIPI MIX REGULAR",
			"KIPI REGULAR",
			"KKWATE CHILIOS",
			"KKWATE ENCHILADO",
			"KKWATE JAPONES",
			"KKWATE MIXED",
			"KKWATE OTHER SUBBRAND",
			"KKWATE PEPITA REGULAR",
			"KKWATE SAL",
			"KOCHI REGULAR",
			"KOIN KOIN REGULAR",
			"KOIO CHILE",
			"KOIO CHIPOTLE",
			"KOIO HABANERO",
			"KOIO NATURAL",
			"KOIO QUESO",
			"KOIO REGULAR",
			"KOIO VARIETY",
			"KOKIS ENCHILADOS",
			"KOKIS JAPONES",
			"KOKIS MIXTA",
			"KOKIS SALADO",
			"KRIS TOTOPOS",
			"KUCHENMEISTER REGULAR",
			"KURUMAYA ENCHILADOS",
			"KURUMAYA JAPONES",
			"KURUMAYA REGULAR",
			"KURUMAYA VARIETY",
			"LA BOTANA ENCHILADOS",
			"LA BOTANA JAPONES",
			"LA BOTANA REGULAR",
			"LA BOTANA VARIETY",
			"LA CASA DE REGULAR",
			"LA FE TOTOPO RANCHERO DESHIDRATADO",
			"LA FUENTE INTEGRAL",
			"LA FUENTE REGULAR",
			"LA GIRALDA REGULAR",
			"LA INTEGRAL OATS",
			"LA INTEGRAL REGULAR",
			"LA INTEGRAL RUEDITAS",
			"LA INTEGRAL TERRONES",
			"LA INTEGRAL TOSTADITAS",
			"LA ITALIANA ANIMALITOS",
			"LA ITALIANA MARIAS",
			"LA ITALIANA OVALADA",
			"LA ITALIANA TRADITIONAL",
			"LA LIBANESA REGULAR",
			"LA MARISCA REGULAR",
			"LA MEXICANA CACHUATE REGULAR",
			"LA MEXICANA CHETO REGULAR",
			"LA MEXICANA CHICHARRON REGULAR",
			"LA MEXICANA CHURRITOS REGULAR",
			"LA MEXICANA PALOMITAS",
			"LA MEXICANA PAPAS",
			"LA MEXICANA PELLET REGULAR",
			"LA MEXICANA PLATANITOS REGULAR",
			"LA MEXICANA SALTY MIX REGULAR",
			"LA MORISCA REGULAR",
			"LA OLIVA REGULAR",
			"LA PANZANELLA REGULAR",
			"LA PECOSITA PASTICETA",
			"LA PECOSITA POLVORON",
			"LA PECOSITA VARIETY",
			"LA PROVIDENCIA JAPONES",
			"LAMBERTZ REGULAR",
			"LAMBERTZ VARIETY",
			"LANCE CRACKER REGULAR",
			"LANCE SANDWICH REGULAR",
			"LARA ANIMALITOS",
			"LARA CANAPINAS REGULAR",
			"LARA CHOCO CHIPS REGULAR",
			"LARA CREMAS REGULAR",
			"LARA CRENETAS REGULAR",
			"LARA DE CANELAS",
			"LARA DELICIOSAS CHOCOLATE",
			"LARA DELICIOSAS MARSHMALLOW REGULAR",
			"LARA DELICIOSAS REGULAR",
			"LARA MAGNAS REGULAR",
			"LARA MANTEQUILLITAS",
			"LARA MARIAS REGULAR",
			"LARA SALADAS REGULAR",
			"LARA TARTINAS REGULAR",
			"LARA TROPICOCOS",
			"LARA VARIETY",
			"LAS DELICIAS CHOCO CRUNCH",
			"LAS DELICIAS CRUN CHU",
			"LAS DELICIAS NUTS REGULAR",
			"LAS DELICIAS WAFER REGULAR",
			"LAS DOS NACIONES REGULAR",
			"LAS DOS NACIONES SODA",
			"LAS LECHITAS REGULAR",
			"LAS SOMBRILLAS REGULAR",
			"LAYS DIPS REGULAR",
			"LAYS REGULAR",
			"LECLERC CELEBRATION",
			"LECLERC REGULAR",
			"LENNY LARRY REGULAR",
			"LEO CACAHUATE JAPONES",
			"LEO CACAHUATE SALADO",
			"LEO CHICH DE CERDO REGULAR",
			"LEO CHICH HARINA REGULAR",
			"LEO CORN REGULAR",
			"LEO EXTRUDED REGULAR",
			"LEO PAPAS ADOBADAS",
			"LEO PAPAS ONDULADAS",
			"LEO PEPITA REGULAR",
			"LEO POPCORN REGULAR",
			"LEO TORTILLA REGULAR",
			"LEO VARIETY REGULAR",
			"LEON CORN CHIPS",
			"LEON PORKRIND",
			"LEON SALADAS",
			"LIL DUTCH MAID REGULAR",
			"LIL DUTCH MAID VARIETY",
			"LILI COMBINADOR",
			"LILI REGULAR",
			"LILI ROSCAS",
			"LILYOCC CHURRO",
			"LILYOCC REGULAR",
			"LITTLE DEBBIE REGULAR",
			"LIVEM REGULAR",
			"LOACKER REGULAR",
			"LOACKER WAFERS",
			"LOC MARIAS",
			"LORENZ SALADITAS",
			"LORENZ VARIETY",
			"LORIN ENCHILADOS",
			"LORIN JAPONES",
			"LORIN POPCORN",
			"LORIN REGULAR",
			"LORS REGULAR",
			"LOS CAMPEONES REGULAR",
			"LOS COYOTES BOTANAZO",
			"LOS COYOTES BOTANONON FUEGO",
			"LOS COYOTES CHICH DE CERDO",
			"LOS COYOTES CHICHARRON",
			"LOS COYOTES CHURRO",
			"LOS COYOTES FUEGO",
			"LOS COYOTES HABANERO",
			"LOS COYOTES JALAPENO",
			"LOS COYOTES MIX",
			"LOS COYOTES NACHO",
			"LOS COYOTES NACHO EL REPARO",
			"LOS COYOTES NATURAL",
			"LOS COYOTES PAPA ADOBADA",
			"LOS COYOTES PAPA HABANERAS",
			"LOS COYOTES PAPA MIX",
			"LOS COYOTES PAPA PAPATIAS",
			"LOS COYOTES PAPA RAYADITAS CON QUESO",
			"LOS COYOTES PAPA TAPATIA CASERA",
			"LOS COYOTES POPCORN REGULAR",
			"LOS COYOTES QUESO",
			"LOS COYOTES REGULAR",
			"LOS COYOTES SALSA",
			"LOS COYOTES SNACK MIX",
			"LOS REYES CACAHUATE",
			"LOS REYES EXTRUDED REGULAR",
			"LOS REYES JAPONES",
			"LOS REYES MIX",
			"LOS REYES NACHOS AL COMAL",
			"LOS REYES PAPA FRITA",
			"LOS REYES REGULAR",
			"LOS REYES TOTOPO NATURAL ENCHILADO",
			"LOS TRADICIONALES REGULAR",
			"LOTUS BISCOFF",
			"LOURDES CORN REGULAR",
			"LOURDES PORK RINDS REGULAR",
			"LOURDES REGULAR",
			"LOURDES RETRO QUESITAS",
			"LOVIES MIXTO",
			"LOVIES PORK REGULAR",
			"LOVIES RANCHERITOS",
			"LOVIES SALADO",
			"LU REGULAR",
			"LUCKY COOKIES REGULAR",
			"LUICAM REGULAR",
			"LULITAS REGULAR",
			"LULU TRADITIONAL",
			"LULU VARIETY",
			"LUNA CHURRITOS SURTIDO AMARANTO",
			"LUNDBERG TORTAS",
			"LUPITA HUESITOS",
			"LUPITA SANDWICH REGULAR",
			"LUPITA SUSPIROS",
			"LUZMA CHURRITOS",
			"LUZMA PORK RINDS REGULAR",
			"LUZMA TOTOPO",
			"M.G. CHURRITOS REGULAR",
			"M.G. PALOMITAS REGULAR",
			"MACARENAS REGULAR",
			"MACMA ABANICO",
			"MACMA BARQUILLO",
			"MACMA BARQUILLO REGULAR",
			"MACMA BOCADO",
			"MACMA CAPPUCCINO",
			"MACMA CHISPAS",
			"MACMA CHOCCO",
			"MACMA COPO DE NUEZ",
			"MACMA CRIPS",
			"MACMA CRISPAS",
			"MACMA DONCELLAS",
			"MACMA DULAT",
			"MACMA FIGURA",
			"MACMA FRESA",
			"MACMA GAUFRETTE",
			"MACMA GOFRENATA",
			"MACMA HAZELNUT",
			"MACMA NATA",
			"MACMA OTHER SUBBRAND",
			"MACMA PARISIEN",
			"MACMA VARIEDADES REGULAR",
			"MACMA VARIEDADES TRADICIONAL",
			"MACMA VARIETY",
			"MACMA WAFER REGULAR",
			"MADERA REGULAR",
			"MAFER CREMAS Y ESPECIAS",
			"MAFER ENCHILADO",
			"MAFER JALAPENO",
			"MAFER JAPONES CLASICO",
			"MAFER JAPONES LIMON",
			"MAFER MEXCALISMO",
			"MAFER MIX ARANDANO",
			"MAFER MIX FRESA",
			"MAFER MIX MANZANA",
			"MAFER MIX VARIETY",
			"MAFER OTHER SUBBRAND",
			"MAFER SAL",
			"MAFER SIN SAL",
			"MAFER SURTIDO ENCHILADO",
			"MAFER SURTIDO SALADO",
			"MAFER TOSTADO SAZONADO",
			"MAGIC WAFER",
			"MAIZORO ARITOS CLASICA",
			"MAIZORO AZUCARADAS",
			"MAIZORO CHOCO CRONCHI",
			"MAIZORO CHOCO FLAKES CLASICO",
			"MAIZORO CORN FLAKES REGULAR",
			"MAKANA JAPONES",
			"MAKY ENCHILADOS",
			"MAKY REGULAR",
			"MAMA CONEJA QUESO",
			"MAMA CONEJA REGULAR",
			"MAMUT BLANCO",
			"MAMUT CLASICO",
			"MAMUT FLIPY",
			"MAMUT MINI",
			"MAMUT OTHER SUBBRAND",
			"MANASUR REGULAR",
			"MANOLITO PELLET REGULAR",
			"MANOLITO POPCORN REGULAR",
			"MANOLITO POTATO REGULAR",
			"MANOLITO SNACK MIX REGULAR",
			"MANZELA CHURRITOS CHILE",
			"MANZELA ENCHILADOS",
			"MANZELA JAPONES",
			"MANZELA REGULAR",
			"MANZELA SAL",
			"MANZELA VARIETY",
			"MARIAN BOLITAS",
			"MARIAN NOCHE BUENA",
			"MARIAN OREJITAS",
			"MARIAN REGULAR",
			"MARIAN TARTALETA REGULAR",
			"MARIAN TRAZZOS",
			"MARIAN VARIETY",
			"MARIBEL ANIMALITOS",
			"MARIBEL BETUNADA",
			"MARIBEL BETUNADAS MINI",
			"MARIBEL CHIPS",
			"MARIBEL CHISPITAS",
			"MARIBEL DORADAS",
			"MARIBEL IN HOME REGULAR",
			"MARIBEL KIDS",
			"MARIBEL KIDS VARIETY",
			"MARIBEL MARIAS REGULAR",
			"MARIBEL NAPOLI REGULAR",
			"MARIBEL SALADITAS REGULAR",
			"MARIBEL SANDWICH REG",
			"MARIBEL TAPATIAS",
			"MARIBEL TOSCANA",
			"MARIBEL TROPICUQUI",
			"MARINAS REGULAR",
			"MARINELA FLOW REGULAR",
			"MARINELA PINGUINOS",
			"MARINELA POLVORON BITES",
			"MARINELA POLVORON BITES MIX",
			"MARINELA POLVORON REGULAR",
			"MARINI REGULAR",
			"MARIQUITAS ENCHILADOS",
			"MARIQUITAS JAPONES",
			"MARIQUITAS LIMON",
			"MARIQUITAS REGULAR",
			"MARISEL ANIMALITOS",
			"MARISOL CHURRO",
			"MARITELY REGULAR",
			"MARK CHILE",
			"MARK REGULAR",
			"MARSAM AJONJOLI",
			"MARSAM QUESO",
			"MARSAM REGULAR",
			"MARTINEZ CORN REGULAR",
			"MARTINEZ JAPONES",
			"MARTINEZ REGULAR",
			"MARTINS REGULAR",
			"MARY ENCHILADOS",
			"MARY JAPONES",
			"MARY LIMON",
			"MARY NATURAL",
			"MARY REGULAR",
			"MARY VARIETY",
			"MAS BOTANAS ENCHILADOS",
			"MAS BOTANAS JAPONES",
			"MAS BOTANAS REGULAR",
			"MASCOTAS REGULAR",
			"MASQUERADE REGULAR",
			"MATCHA KAORI REGULAR",
			"MATILDE VICENZI REGULAR",
			"MATRACAS JAPONES",
			"MATRACAS REGULAR",
			"MAXIMA MARIAS",
			"MAYORAL CHIPOTLE",
			"MAYORAL CHURRO",
			"MAYORAL ENCHILADOS",
			"MAYORAL INTENSO",
			"MAYORAL LIMON",
			"MAYORAL NATURAL",
			"MAYORAL REGULAR",
			"MCKEE REGULAR",
			"MCVITIES DIGESTIVES",
			"MEBER ENCHILADOS",
			"MEBER JAPONES",
			"MEBER MIX",
			"MEBER REGULAR",
			"MEIJI HELLO PANDA",
			"MELY REGULAR",
			"MERBA REGULAR",
			"MERZA PAPAS",
			"METRO ENCHILADOS",
			"METRO JAPONES",
			"METRO REGULAR",
			"MEXIPON CHILE",
			"MEXIPON CHIPOTLE",
			"MEXIPON HABANERO",
			"MEXIPON JAPONES",
			"MEXIPON REGULAR",
			"MI CARRITO CHURRO",
			"MI CARRITO EXTRUIDO",
			"MI CARRITO SAL",
			"MI REINA REGULAR",
			"MILAGROS REGULAR",
			"MILI VARIETY",
			"MILLERS INSTANT REGULAR",
			"MILLERS OATS REGULAR",
			"MILTONS CRACKERS",
			"MISURA CHOC CHIP REGULAR",
			"MISURA CKY FILLED REGULAR",
			"MISURA CRACKER REGULAR",
			"MISURA REGULAR",
			"MM JAPONES",
			"MM REGULAR",
			"MONTERREY REGULAR",
			"MORIMOTO REGULAR",
			"MORO CHILE",
			"MORO REGULAR",
			"MORO VTY REGULAR",
			"MORUSAS REGULAR",
			"MOY CHICHARRONES DE CERDO",
			"MOY CORN REGULAR",
			"MOY PELLET LIMON",
			"MOY POTATO REGULAR",
			"MOY VTY PK REGULAR",
			"MR. SAULO REGULAR",
			"MRS FIELDS REGULAR",
			"MRS FRESHLEYS REGULAR",
			"MULIX FUEGO",
			"MULIX JAPONES",
			"MULTI REGULAR",
			"MUNCHIES REGULAR",
			"MURA REGULAR",
			"MUY NATURAL AVENA NATURAL",
			"MUY NATURAL CHURRITOS DE MAIZNOPAL",
			"NACHOS TITO NACHO",
			"NACHOS TITO VARIETY",
			"NACIONAL REGULAR",
			"NALFRE REGULAR",
			"NANCY REGULAR",
			"NATUCHIPS CHILE",
			"NATUCHIPS SAL",
			"NATURAL BALANCE REGULAR",
			"NATURAL BITS REGULAR",
			"NATURAL NECTAR REGULAR",
			"NATURES GARDEN REGULAR",
			"NATURES HEART BARS REGULAR",
			"NATURES HEART NUEZ",
			"NATURES HEART OATS REGULAR",
			"NB CHILE",
			"NB CHURRO",
			"NB JALAPENO",
			"NB LIMON",
			"NB NACIONAL AVIONCHITO",
			"NB QUESO",
			"NB REGULAR",
			"NB SALSA",
			"NB VARIETY",
			"NEY CHETO CHILE",
			"NEY CHURRO",
			"NEY DONITA LIMON",
			"NEY JAPONES",
			"NEY PALOMITAS MANTEQUILLA",
			"NINJA JAPONES",
			"NIPON ENCHILADO",
			"NIPON JAPONES",
			"NIPON REGULAR",
			"NITSUGA CHIPOTLE",
			"NOGLUT REGULAR",
			"NOPALIA CHURRITOS CHILE",
			"NOPALIA CHURRITOS CHIPOTLE",
			"NOPALIA CHURRITOS HABANERO",
			"NOPALIA CHURRITOS LIMON",
			"NOPALIA TOSTADAS REGULAR",
			"NORIKO ENCHILADOS",
			"NORIKO JAPONES",
			"NORIKO REGULAR",
			"NORIKO VARIETY",
			"NORVER REGULAR",
			"NUECES REGULAR",
			"NUEVO LEON FRITURAS",
			"NUEVO LEON FRITURAS POTATO",
			"NUTELLA BISCUITS",
			"NUTRESA COOL NUTS CHILE",
			"NUTRESA COOL NUTS JAPONES",
			"NUTRESA COOL NUTS REGULAR",
			"NUTRESA COOL NUTS SAL",
			"NUTRESA REGULAR",
			"NUTRI CEREAL",
			"NUTRI WEEL TOSTADITAS",
			"NUTRIBERRI REGULAR",
			"NUTRIFLAKES REGULAR",
			"NUTRIGAL REGULAR",
			"NUTRIGAL TRADITIONAL",
			"NUTRIGAL VARIETY",
			"NUTRIGAL ZERTUCHE",
			"NUTRIMAX REGULAR",
			"NUTRISA REGULAR",
			"NUTRITAS REGULAR",
			"NUTRIVIT REGULAR",
			"NUTRIWELL REGULAR",
			"NYKIS JAPONES",
			"OAT REVOLUTION REGULAR",
			"OCAMPO REGULAR",
			"OCAMPO VARIETY",
			"OCHOA TOTOPO",
			"OKKO REGULAR",
			"OLD LONDON REGULAR",
			"OLVERA REGULAR",
			"ORIGINAL GOURMET REGULAR",
			"OSMA CHURRO",
			"OSMA ENCHILADOS",
			"OSMIT REGULAR",
			"OTHER BARS NUTR",
			"OTHER BARS OTHER",
			"OTHER BFYCRK SALTY SUBBRAND",
			"OTHER BISCUITS SUBBRAND",
			"OTHER BISCUITS VARIETY",
			"OTHER CEREAL ADULT",
			"OTHER CEREAL ADULT SUBBRAND",
			"OTHER CEREAL KIDS",
			"OTHER CHOC CHIP",
			"OTHER CHOC COVERED",
			"OTHER CKY BFY SUBBRAND",
			"OTHER CKY CHOC SUBBRAND",
			"OTHER CKY OTHER SUBBRAND",
			"OTHER CKY OTHER SUBBRAND SOPA",
			"OTHER CKY TRAD SUBBRAND",
			"OTHER COOKIES LE",
			"OTHER CRK IN HOME OTHER SUBBRAND",
			"OTHER CRK OTHER SUBBRAND SAVORY",
			"OTHER CRK PLAIN SUBBRAND",
			"OTHER CRK RICE SUBBRAND",
			"OTHER CRK SALTINE OTHER SUBBRAND",
			"OTHER FILLED",
			"OTHER GRANOLA",
			"OTHER MARIAS",
			"OTHER NUTS SUBBRAND",
			"OTHER OATS INSTANT",
			"OTHER OATS INSTANT SUBBRAND",
			"OTHER OATS MILD PWD",
			"OTHER OATS MILD PWD SUBBRAND",
			"OTHER OATS NATURAL",
			"OTHER OATS NATURAL SUBBRAND",
			"OTHER OATS TRAD SUBBRAND",
			"OTHER OTHER COOKIES",
			"OTHER PELLET SUBBRAND",
			"OTHER PEPSICO BISCUITS SUBBRAND",
			"OTHER PEPSICO FOODS",
			"OTHER PEPSICO SAVORY SUBBRAND",
			"OTHER POPCORN MW SUBBRAND",
			"OTHER POPCORN RTE SUBBRAND",
			"OTHER PORK RINDS SUBBRAND",
			"OTHER POTATO SUBBRAND",
			"OTHER SALTY MIX SUBBRAND",
			"OTHER SANDWICH",
			"OTHER SAV CORN SUBBRAND",
			"OTHER SAV EXTRUDED SUBBRAND",
			"OTHER SAV OTHER SUBBRAND",
			"OTHER SAV WHEAT",
			"OTHER SAVORY CORN",
			"OTHER SAVORY EXTRUDED",
			"OTHER SAVORY NUTS",
			"OTHER SAVORY OTHER",
			"OTHER SAVORY PELLET",
			"OTHER SAVORY POPCORN MW",
			"OTHER SAVORY POPCORN RTE",
			"OTHER SAVORY PORK RINDS",
			"OTHER SAVORY POTATO",
			"OTHER SAVORY SALTY MIX",
			"OTHER SEMILLAS",
			"OTHER TORTILLA",
			"OTHER TORTILLA SUBBRAND",
			"OTHER VARIETY PK",
			"OTHER WAFERS",
			"OYIUKY REGULAR",
			"OYUKI CHICHARRONES",
			"OYUKI CHURRITO",
			"OYUKI FRITURAS MEGA",
			"OYUKI NUTS & SEEDS",
			"OYUKI PALOMITAS DE CARAMELO",
			"OYUKI RANCHERO REGULAR",
			"PACOYOS FRITURA HARINA REGULAR",
			"PACOYOS REGULAR",
			"PAGASA ANIMALITOS",
			"PAGASA ANTILLANAS",
			"PAGASA BETUNADAS",
			"PAGASA CESARINAS",
			"PAGASA GRANADINAS",
			"PAGASA MARIAS REGULAR",
			"PAGASA PICOLINAS",
			"PAGASA POLVORON",
			"PAGASA POPULAR",
			"PAGASA PRINCESA",
			"PAGASA REGINAS",
			"PAGASA REGIONALES",
			"PAGASA SANDWICH",
			"PAGASA WAFER REGULAR",
			"PAKETAXO BOTANERO",
			"PAKETAXO MEZCLADITO",
			"PAKETAXO PASUMECHA",
			"PAKETAXO QUEXO",
			"PAKETAXO VARIETY",
			"PAN BUENO BFY",
			"PAN BUENO OREJAS",
			"PAN DUCALE REGULAR",
			"PAN EUROPEO REGULAR",
			"PANCREMA CLASICA",
			"PAPADOPOULOS REGULAR",
			"PAPATINAS CATSUP",
			"PAPATINAS CHILE EN VINAGRE",
			"PAPATINAS CHILIX",
			"PAPATINAS FUEGO",
			"PAPATINAS OTHER SUBBRAND",
			"PAPATITAS",
			"PAQUETE PINATA REGULAR",
			"PARIOS CHIPS REGULAR",
			"PARLE GALLETAS",
			"PARLE MAGIX",
			"PARRAS REGULAR",
			"PELAYO CHURRO",
			"PELAYO REGULAR",
			"PEPE CHILE",
			"PEPE QUESO",
			"PEPERO REGULAR",
			"PEPITO CACAHUATES",
			"PEPITO CHURRITOS REGULAR",
			"PEPITOS DONAS REGULAR",
			"PEPON JAPONES",
			"PEPON SAL",
			"PEPPERIDGE FARM CHESSMEN",
			"PEPPERIDGE FARM CHUNK",
			"PEPPERIDGE FARM GOLDFISH REGULAR",
			"PEPPERIDGE FARM MILANO",
			"PEPPERIDGE FARM REGULAR",
			"PEPPERIDGE FARM VERONA",
			"PICOREY CHARRITOS REGULAR",
			"PICOREY CHICHARRON REGULAR",
			"PICOREY CHIWIZ REGULAR",
			"PICOREY ENCHILADOS",
			"PICOREY MIX",
			"PICOREY PALITOS PAPA",
			"PICOREY PAPA",
			"PICOREY POPCORN REGULAR",
			"PIKIS CACAHUATE REGULAR",
			"PIKIS CUADROS REGULAR",
			"PIKIS DONITAS REGULAR",
			"PIKIS PAPAS NATURAL",
			"PILLSBURY REGULAR",
			"PINOLITAS REGULAR",
			"PIPO CACAHUATE SALADO",
			"PIPO CHURRITOS REGULAR",
			"PIZZEROLAS REGULAR",
			"PLATIVOLOS REGULAR",
			"POFFETS CARAMELO",
			"POFFETS CHILE",
			"POFFETS CHOCOLATE",
			"POFFETS MW EXTRA MANTEQUILLA",
			"POFFETS MW MANTEQUILLA",
			"POFFETS MW MANTEQUILLA LIGHT",
			"POFFETS MW NATURAL",
			"POFFETS MW OTHER SUBBRAND",
			"POFFETS OTHER SUBBRAND",
			"POFFETS QUESO",
			"POFFETS QUESO CHIPOTLE",
			"POPCORNERS REGULAR",
			"POPWORKS CARAMELO",
			"PORK RIND CHICH DE CERDO",
			"PORKI REGULAR",
			"PORTICO JAPONES",
			"PRIMA ANIMALITOS",
			"PRIMA MARIAS",
			"PRIMEAL REGULAR",
			"PRINCIPE REGULAR",
			"PRINCIPE TENTACION",
			"PRINGUITAS REGULAR",
			"PRIVATE LABEL BARS",
			"PRIVATE LABEL COOKIES",
			"PRIVATE LABEL COOKIES ANIMALITOS",
			"PRIVATE LABEL COOKIES MARIAS",
			"PRIVATE LABEL COOKIES TRADITIONAL",
			"PRIVATE LABEL CORN",
			"PRIVATE LABEL EXTRUDED",
			"PRIVATE LABEL HEALTHY SNACK",
			"PRIVATE LABEL OATS",
			"PRIVATE LABEL OTHER",
			"PRIVATE LABEL POPCORN MW",
			"PRIVATE LABEL POPCORN RTE",
			"PRIVATE LABEL PORK RINDS",
			"PRIVATE LABEL POTATO",
			"PRIVATE LABEL SALTY MIX",
			"PRIVATE LABEL SAVORY",
			"PRIVATE LABEL SAVORY NUTS & SEEDS",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL VARIETY PK",
			"PROFE MIX",
			"PROPLASTEC REGULAR",
			"PROVI CORN REGULAR",
			"PROVI ENCHILADO",
			"PROVI ESPANOL",
			"PROVI HABA",
			"PROVI JAPONES",
			"PROVI MIXTO",
			"PROVI PORK RINDS REGULAR",
			"PROVI POTATO REGULAR",
			"PROVI REGULAR",
			"PROVI SALADAS",
			"PROVI SALTY MIX REGULAR",
			"PUMA ENCHILADOS",
			"PUMA JAPONES",
			"PUMA SALADO",
			"PUMA VARIETY",
			"Q PAPAS REGULAR",
			"QUAKER BARS CHEWY REGULAR",
			"QUAKER BARS FRUIT FLATS",
			"QUAKER BARS FRUIT REGULAR",
			"QUAKER BARS OTHER SUBBRAND",
			"QUAKER BREAKFAST REGULAR",
			"QUAKER CEREAL AVENA FLAKES",
			"QUAKER CEREAL BALANCE COMPLETE",
			"QUAKER CEREAL CUADRITOS",
			"QUAKER CEREAL GRANOLA 100PCT NATURAL",
			"QUAKER CEREAL KIDS EMPERADOR",
			"QUAKER CEREAL KIDS OHS",
			"QUAKER CEREAL KIDS STARS",
			"QUAKER CEREAL LIFE",
			"QUAKER CEREAL MULTIBRAN",
			"QUAKER CEREAL REGULAR",
			"QUAKER GALLETAS 3 MINUTOS",
			"QUAKER GALLETAS ARTESANAS",
			"QUAKER GALLETAS REGULAR",
			"QUAKER GALLETAS SIN AZUCAR",
			"QUAKER OATS 3 MINUTOS",
			"QUAKER OATS INSTANT CUPS",
			"QUAKER OATS INSTANT NO SUGAR",
			"QUAKER OATS INSTANT REGULAR",
			"QUAKER OATS OLD FASH REGULAR",
			"QUAKER OATS REGULAR",
			"QUAKER POLVO SUGAR FREE",
			"QUAKER RICE CAKES",
			"QUAKER STILA CEREALES",
			"QUAKER TARTAS NO SUGAR",
			"QUALA KIPITOS REGULAR",
			"QUEEN COOKIES CUBIERTAS",
			"QUEEN COOKIES REGULAR",
			"QUELY REGULAR",
			"QUESABRITAS REGULAR",
			"QUICKBURY ANIMALITOS",
			"QUICKBURY REGULAR",
			"RAMAPE CHILE",
			"RAMAPE REGULAR",
			"RAMIREZ REGULAR",
			"RANCHERITOS REGULAR",
			"RANCHIPS SAL",
			"RANCHO SANO ENCHILADOS",
			"RANCHO SANO FUEGO",
			"RANCHO SANO JALAPENO",
			"RANCHO SANO JAPONES",
			"RANCHO SANO REGULAR",
			"RANCHO SANO VARIETY",
			"RANGEL TOTOPOS",
			"RAYADITAS REGULAR",
			"RD BOTANA REGULAR",
			"RD CHURRO",
			"RD FRITURAS",
			"RD PAPAS REGULAR",
			"RD POPCORN REGULAR",
			"RD RUEDA REGULAR",
			"REBOTANA CORN REGULAR",
			"REBOTANA POTATO REGULAR",
			"RECETA CRUJIENTE CHILES ROJOS",
			"RECETA CRUJIENTE FLAMIN HOT",
			"RECETA CRUJIENTE JALAPENO",
			"RECETA CRUJIENTE OTHER SUBBRAND",
			"RECETA CRUJIENTE SAL",
			"RED ROCK DELI REGULAR",
			"REGIONAL REGULAR",
			"REMEDIOS REGULAR",
			"REY CANDY REGULAR",
			"REY CRUJIQUESO",
			"REY ENCHILADOS",
			"REY JAPONES",
			"REY QUESO",
			"REY REGULAR",
			"REY VARIETY",
			"REYES REGULAR",
			"REYNOSO CHILE",
			"REYNOSO REGULAR",
			"RIC HABA",
			"RICA PAPA NATURAL",
			"RICA PLATANITO NATURAL",
			"RICASSON REGULAR",
			"RICHAUD ANIMALITOS",
			"RICHAUD BIZCOCHITOS",
			"RICHAUD CANELITAS",
			"RICHAUD CHARRITOS",
			"RICHAUD DELICIAS",
			"RICHAUD GLOBIRICOS",
			"RICHAUD MARIAS REGULAR",
			"RICHAUD OTHER COOKIES",
			"RICHAUD OVAL",
			"RICHAUD PORK RIND REGULAR",
			"RICHAUD SODA",
			"RICHAUD VELETAS",
			"RICHEESE NABATI",
			"RICOMAIZ REGULAR",
			"RINCON TARASCO NUTS REGULAR",
			"RINCON TARASCO PORK RINDS REGULAR",
			"ROBELIS REGULAR",
			"ROCKO MINI",
			"ROCKO MOBI RECULAR",
			"ROCKO REGULAR",
			"ROLAND TARTALETAS",
			"ROLD GOLD REGULAR",
			"ROLIGO CHURRO",
			"ROLIGO REGULAR",
			"ROSITA ENCHILADOS",
			"ROSITA REGULAR",
			"ROSITA VARIETY",
			"ROSSY CHICHARRON",
			"ROSSY PALOMITAS",
			"ROYCA CHICHARRIN",
			"RUFFLES HABANERO BITES",
			"RUFFLES MEGA CRUNCH COCTELERAS",
			"RUFFLES MEGA CRUNCH JALAPENO",
			"RUFFLES MEGA CRUNCH OTHER SUBBRAND",
			"RUFFLES MEGA CRUNCH SALSA NEGRA",
			"RUFFLES MEGA CRUNCH SALSA ROJA",
			"RUFFLES OTHER SUBBRAND",
			"RUFFLES QUESO",
			"RUFFLES SAL",
			"RUFFLES ULTRA BLAZIN CHEESE",
			"RUFFLES ULTRA FLAMIN HOT",
			"SABRITAS ACIDULCE",
			"SABRITAS ADOBADAS",
			"SABRITAS BLAZIN CHEESE",
			"SABRITAS CHAMPIONS",
			"SABRITAS CHILE MANZANO",
			"SABRITAS CHORIQUESO",
			"SABRITAS CREMA Y ESPECIAS",
			"SABRITAS DIPS CHEESE",
			"SABRITAS DIPS CHIPOTLE",
			"SABRITAS DIPS HOT CHEESE",
			"SABRITAS DIPS ONION",
			"SABRITAS DIPS SOUR CREAM",
			"SABRITAS FLAMIN HOT",
			"SABRITAS HABANERO",
			"SABRITAS LIMON",
			"SABRITAS MINIS RACK",
			"SABRITAS OTHER SUBBRAND",
			"SABRITAS PC OTHER SUBBRAND",
			"SABRITAS PC VARIETY",
			"SABRITAS POPPLERS CHILE",
			"SABRITAS POPPLERS REGULAR",
			"SABRITAS SABRIDOR",
			"SABRITAS SABRIMAYOREO",
			"SABRITAS SABRINEGOCIO",
			"SABRITAS SABRISURTIDO",
			"SABRITAS SAL",
			"SABRITAS SALSA CHAMOY",
			"SABRITAS SALSA OTHER SUBBRAND",
			"SABRITAS SALSA PICANTE",
			"SABRITAS SALTY VARIETY",
			"SABRITAS SENSACIONES",
			"SABRITAS SPICY PACK",
			"SABRITAS VARIETY SABRISEMANA",
			"SABRITONES REGULAR",
			"SADY REGULAR",
			"SADY VARIETY",
			"SAIN MICHEL REGULAR",
			"SALADINO REGULAR",
			"SAN PABLO REGULAR",
			"SANCHEZ ENCHILADOS",
			"SANCHEZ JAPONES",
			"SANCHEZ REGULAR",
			"SANCHEZ SALADO",
			"SANISSIMO GALLETAS REGULAR",
			"SANISSIMO ROSETAS REGULAR",
			"SANSON REGULAR",
			"SANTA CECILIA REGULAR",
			"SANTIVERI CHOC CHIPS",
			"SANTIVERI MARIAS REGULAR",
			"SANTIVERI OATS REGULAR",
			"SANTIVERI OTHER COOKIES",
			"SANZON REGULAR",
			"SATORU REGULAR",
			"SCHAR CKY REGULAR",
			"SCHAR MARIAS REGULAR",
			"SCHAR SALADAS",
			"SCHAR WAFERS",
			"SCHETTINO REGULAR",
			"SCHNITZER CRACKERS",
			"SELECCION DE LA CASA JAPONES",
			"SHEILA G REGULAR",
			"SINALOENSE ENCHILADOS",
			"SINALOENSE JAPONES",
			"SINALOENSE REGULAR",
			"SION NATURAL",
			"SION REGULAR",
			"SKANDIA BETTER FOR YOU REGULAR",
			"SKANDIA CHOC CHIP REGULAR",
			"SLIM POP CHEESE",
			"SLIM POP CHILE",
			"SLIM POP LIMON",
			"SLIM POP MW NATURAL",
			"SLIM POP MW REGULAR",
			"SLIM POP NATURAL",
			"SLIM POP REGULAR",
			"SLIM POP SAL",
			"SLIM POP SWEET AND SALTY",
			"SNACK FACTORY REGULAR",
			"SNAPS CHILE",
			"SNAPS ESQUITES",
			"SNAPS OTHER",
			"SNAPS PIMIENTA",
			"SNAPS QUESO",
			"SNYDERS PRETZELS",
			"SNYDERS REGULAR",
			"SODA REAL CLASICA",
			"SOE REGULAR",
			"SOFY REGULAR",
			"SPECIAL VALUE REGULAR",
			"SPIRRONES REGULAR",
			"SPONCH REGULAR",
			"ST MICHEL REGULAR",
			"STACYS REGULAR",
			"STAX BBQ",
			"STAX CHEDDAR",
			"STAX ORIGINAL",
			"STAX OTHER SUBBRAND",
			"STAX PIZZA",
			"STAX SOUR CREAM",
			"STIEFFEL HOFER",
			"STILA BITS",
			"STILA FIT REGULAR",
			"STILA REGULAR",
			"SUANDY ABANITAS",
			"SUANDY DANESA",
			"SUANDY LUNAS",
			"SUANDY PASTISETAS",
			"SUANDY REGULAR",
			"SUANDY TARTALETAS",
			"SUANDY VARIETY",
			"SUAVICREMAS MINI",
			"SUAVICREMAS REGULAR",
			"SUKY FRITO",
			"SUKY REGULAR",
			"SUN CHIPS REGULAR",
			"SUNBITES CHILES ROJOS",
			"SUNBITES PLATANITOS CHILE",
			"SUNBITES PLATANITOS DULCE",
			"SUNBITES POPCORN CHEDDAR",
			"SUNBITES POPCORN SAL DE MAR",
			"SUNBITES POPCORN SWEET & SPICY",
			"SUNBITES TRES QUESOS",
			"SUPERMIX INSTANT",
			"SUPERMIX OLD FASHION",
			"SUPERMIX RTEC",
			"SURTIDO FAVORITO",
			"SUSALIA CRACKER REGULAR",
			"SUSALIA REGULAR",
			"SWEET FLAKES REGULAR",
			"SWEETWELL REGULAR",
			"SWEETWELL SANDWICH",
			"TA ITTO ENCHILADOS",
			"TA ITTO FRITURAS CHILE",
			"TA ITTO FRITURAS SAL",
			"TA ITTO HABANERO",
			"TA ITTO JAPONES",
			"TA ITTO REGULAR",
			"TA ITTO VARIETY",
			"TAGO REGULAR",
			"TAIFELDS FIBER",
			"TAIFELDS NO SUGAR",
			"TAIFELDS REGULAR",
			"TAIFELDS SUPER",
			"TAIFELDS VARIETY",
			"TAIFELDS VEGAN",
			"TAKIS BLUE HEAT",
			"TAKIS COBRA",
			"TAKIS FUEGO",
			"TAKIS HUAKAMOLE",
			"TAKIS LIMON",
			"TAKIS OTHER SUBBRAND",
			"TAKIS REGULAR",
			"TAKIS SALSA BRAVA",
			"TAKIS VERDE",
			"TAKIS ZOMBIE",
			"TANE ENCHILADOS",
			"TANITOS PLATANITOS",
			"TARRIN CHETO REGULAR",
			"TARRIN REGULAR",
			"TEPIKENAS REGULAR",
			"TERRAFERTIL CACAHUATE REGULAR",
			"TERRAFERTIL CORN REGULAR",
			"TIA ANGELITA REGULAR",
			"TIA OFILIA AVENA REGULAR",
			"TIA ROSA TARTINAS REGULAR",
			"TIA ROSA TRADITIONALS APAPACHOS",
			"TIA ROSA TRADITIONALS BESITOS",
			"TIA ROSA TRADITIONALS DORADITAS",
			"TIA ROSA TRADITIONALS GALLETA OATS",
			"TIA ROSA TRADITIONALS INTEGRAL",
			"TIA ROSA TRADITIONALS OREJITAS",
			"TIA ROSA TRADITIONALS POLVORON",
			"TIA ROSA TRADITIONALS REGULAR",
			"TIGRILLO CHURRO",
			"TIGRILLO QUESO",
			"TIGRILLO REGULAR",
			"TIGRILLO VARIETY",
			"TINAJITA REGULAR",
			"TIPIKOS REGULAR",
			"TIQUA REGULAR",
			"TITANIC ENCHILADOS",
			"TITANIC JAPONES",
			"TITANIC REGULAR",
			"TITANIC VARIETY",
			"TOKIWA JAPONES",
			"TOLOSA REGULAR",
			"TOLUQUENAS CORN REGULAR",
			"TOLUQUENAS MIX SNACKS",
			"TOLUQUENAS OTHER SNACKS",
			"TOLUQUENAS PAPAS REGULAR",
			"TOLUQUENAS POPCORN REGULAR",
			"TOLUQUENAS PORKRIND REGULAR",
			"TONIS CHURRO",
			"TOREADOS HABANERO",
			"TORRITOS REGULAR",
			"TORTRIX RODACAS",
			"TOSTACHOS REGULAR",
			"TOSTARICA ANIMALITOS",
			"TOSTARICA MARIAS",
			"TOSTARICA TRADICIONALES",
			"TOSTIDADAS REGULAR",
			"TOSTITOS CRONCHOS",
			"TOSTITOS FLAMIN HOT",
			"TOSTITOS NACHOS",
			"TOSTITOS OTHER SUBBRAND",
			"TOSTITOS PIQUIN",
			"TOSTITOS SAL",
			"TOSTITOS SALSA ROJA",
			"TOSTITOS SALSA VERDE",
			"TOSTITOS TOSTADAS",
			"TOSTITOS TOSTIELOTE",
			"TOSTITOS TOSTINAS",
			"TOTAL BRAN REGULAR",
			"TRES REYES REGULAR",
			"TREVI REGULAR",
			"TRIKI-TRAKES BITES",
			"TRIKI-TRAKES REGULAR",
			"TRONQUITO BOTANA REGULAR",
			"TRONQUITO ENCHILADOS",
			"TRONQUITO FRITOS REGULAR",
			"TRONQUITO LIMON",
			"TRONQUITO MIX REGULAR",
			"TRONQUITO NATURAL",
			"TRONQUITO PALOMITAS REGULAR",
			"TRUSNACK REGULAR",
			"TRYOSS REGULAR",
			"TUKAN CACAHUATE NATURAL",
			"TUKAN FRITURA HARINA",
			"TUKAN PORKRIND REGULAR",
			"TULEYDA HABANERO",
			"TULEYDA REGULAR",
			"TURKOS REGULAR",
			"TWISTOS MINITOSTADITAS",
			"UNIVERSITARIO REGULAR",
			"VALENTONES REGULAR",
			"VAN DER BERG REGULAR",
			"VAZQUEZ JAPONES",
			"VAZQUEZ REGULAR",
			"VELOZ MOVIE POP EXTRA MANTEQUILLA",
			"VELOZ MOVIE POP MANTEQUILLA",
			"VELOZ MOVIE POP NATURAL",
			"VENSE ENCHILADOS",
			"VENSE REGULAR",
			"VERO JAPONES",
			"VICTORIA REGULAR",
			"VICTORIA VARIETY",
			"VIEIRA CRACKER",
			"VIEIRA GOODIES",
			"VIEIRA REGULAR",
			"VIEIRA VARIETY",
			"VIEIRA WAFERS",
			"VIKINGO CHURRO",
			"VIKINGO CUADRO REGULAR",
			"VIKINGO NACHO",
			"VIKINGO PAPAS REGULAR",
			"VIKINGO RUEDA REGULAR",
			"VIKINGOS CHICHARRONES ARTESANAL",
			"VIKINGOS CHURRO CHILE LIMON",
			"VIKINGOS CHURRO CHIPOTLE",
			"VIKINGOS CHURRO CON CHILE",
			"VIKINGOS CHURRO LARGO",
			"VIKINGOS CHURRO MIX",
			"VIKINGOS CHURRO MORRITOS MIX",
			"VIKINGOS CHURRO NATURAL",
			"VIKINGOS PALOMITA",
			"VIKINGOS PALOMITA MIX",
			"VILLA SAN MIGUEL TOTOPOS",
			"VIRGEN DEL BRESO",
			"VIRGINIA REGULAR",
			"VIRIDIANA CHETO QUESO",
			"VIRIDIANA CUADRO ENCHILADO",
			"VIRIDIANA CUADRO SALADO",
			"VIRIDIANA HOJUELA DE PAPA",
			"VIRIDIANA LAGRIMA SALADA",
			"VIRIDIANA MEGA",
			"VIRIDIANA MINI",
			"VIRIDIANA RUEDA DE PAPA",
			"VIRIDIANA RUEDA SALADA",
			"VIRIDIANA RUFLE PAPA",
			"VIRTUDES CKY OTHER",
			"VITALI CHURROS DE AMARANTO",
			"VITALI HEALTHY SNACK",
			"VIVRA REGULAR",
			"VOORTMAN REGULAR",
			"VUALA SORPRESA",
			"WALKERS REGULAR",
			"WANDS CHICHARRON",
			"WANDS FRITURA MAIZ CON QUESO",
			"WASA REGULAR",
			"WHEAT BRAN CLASICO",
			"WHEAT BRAN REGULAR",
			"WIKTORKI REGULAR",
			"WINNUTS ENCHILADO",
			"WINNUTS EXTRUDED REGULAR",
			"WINNUTS MIXTO",
			"WINNUTS POTATO REGULAR",
			"WINNUTS REGULAR",
			"WINNUTS SALADAS",
			"WINNUTS TOSTADO",
			"WIPO CHURRO",
			"WIPO DONITA CHILE ROJO",
			"WIPO DONITA LIMON VERDE",
			"WIPO FUEGO",
			"WIPO LIMON",
			"WIPO MANTEQUILLA",
			"WIPO REGULAR",
			"WIPO VTY MIX",
			"WIPO WICHESOS PICA FUEGO",
			"WIPO WICHESOS QUESO",
			"WISE CHIPS HOME STYLE",
			"WISE CHIPS REGULAR",
			"WISE POPCORN REGULAR",
			"WONDERFUL ALMENDRAS",
			"WONDERFUL PISTACHIOS",
			"WOODPECKER PRETZELS",
			"XALIL CHILE",
			"XALIL ENCHILADOS",
			"XALIL GARAPINADO",
			"XALIL JAPONES",
			"XALIL REGULAR",
			"YENS ENCHILADOS",
			"YENS JAPONES",
			"YENS VARIETY",
			"YORICA TOTOPOS",
			"YORSH REGULAR",
			"YOUTZI CHURRO",
			"YOUTZI ENCHILADOS",
			"YUDITH TOTOPO",
			"YUMI NATURAL",
			"YUMI QUESO",
			"YUMI RANCHIPS QUESO",
			"YUMI RANCHIPS SAL",
			"YUMI REGULAR",
			"YUMI SAL",
			"ZAKS INSTABITES",
			"ZAKS REGULAR",
			"ZAKS URBIG",
			"ZAPATA CHICHARRON REGULAR",
			"ZAPATA CHURRITOS REGULAR",
			"ZAPATA MIX VARIETY",
			"ZORRITOS REGULAR",
		],
		packSize: [
			"01X-MINI",
			"01X-SS XSMALL",
			"02P-MULTIPK",
			"02X-JR LINE",
			"02X-SS SMALL",
			"03P-MULTIPK",
			"03X-EPP SIZE",
			"03X-SHOTS",
			"03X-SS MEDIUM",
			"04P-MULTIPK",
			"04X-CHICO",
			"04X-SS LARGE",
			"05P-MULTIPK",
			"05X-SS XXSMALL",
			"06P-MULTIPK",
			"07P-MULTIPK",
			"07X-MAX",
			"07X-MEDIUM",
			"08P-MULTIPK",
			"08X-REC",
			"09P-MULTIPK",
			"09X-JUMBO",
			"108P-MULTIPK",
			"10P-MULTIPK",
			"10X-AMIGOTE",
			"10X-LHPP",
			"11P-MULTIPK",
			"11X-BIG",
			"11X-COMPARTE",
			"12P-MULTIPK",
			"12X-EXTRA BIG",
			"12X-FAMILIAR",
			"13X-LLEVA2",
			"14P-MULTIPK",
			"14X-FAMILY",
			"14X-FIESTA",
			"15P-MULTIPK",
			"15X-HPP",
			"15X-JUMBO",
			"15X-MEGA",
			"16X-SUPER SIZE",
			"18P-MULTIPK",
			"18X-BOTE GRANDE",
			"18X-OTHER",
			"19X-BOTE CHICO",
			"20P-MULTIPK",
			"24P-MULTIPK",
			"24P-TOSTIPACK",
			"24X-CHICO",
			"25P-MULTIPK",
			"25X-POPULITO",
			"27P-MULTIPK",
			"28P-MULTIPK",
			"30P-MULTIPK",
			"32P-MULTIPK",
			"34P-MULTIPK",
			"40P-MULTIPK",
			"50P-MULTIPK",
			"80P-MULTIPK",
			"81P-MULTIPK",
			"99W-MINIS RACK",
			"99W-OTHER",
			"99W-SABRIMAYOREO",
			"99W-SABRINEGOCIO",
			"99W-SABRISURTIDO",
			"99W-SPICY PACK",
			"99X-OTHER",
		],
		date: "02/01/2024-03/01/2024",
		variables: ["priceByUnitSellOut"],
		views: ["absPeriodCompare", "currentPeriod", "periodCompare", "lastPeriod"],
	},
	{
		country: "GUATEMALA",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL GUATEMALA-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		viewTop: 10,
		data: "sellOut",
		viewX: "absGrowthSOM",
		viewY: "priceSellOut",
		viewSize: "somSalesUnitSellOut",
		xSOMGroup: "Value",
		xSOMMeasure: "Category",
		ySOMGroup: "perVolume",
		ySOMMeasure: "",
		viewType: "subBrandSize",
		category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-DIPS & SALSA", "SAV-NUTS & SEEDS", "SAV-POPCORN-MW", "SAV-SALTY"],
		vendor: [
			"13 CEREALES",
			"ABIL",
			"AGROINDUSTRIAS ALBAY",
			"ALCSA",
			"ALIMENTOS CAMPESTRES",
			"ALIMENTOS COOK",
			"ALIMENTOS DIANA",
			"ALIMENTOS H & H",
			"ALIMENTOS JACKS",
			"ALIMENTOS SELECTOS",
			"AMERICAN POP CORN",
			"AMERICAN ROLAND FOOD",
			"ARCOR",
			"BEAR NAKED",
			"BLUE DIAMOND GROWERS",
			"BOCADELI",
			"BRIO",
			"BROHDERS",
			"CAMPBELLS",
			"CASA BAZZINI",
			"CASADEMONT",
			"CENTRAL DE ALIMENTOS",
			"CHOCOLATES BEST",
			"COLOMBINA",
			"CONSERVAS LA COSTEÑA",
			"CORPORACION MULTI INVERSIONES",
			"D CARLOS",
			"DANCAKE",
			"DAVIDS",
			"DEL CARMEN",
			"DELIRICE",
			"DERIVADOS DE LECHE LA ESMERALDA",
			"DESOBRY",
			"DIAMOND FOODS",
			"DINANT",
			"DISTRIBUIDORA DE ALIMENTOS Y BEBIDAS GUACHA",
			"DOMITICA",
			"ELLEDI",
			"ENLACES GUATEMALA",
			"EXCELLENT",
			"FABRICA DE GALLETAS GULLON",
			"FERRERO",
			"FORNO BONOMI",
			"GENERAL MILLS",
			"GLOBAL BRANDS",
			"GRUPO BIMBO",
			"GRUPO CAROZZI",
			"GRUPO INDUSTRIAL ALIMENTICIO",
			"GRUPO VIDA",
			"HEBBE",
			"IBERIA FOODS",
			"IDEALSA",
			"INA",
			"INDUSTRIAS HELIOS",
			"INDUSTRIAS MAFAM",
			"INDUSTRIAS UNIDAS",
			"JOHN B SANFILIPPO & SON",
			"KAMOJA",
			"KELLOGGS",
			"KELSEN",
			"KETTLE FOODS",
			"KIDDYLICIOUS",
			"LA CHILERA",
			"LA FLOR BURGALESA",
			"LINAVENA",
			"LOTUS BAKERY",
			"MADA",
			"MAIZITOS",
			"MANITOBA",
			"MOLINO DI FERRO",
			"MOM BRANDS",
			"MONDELEZ",
			"NATURAL HONEY",
			"NATURASOL",
			"NESTLE",
			"NURTURE",
			"NUTRIEVO",
			"ORGRAN",
			"ORIGINAL GOURMET FOOD",
			"OTHER VENDOR",
			"OTROS",
			"PAN SINAI",
			"PANDURATA ALIMENTOS",
			"PASQUIER",
			"PEPSICO",
			"PICONAS",
			"POP TIME",
			"POST FOODS",
			"PRODECA",
			"PRODUCTOS ALIMENTICIOS CENTROAMERICANOS",
			"PRODUCTOS ALIMENTICIOS CYP",
			"PRODUCTOS HIDROTERRA",
			"PRODUCTOS KITTY",
			"PRODUCTOS RIQUISIMA",
			"PROSNACKS",
			"QUILALI",
			"SALUVITA",
			"SAN ENRIQUE",
			"SEEBERGER",
			"SNYDERS LANCE",
			"TAIFELDS",
			"TELUMA",
			"THE HAIN CELESTIAL",
			"TIA ANGELITA",
			"TORTI EXPRESS",
			"TOSTADAS CHARRAS",
			"TOTIS",
			"TROPICAL NUT & FRUIT",
			"TUTO SNACK",
			"UNILEVER",
			"WALMART",
			"WEAVER POPCORN",
			"YOGI SUPERFOODS",
			"YUMUS",
		],
		segment: [
			"CKY-BREAKFAST",
			"CKY-CHOC CHIP",
			"CKY-CHOC COVER",
			"CKY-FILLED",
			"CKY-MARIAS",
			"CKY-MARSHMLW",
			"CKY-OAT_QUAKER",
			"CKY-OTHER",
			"CKY-SANDWICH",
			"CKY-TRAD",
			"CKY-WAFER",
			"CRK-OIL",
			"CRK-PLAIN",
			"CRK-RICE",
			"CRK-SALTINE",
			"FDS-BARS CHEWY",
			"FDS-BARS FRUIT",
			"FDS-BARS GRANOLA",
			"FDS-BARS OAT",
			"FDS-CEREAL ADULT",
			"FDS-CEREAL GRANOLA",
			"FDS-CEREAL KIDS",
			"FDS-OATS INSTANT",
			"FDS-OATS TRAD",
			"SAV-CORN",
			"SAV-EXTRUDED",
			"SAV-HLTHY SNACK",
			"SAV-NUTS",
			"SAV-OTHER",
			"SAV-PELLET",
			"SAV-POPCORN-MW",
			"SAV-POPCORN-RTE",
			"SAV-PORK RINDS",
			"SAV-POTATO",
			"SAV-RTE DIPS",
			"SAV-SALTY MIX",
			"SAV-TORTILLA",
			"SAV-VARIETY PK",
		],
		brand: [
			"13 CEREALES REGULAR",
			"ACT II REGULAR",
			"ALBAY REGULAR",
			"ARCOIRIS FRUITS",
			"ARCOIRIS REGULAR",
			"ARCOR REGULAR",
			"ARTESANAS REGULAR",
			"ARTESANO REGULAR",
			"AUNT JEMIMA REGULAR",
			"AVENA DEL MOLINO",
			"B & B REGULAR",
			"BANZITOS REGULAR",
			"BARCEL POPCORN REGULAR",
			"BARCEL TORTILLA REGULAR",
			"BAUDUCCO REGULAR",
			"BAZZINI REGULAR",
			"BEAR NAKED REGULAR",
			"BEST REGULAR",
			"BIG MIX REGULAR",
			"BLUE DIAMOND REGULAR",
			"BOCA2 REGULAR",
			"BOCADELI CORN REGULAR",
			"BOCADELI EXTRUDED REGULAR",
			"BOCADELI HEALTHY SNACKS",
			"BOCADELI MIX REGULAR",
			"BOCADELI POPCORN REGULAR",
			"BOCADELI PORK RIND REGULAR",
			"BOCADELI POTATO REGULAR",
			"BOTANIS EXTRUDED REGULAR",
			"BOTANIS PELLET REGULAR",
			"BOTANIS TORTILLA REGULAR",
			"BRIOCHE PASQUIER REGULAR",
			"BROHDERS REGULAR",
			"BRUGGEN REGULAR",
			"BUE NACHOS",
			"BUGLES REGULAR",
			"C&P HEALTHY SNACK",
			"C&P POTATO",
			"C&P TORTILLA",
			"CAMPO RICO",
			"CAN CAN REGULAR",
			"CARIBAS REGULAR",
			"CASADEMONT REGULAR",
			"CASHITAS HEALTHY SNACK",
			"CASHITAS NUTS REGULAR",
			"CASHITAS PELLET REGULAR",
			"CASHITAS POPCORN MW REGULAR",
			"CEBOLLITAS REGULAR",
			"CEREVITA REGULAR",
			"CHARRAS REGULAR",
			"CHEEZ IT REGULAR",
			"CHEX MIX REGULAR",
			"CHICHARRON",
			"CHICHARRON CRIOLLO",
			"CHIPS",
			"CHITOS GOLOSOS",
			"CHOBIX",
			"CHOCOWOW REGULAR",
			"CHOKIS CHISPAS BLACK",
			"CHOKIS CHOCOBASE",
			"CHOKIS CHOCOLATE",
			"CHOKIS CLASICA",
			"CHOKIS MINI",
			"CHOKIS MIX",
			"CHOKIS RELLENA",
			"CHOKIS RELLENA REGULAR",
			"CHOKIS SANDWICH",
			"CHURRITO FIESTA",
			"CHURRUMAIS REGULAR",
			"COLOMBINA REGULAR",
			"CONCHITAS",
			"CONGA MIX REGULAR",
			"COSTA REGULAR",
			"CRACKETS MINI",
			"CRACKETS REGULAR",
			"CREMAX SNOW",
			"CRUJITOS",
			"CUETARA REGULAR",
			"CYP REGULAR",
			"D CARLOS REGULAR",
			"DANESITA REGULAR",
			"DAVIDS REGULAR",
			"DEEP RIVER REGULAR",
			"DEL CARMEN REGULAR",
			"DEL PILAR REGULAR",
			"DELIRICE REGULAR",
			"DESOBRY REGULAR",
			"DETODITO",
			"DIAMOND REGULAR",
			"DIANA ALBOROTO",
			"DIANA CENTAVITOS",
			"DIANA CHICHARRONES",
			"DIANA CHIPS JALAPENOS",
			"DIANA CHURRITOS",
			"DIANA COOKIE REGULAR",
			"DIANA CORN CHIPS",
			"DIANA CRAKS SAVORY",
			"DIANA ELOTITOS",
			"DIANA MAIZ CHINO",
			"DIANA NACHOS",
			"DIANA NUTS REGULAR",
			"DIANA OTHER SAVORY",
			"DIANA PALITOS",
			"DIANA POPOTITOS",
			"DIANA QUESITOS",
			"DIANA TOCINITO",
			"DIANA TORTILLA REGULAR",
			"DIANA TOZTECAS",
			"DIANA VARIETY PK REGULAR",
			"DOBRECITOS",
			"DORADITAS REGULAR",
			"DORITOS",
			"DOUBLE NUTS REGULAR",
			"EATSMART REGULAR",
			"EL CIERVO REGULAR",
			"ELLEDI REGULAR",
			"EMPERADOR COMBINADO",
			"EMPERADOR INTENSO",
			"EMPERADOR NOCTURNO",
			"EMPERADOR REGULAR",
			"EMPERADOR SENZO",
			"EXCELLENT REGULAR",
			"EXTRA SPECIAL REGULAR",
			"FIESTA SNAX REGULAR",
			"FILLERS EXTRUDED",
			"FILLERS POTATO",
			"FILLERS TORTILLA",
			"FLAMAS REGULAR",
			"FLORBU REGULAR",
			"FORNO BONOMI REGULAR",
			"FRESCAVENA REGULAR",
			"FRIJOLI CHIPS",
			"FRITO LAY NUTS REGULAR",
			"FRITO LAY OTHER REGULAR",
			"FRITO LAY VARIETY",
			"FRITOS DIP",
			"FRITOS REGULAR",
			"FRITOS TWIST",
			"FRUITY OCEAN REGULAR",
			"FRUTS REGULAR",
			"FUNYUNS REGULAR",
			"GAMA FILLED REGULAR",
			"GAMA MARIAS REGULAR",
			"GAMA OTHER REGULAR",
			"GAMA SALTINE REGULAR",
			"GAMAWAFER REGULAR",
			"GAMESA DELICIAS",
			"GAMESA FLORENTINAS",
			"GAMESA MARIAS CLASICA",
			"GAMESA MARIAS MINI",
			"GAMESA NAVIDAD",
			"GAMESA REGULAR",
			"GAMESA RICE CRACKERS",
			"GAMESA SALADITAS CLASICA",
			"GAMESA TRAD BARRAS DE COCO",
			"GAMESA TRAD COOKIES MARAVILLAS",
			"GAMESA TRAD COOKIES RICANELAS",
			"GAMESA VARIETY PEKEPAKES",
			"GAMESA VARIETY REGULAR",
			"GAMESA VARIETY RICO",
			"GENERAL MILLS CORN CHIPS",
			"GRAN DIA CEREAL ADULT",
			"GRAN DIA CEREAL GRANOLA",
			"GRAN VITA CEREAL GRANOLA",
			"GRAN VITA OATS INSTANT",
			"GRANDMAS CHOC CHIP FACTORY",
			"GRANDMAS COOKIES FACTORY",
			"GRANUTS REGULAR",
			"GREAT VALUE CEREAL ADULT",
			"GREAT VALUE CEREAL GRANOLA",
			"GREAT VALUE CEREAL KIDS",
			"GREAT VALUE COOKIE OTHER",
			"GREAT VALUE NUTS REGULAR",
			"GREAT VALUE OATS REGULAR",
			"GREAT VALUE POPCORN MW",
			"GREAT VALUE POTATO REGULAR",
			"GREAT VALUE TORTILLA REGULAR",
			"GREEN MOUNTAIN",
			"GUACHA REGULAR",
			"GULLON REGULAR",
			"HAPPY FAMILY REGULAR",
			"HAWAIIAN CRUNCH REGULAR",
			"HELIOS CEREAL GRANOLA",
			"HELIOS COOKIE OTHER",
			"HERRS CORN REGULAR",
			"HERRS POTATO REGULAR",
			"HERRS TORTILLA REGULAR",
			"HIDROTERRA HEALTHY SNACK",
			"HIDROTERRA OTHER SAVORY REGULAR",
			"HORTIFRUTI REGULAR",
			"HULA HOOPS REGULAR",
			"IBERIA REGULAR",
			"INA REGULAR",
			"INCAPARINA REGULAR",
			"INTERCAMPO REGULAR",
			"JACKS REGULAR",
			"JOLLY TIME REGULAR",
			"JULIETA REGULAR",
			"KACHITOS REGULAR",
			"KAMOJA OATS TRADITIONAL",
			"KAMOJA POPCORN MW",
			"KAPRICHITOS REGULAR",
			"KARAMELADAS REGULAR",
			"KEEBLER REGULAR",
			"KELLOGGS CEREAL ADULT",
			"KELLOGGS CEREAL GRANOLA",
			"KELLOGGS CEREAL KIDS",
			"KETTLE REGULAR",
			"KIDDYLICIOUS CEREAL KIDS",
			"KIDDYLICIOUS CRACKERS REGULAR",
			"KITTY CORN CHIP REGULAR",
			"KITTY EXTRUDED REGULAR",
			"KITTY MIX REGULAR",
			"KITTY POPCORN REGULAR",
			"KITTY POTATO REGULAR",
			"KITTY VARIETY REGULAR",
			"KOCTEL REGULAR",
			"KRAFT REGULAR",
			"LA CHILERA HEALTHY SNACK",
			"LA CHILERA POPCORN RTE REGULAR",
			"LA CHILERA RTE DIPS REGULAR",
			"LAYS",
			"LAYS BAKED",
			"LAYS KETTLE",
			"LAYS MAXX",
			"LAYS STAX",
			"LE VENEZIANE REGULAR",
			"LINAVENA REGULAR",
			"LONCHERA REGULAR",
			"LOS CEBOLLINES DIPS REGULAR",
			"LOS CEBOLLINES REGULAR",
			"LOTUS REGULAR",
			"MADA REGULAR",
			"MAIZENA REGULAR",
			"MALT O MEAL CEREAL ADULT",
			"MALT O MEAL OATS INSTANT",
			"MAMUT MINI",
			"MAMUT REGULAR",
			"MAMUT REGULAR REGULAR",
			"MANI MOTO REGULAR",
			"MANIAX NUTS",
			"MANIAX REGULAR",
			"MANITOBA NUTS REGULAR",
			"MANITOBA POPCORN MW",
			"MARCELINO COOKIE CHOC CHIP",
			"MARCELINO DIPS REGULAR",
			"MARIAN REGULAR",
			"MARINELA REGULAR",
			"MCCALLUMS REGULAR",
			"MEGA NUTS EXTRUDED REGULAR",
			"MEGA NUTS HEALTHY SNACK REGULAR",
			"MEGA NUTS POTATO REGULAR",
			"MEGA NUTS SNACK MIX REGULAR",
			"MINI CHOKIS BLACK",
			"MINI PRETZEL REGULAR",
			"MUCHO NACHO REGULAR",
			"MUNCHIES REGULAR",
			"MUNCHOS REJILLAS",
			"NABISCO COOKIE CHOC CHIP",
			"NABISCO COOKIE OATS",
			"NABISCO COOKIE SANDWICH",
			"NABISCO CRACKERS REGULAR",
			"NABISCO CRACKERS SALTINE",
			"NACHO SOMBRERON",
			"NATUCHIPS PLATANO MADURO",
			"NATUCHIPS PLATANO VERDE",
			"NATUCHIPS REGULAR",
			"NATURAL HONEY",
			"NATURES HEART NUTS REGULAR",
			"NATURES HEART OATS INSTANT",
			"NAU REGULAR",
			"NESTLE CEREAL ADULT",
			"NESTLE CEREAL GRANOLA",
			"NESTLE CEREAL KIDS",
			"NUT HARVEST REGULAR",
			"NUTELLA REGULAR",
			"NUTRIEVO REGULAR",
			"NUTRISNACKS REGULAR",
			"ORCHARD VALLEY HARVEST",
			"ORGRAN REGULAR",
			"ORIGINAL GOURMET",
			"ORVILLE REDENBACHERS",
			"ORVILLE REGULAR",
			"OTHER PEPSICO EXTRUDED SUBBRAND",
			"OTHER PEPSICO PELLET SUBBRAND",
			"OTHER PEPSICO POTATO SUBBRAND",
			"OTHER SUBBRAND CORN",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND OTHER",
			"OTHER SUBBRAND POPCORN-MW",
			"OTHER SUBBRAND POPCORN-RTE",
			"OTHER SUBBRAND PORK RINDS",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND SALTY MIX",
			"OTHER SUBBRAND TORTILLA",
			"OTHER SUBBRAND VARIETY PK",
			"OTROS OATS TRADITIONAL",
			"OTROS POPCORN MW",
			"OUR FINEST REGULAR",
			"PACHANGA MIX REGULAR",
			"PAKETAXO REGULAR",
			"PAN CREMA REGULAR",
			"PAN SINAI REGULAR",
			"PANCREMA CLASICA",
			"PEPPERIDGE FARM COOKIE FILLED",
			"PEPPERIDGE FARM CRACKERS SALTINE",
			"PICONAS REGULAR",
			"PICONCITOS REGULAR",
			"PIKARONES REGULAR",
			"PLANTERS REGULAR",
			"POFFETS REGULAR",
			"POP  TIME REGULAR",
			"POP WEAVER REGULAR",
			"POPCORNERS",
			"POPSIS REGULAR",
			"POST REGULAR",
			"POZUELO COOKIE MARIAS",
			"POZUELO COOKIE OATS",
			"POZUELO COOKIE REGULAR",
			"POZUELO CRACKER SALTINE",
			"PRINGLES",
			"PRO HEALTHY SNACK",
			"PRO NUTS REGULAR",
			"PRO PAPAS REGULAR",
			"PRO PLATANOS REGULAR",
			"PRO SNACKS MIX",
			"PRODECA REGULAR",
			"QUAKER BARS CHEWY",
			"QUAKER BARS CRUNCHY",
			"QUAKER BARS FLATS",
			"QUAKER BARS FRUIT",
			"QUAKER BARS GRANOLA",
			"QUAKER BARS MINI",
			"QUAKER CEREAL 100PCT NATURAL",
			"QUAKER CEREAL CAPN CRUNCH",
			"QUAKER CEREAL CHOCOBLAST",
			"QUAKER CEREAL CHOCOSTARS",
			"QUAKER CEREAL FRUITY OHS",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL HEARTS",
			"QUAKER CEREAL LIFE",
			"QUAKER CEREAL MULTIBRAN",
			"QUAKER CEREAL OTHER",
			"QUAKER CEREAL SQUARES",
			"QUAKER GALLETAS AVENA",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS REGULAR",
			"QUAKER OATS INSTANT",
			"QUAKER OATS MOLIDA",
			"QUAKER OATS MOSH",
			"QUAKER OATS QUICK",
			"QUAKER OATS REGULAR",
			"QUAKER YOGURT",
			"QUILALI REGULAR",
			"RANCHERITOS REGULAR",
			"RECETA CRUJIENTE SAL",
			"RICITO REGULAR",
			"RICKS CORN REGULAR",
			"RICKS EXTRUDED REGULAR",
			"RICKS HEALTHY SNACK REGULAR",
			"RICKS PELLET REGULAR",
			"RICKS POTATO REGULAR",
			"RICKS SNACK MIX REGULAR",
			"RIQUISIMA REGULAR",
			"ROLAND HEALTHY SNACK",
			"ROLAND NUTS REGULAR",
			"ROLD GOLD REGULAR",
			"ROYAL DANSK",
			"RUFFLES REGULAR",
			"RUNNERS REGULAR",
			"SABEMAS COOKIE REGULAR",
			"SABEMAS OATS INSTANT",
			"SABEMAS OATS TADITIONAL",
			"SABEMAS POPCORN MW",
			"SABEMAS TORTILLA REGULAR",
			"SABRIMINIS CHARROS",
			"SABRISEMANA REGULAR",
			"SABRITAS REGULAR",
			"SABRITONES REGULAR",
			"SALUVITA REGULAR",
			"SAMS CHOICE",
			"SAN ENRIQUE REGULAR",
			"SANISSIMO REGULAR",
			"SASSON DIPS REGULAR",
			"SASSON NUTS REGULAR",
			"SEEBERGER REGULAR",
			"SENORIAL ARITOS",
			"SENORIAL BOLITAS QUESO",
			"SENORIAL CHICHARRON",
			"SENORIAL NACHOS TORTILLA CHIPS",
			"SENORIAL PLATANITOS",
			"SENORIAL POPCORN",
			"SENORIAL QUESIFRITO",
			"SENORIAL RINGOS",
			"SENORIAL RUFITAS",
			"SENORIAL SALTY MIX",
			"SENORIAL SOPLITOS",
			"SENORIAL TORTICHIPS",
			"SENORIAL TORTILLA REGULAR",
			"SENORIAL TORTILLITAS",
			"SENORIAL YUCARICAS",
			"SENSIBLE PORTIONS EXTRUDED REGULAR",
			"SENSIBLE PORTIONS HEALTHY SNACK",
			"SHAKA LAKA CEREAL ADULT",
			"SHAKA LAKA CEREAL KIDS",
			"SMARTFOOD REGULAR",
			"SNAK TIME REGULAR",
			"SNYDERS PRETZEL REGULAR",
			"STILA REGULAR",
			"SUANDY REGULAR",
			"SULI CEREAL KIDS",
			"SULI CRACKER SALTINE",
			"SULI EXTRUDED REGULAR",
			"SULI FILLED",
			"SULI HEALTHY SNACK",
			"SULI MARIAS",
			"SULI OATS REGULAR",
			"SULI OTHER",
			"SULI POTATO REGULAR",
			"SUN CHIPS REGULAR",
			"SUNSHINE SNACKS",
			"TAIFELDS REGULAR",
			"TAKIS",
			"TAQUERITOS REGULAR",
			"TELUMA REGULAR",
			"TERRA POTATO REGULAR",
			"TERRA SALTY MIX REGULAR",
			"TIA ANGELITA REGULAR",
			"TITO PLATANITAS REGULAR",
			"TITO SALTY MIX",
			"TORRADITOS REGULAR",
			"TORTI EXPRESS",
			"TORTICHIPS REGULAR",
			"TORTILLEROS REGULAR",
			"TORTRIX CHAPIMIX",
			"TORTRIX CHICHARRON",
			"TORTRIX CORN",
			"TORTRIX SNACK MIX",
			"TORTRIX TORTILLA",
			"TORTRIX TWIST",
			"TOSH CEREAL ADULT",
			"TOSH CEREAL GRANOLA",
			"TOSH CRACKER",
			"TOSH EXTRUDED REGULAR",
			"TOSH NUTS REGULAR",
			"TOSH OATS REGULAR",
			"TOSH TORTILLA REGULAR",
			"TOSTACHOS REGULAR",
			"TOSTITOS CANTINA",
			"TOSTITOS CRISPY ROUNDS",
			"TOSTITOS DIPS REGULAR",
			"TOSTITOS REGULAR",
			"TOSTITOS SANTE FE",
			"TOSTITOS SCOOPS",
			"TOSTITOS TOQUE DE LIMA",
			"TOTIS CORN REGULAR",
			"TOTIS EXTRUDED REGULAR",
			"TOTIS POPCORN RTE REGULAR",
			"TRIKOS CORN REGULAR",
			"TRIKOS EXTRUDED REGULAR",
			"TRONIX CORN REGULAR",
			"TRONIX HEALTHY SNACK REGULAR",
			"TROPIC",
			"TROPICAL",
			"TUTO SNACK",
			"VARIETY OTHER SUBBRAND",
			"WORLD TABLE",
			"YA ESTA EXTRUDED REGULAR",
			"YA ESTA OATS INSTANT",
			"YA ESTA POPCORN RTE REGULAR",
			"YA ESTA RTE DIPS",
			"YA ESTA TORTILLA REGULAR",
			"YOGI REGULAR",
			"YUMMIES DEL RANCHO",
			"YUMMIES EXTRUDED CAPPY",
			"YUMMIES POPCORN RTE CAPPY",
			"YUMMIES RANCHITAS",
			"YUMMIES TACO",
			"YUMMIES TRONES CHICHARRON",
			"YUMMIES TRONES TORTILLA",
			"YUMMIES ZAMBOS HEALTHY SNACK",
			"YUMMIES ZAMBOS SALTY MIX",
			"YUMMIES ZIBAS EXTRUDED",
			"YUMMIES ZIBAS POTATO",
			"YUMMIX REGULAR",
		],
		subBrand: [
			"13 CEREALES REGULAR",
			"ACT II REGULAR",
			"ALBAY REGULAR",
			"ARCOIRIS FRUITS",
			"ARCOIRIS REGULAR",
			"ARCOR REGULAR",
			"ARTESANAS REGULAR",
			"ARTESANO REGULAR",
			"AUNT JEMIMA REGULAR",
			"AVENA DEL MOLINO",
			"B & B REGULAR",
			"BANZITOS REGULAR",
			"BARCEL POPCORN REGULAR",
			"BARCEL TORTILLA REGULAR",
			"BAUDUCCO REGULAR",
			"BAZZINI REGULAR",
			"BEAR NAKED REGULAR",
			"BEST REGULAR",
			"BIG MIX REGULAR",
			"BLUE DIAMOND REGULAR",
			"BOCA2 REGULAR",
			"BOCADELI CORN REGULAR",
			"BOCADELI EXTRUDED REGULAR",
			"BOCADELI HEALTHY SNACKS",
			"BOCADELI MIX REGULAR",
			"BOCADELI POPCORN REGULAR",
			"BOCADELI PORK RIND REGULAR",
			"BOCADELI POTATO REGULAR",
			"BOTANIS EXTRUDED REGULAR",
			"BOTANIS PELLET REGULAR",
			"BOTANIS TORTILLA REGULAR",
			"BRIOCHE PASQUIER REGULAR",
			"BROHDERS REGULAR",
			"BRUGGEN REGULAR",
			"BUE NACHOS",
			"BUGLES REGULAR",
			"C&P HEALTHY SNACK",
			"C&P POTATO",
			"C&P TORTILLA",
			"CAMPO RICO",
			"CAN CAN REGULAR",
			"CARIBAS REGULAR",
			"CASADEMONT REGULAR",
			"CASHITAS HEALTHY SNACK",
			"CASHITAS NUTS REGULAR",
			"CASHITAS PELLET REGULAR",
			"CASHITAS POPCORN MW REGULAR",
			"CEBOLLITAS REGULAR",
			"CEREVITA REGULAR",
			"CHARRAS REGULAR",
			"CHEEZ IT REGULAR",
			"CHEX MIX REGULAR",
			"CHICHARRON",
			"CHICHARRON CRIOLLO",
			"CHIPS",
			"CHITOS GOLOSOS",
			"CHOBIX",
			"CHOCOWOW REGULAR",
			"CHOKIS CHISPAS BLACK",
			"CHOKIS CHOCOBASE",
			"CHOKIS CHOCOLATE",
			"CHOKIS CLASICA",
			"CHOKIS MINI",
			"CHOKIS MIX",
			"CHOKIS RELLENA",
			"CHOKIS RELLENA REGULAR",
			"CHOKIS SANDWICH",
			"CHURRITO FIESTA",
			"CHURRUMAIS REGULAR",
			"COLOMBINA REGULAR",
			"CONCHITAS",
			"CONGA MIX REGULAR",
			"COSTA REGULAR",
			"CRACKETS MINI",
			"CRACKETS REGULAR",
			"CREMAX SNOW",
			"CRUJITOS",
			"CUETARA REGULAR",
			"CYP REGULAR",
			"D CARLOS REGULAR",
			"DANESITA REGULAR",
			"DAVIDS REGULAR",
			"DEEP RIVER REGULAR",
			"DEL CARMEN REGULAR",
			"DEL PILAR REGULAR",
			"DELIRICE REGULAR",
			"DESOBRY REGULAR",
			"DETODITO",
			"DIAMOND REGULAR",
			"DIANA ALBOROTO",
			"DIANA CENTAVITOS",
			"DIANA CHICHARRONES",
			"DIANA CHIPS JALAPENOS",
			"DIANA CHURRITOS",
			"DIANA COOKIE REGULAR",
			"DIANA CORN CHIPS",
			"DIANA CRAKS SAVORY",
			"DIANA ELOTITOS",
			"DIANA MAIZ CHINO",
			"DIANA NACHOS",
			"DIANA NUTS REGULAR",
			"DIANA OTHER SAVORY",
			"DIANA PALITOS",
			"DIANA POPOTITOS",
			"DIANA QUESITOS",
			"DIANA TOCINITO",
			"DIANA TORTILLA REGULAR",
			"DIANA TOZTECAS",
			"DIANA VARIETY PK REGULAR",
			"DOBRECITOS",
			"DORADITAS REGULAR",
			"DORITOS",
			"DOUBLE NUTS REGULAR",
			"EATSMART REGULAR",
			"EL CIERVO REGULAR",
			"ELLEDI REGULAR",
			"EMPERADOR COMBINADO",
			"EMPERADOR INTENSO",
			"EMPERADOR NOCTURNO",
			"EMPERADOR REGULAR",
			"EMPERADOR SENZO",
			"EXCELLENT REGULAR",
			"EXTRA SPECIAL REGULAR",
			"FIESTA SNAX REGULAR",
			"FILLERS EXTRUDED",
			"FILLERS POTATO",
			"FILLERS TORTILLA",
			"FLAMAS REGULAR",
			"FLORBU REGULAR",
			"FORNO BONOMI REGULAR",
			"FRESCAVENA REGULAR",
			"FRIJOLI CHIPS",
			"FRITO LAY NUTS REGULAR",
			"FRITO LAY OTHER REGULAR",
			"FRITO LAY VARIETY",
			"FRITOS DIP",
			"FRITOS REGULAR",
			"FRITOS TWIST",
			"FRUITY OCEAN REGULAR",
			"FRUTS REGULAR",
			"FUNYUNS REGULAR",
			"GAMA FILLED REGULAR",
			"GAMA MARIAS REGULAR",
			"GAMA OTHER REGULAR",
			"GAMA SALTINE REGULAR",
			"GAMAWAFER REGULAR",
			"GAMESA DELICIAS",
			"GAMESA FLORENTINAS",
			"GAMESA MARIAS CLASICA",
			"GAMESA MARIAS MINI",
			"GAMESA NAVIDAD",
			"GAMESA REGULAR",
			"GAMESA RICE CRACKERS",
			"GAMESA SALADITAS CLASICA",
			"GAMESA TRAD BARRAS DE COCO",
			"GAMESA TRAD COOKIES MARAVILLAS",
			"GAMESA TRAD COOKIES RICANELAS",
			"GAMESA VARIETY PEKEPAKES",
			"GAMESA VARIETY REGULAR",
			"GAMESA VARIETY RICO",
			"GENERAL MILLS CORN CHIPS",
			"GRAN DIA CEREAL ADULT",
			"GRAN DIA CEREAL GRANOLA",
			"GRAN VITA CEREAL GRANOLA",
			"GRAN VITA OATS INSTANT",
			"GRANDMAS CHOC CHIP FACTORY",
			"GRANDMAS COOKIES FACTORY",
			"GRANUTS REGULAR",
			"GREAT VALUE CEREAL ADULT",
			"GREAT VALUE CEREAL GRANOLA",
			"GREAT VALUE CEREAL KIDS",
			"GREAT VALUE COOKIE OTHER",
			"GREAT VALUE NUTS REGULAR",
			"GREAT VALUE OATS REGULAR",
			"GREAT VALUE POPCORN MW",
			"GREAT VALUE POTATO REGULAR",
			"GREAT VALUE TORTILLA REGULAR",
			"GREEN MOUNTAIN",
			"GUACHA REGULAR",
			"GULLON REGULAR",
			"HAPPY FAMILY REGULAR",
			"HAWAIIAN CRUNCH REGULAR",
			"HELIOS CEREAL GRANOLA",
			"HELIOS COOKIE OTHER",
			"HERRS CORN REGULAR",
			"HERRS POTATO REGULAR",
			"HERRS TORTILLA REGULAR",
			"HIDROTERRA HEALTHY SNACK",
			"HIDROTERRA OTHER SAVORY REGULAR",
			"HORTIFRUTI REGULAR",
			"HULA HOOPS REGULAR",
			"IBERIA REGULAR",
			"INA REGULAR",
			"INCAPARINA REGULAR",
			"INTERCAMPO REGULAR",
			"JACKS REGULAR",
			"JOLLY TIME REGULAR",
			"JULIETA REGULAR",
			"KACHITOS REGULAR",
			"KAMOJA OATS TRADITIONAL",
			"KAMOJA POPCORN MW",
			"KAPRICHITOS REGULAR",
			"KARAMELADAS REGULAR",
			"KEEBLER REGULAR",
			"KELLOGGS CEREAL ADULT",
			"KELLOGGS CEREAL GRANOLA",
			"KELLOGGS CEREAL KIDS",
			"KETTLE REGULAR",
			"KIDDYLICIOUS CEREAL KIDS",
			"KIDDYLICIOUS CRACKERS REGULAR",
			"KITTY CORN CHIP REGULAR",
			"KITTY EXTRUDED REGULAR",
			"KITTY MIX REGULAR",
			"KITTY POPCORN REGULAR",
			"KITTY POTATO REGULAR",
			"KITTY VARIETY REGULAR",
			"KOCTEL REGULAR",
			"KRAFT REGULAR",
			"LA CHILERA HEALTHY SNACK",
			"LA CHILERA POPCORN RTE REGULAR",
			"LA CHILERA RTE DIPS REGULAR",
			"LAYS",
			"LAYS BAKED",
			"LAYS KETTLE",
			"LAYS MAXX",
			"LAYS STAX",
			"LE VENEZIANE REGULAR",
			"LINAVENA REGULAR",
			"LONCHERA REGULAR",
			"LOS CEBOLLINES DIPS REGULAR",
			"LOS CEBOLLINES REGULAR",
			"LOTUS REGULAR",
			"MADA REGULAR",
			"MAIZENA REGULAR",
			"MALT O MEAL CEREAL ADULT",
			"MALT O MEAL OATS INSTANT",
			"MAMUT MINI",
			"MAMUT REGULAR",
			"MAMUT REGULAR REGULAR",
			"MANI MOTO REGULAR",
			"MANIAX NUTS",
			"MANIAX REGULAR",
			"MANITOBA NUTS REGULAR",
			"MANITOBA POPCORN MW",
			"MARCELINO COOKIE CHOC CHIP",
			"MARCELINO DIPS REGULAR",
			"MARIAN REGULAR",
			"MARINELA REGULAR",
			"MCCALLUMS REGULAR",
			"MEGA NUTS EXTRUDED REGULAR",
			"MEGA NUTS HEALTHY SNACK REGULAR",
			"MEGA NUTS POTATO REGULAR",
			"MEGA NUTS SNACK MIX REGULAR",
			"MINI CHOKIS BLACK",
			"MINI PRETZEL REGULAR",
			"MUCHO NACHO REGULAR",
			"MUNCHIES REGULAR",
			"MUNCHOS REJILLAS",
			"NABISCO COOKIE CHOC CHIP",
			"NABISCO COOKIE OATS",
			"NABISCO COOKIE SANDWICH",
			"NABISCO CRACKERS REGULAR",
			"NABISCO CRACKERS SALTINE",
			"NACHO SOMBRERON",
			"NATUCHIPS PLATANO MADURO",
			"NATUCHIPS PLATANO VERDE",
			"NATUCHIPS REGULAR",
			"NATURAL HONEY",
			"NATURES HEART NUTS REGULAR",
			"NATURES HEART OATS INSTANT",
			"NAU REGULAR",
			"NESTLE CEREAL ADULT",
			"NESTLE CEREAL GRANOLA",
			"NESTLE CEREAL KIDS",
			"NUT HARVEST REGULAR",
			"NUTELLA REGULAR",
			"NUTRIEVO REGULAR",
			"NUTRISNACKS REGULAR",
			"ORCHARD VALLEY HARVEST",
			"ORGRAN REGULAR",
			"ORIGINAL GOURMET",
			"ORVILLE REDENBACHERS",
			"ORVILLE REGULAR",
			"OTHER PEPSICO EXTRUDED SUBBRAND",
			"OTHER PEPSICO PELLET SUBBRAND",
			"OTHER PEPSICO POTATO SUBBRAND",
			"OTHER SUBBRAND CORN",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND OTHER",
			"OTHER SUBBRAND POPCORN-MW",
			"OTHER SUBBRAND POPCORN-RTE",
			"OTHER SUBBRAND PORK RINDS",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND SALTY MIX",
			"OTHER SUBBRAND TORTILLA",
			"OTHER SUBBRAND VARIETY PK",
			"OTROS OATS TRADITIONAL",
			"OTROS POPCORN MW",
			"OUR FINEST REGULAR",
			"PACHANGA MIX REGULAR",
			"PAKETAXO REGULAR",
			"PAN CREMA REGULAR",
			"PAN SINAI REGULAR",
			"PANCREMA CLASICA",
			"PEPPERIDGE FARM COOKIE FILLED",
			"PEPPERIDGE FARM CRACKERS SALTINE",
			"PICONAS REGULAR",
			"PICONCITOS REGULAR",
			"PIKARONES REGULAR",
			"PLANTERS REGULAR",
			"POFFETS REGULAR",
			"POP  TIME REGULAR",
			"POP WEAVER REGULAR",
			"POPCORNERS",
			"POPSIS REGULAR",
			"POST REGULAR",
			"POZUELO COOKIE MARIAS",
			"POZUELO COOKIE OATS",
			"POZUELO COOKIE REGULAR",
			"POZUELO CRACKER SALTINE",
			"PRINGLES",
			"PRO HEALTHY SNACK",
			"PRO NUTS REGULAR",
			"PRO PAPAS REGULAR",
			"PRO PLATANOS REGULAR",
			"PRO SNACKS MIX",
			"PRODECA REGULAR",
			"QUAKER BARS CHEWY",
			"QUAKER BARS CRUNCHY",
			"QUAKER BARS FLATS",
			"QUAKER BARS FRUIT",
			"QUAKER BARS GRANOLA",
			"QUAKER BARS MINI",
			"QUAKER CEREAL 100PCT NATURAL",
			"QUAKER CEREAL CAPN CRUNCH",
			"QUAKER CEREAL CHOCOBLAST",
			"QUAKER CEREAL CHOCOSTARS",
			"QUAKER CEREAL FRUITY OHS",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL HEARTS",
			"QUAKER CEREAL LIFE",
			"QUAKER CEREAL MULTIBRAN",
			"QUAKER CEREAL OTHER",
			"QUAKER CEREAL SQUARES",
			"QUAKER GALLETAS AVENA",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS REGULAR",
			"QUAKER OATS INSTANT",
			"QUAKER OATS MOLIDA",
			"QUAKER OATS MOSH",
			"QUAKER OATS QUICK",
			"QUAKER OATS REGULAR",
			"QUAKER YOGURT",
			"QUILALI REGULAR",
			"RANCHERITOS REGULAR",
			"RECETA CRUJIENTE SAL",
			"RICITO REGULAR",
			"RICKS CORN REGULAR",
			"RICKS EXTRUDED REGULAR",
			"RICKS HEALTHY SNACK REGULAR",
			"RICKS PELLET REGULAR",
			"RICKS POTATO REGULAR",
			"RICKS SNACK MIX REGULAR",
			"RIQUISIMA REGULAR",
			"ROLAND HEALTHY SNACK",
			"ROLAND NUTS REGULAR",
			"ROLD GOLD REGULAR",
			"ROYAL DANSK",
			"RUFFLES REGULAR",
			"RUNNERS REGULAR",
			"SABEMAS COOKIE REGULAR",
			"SABEMAS OATS INSTANT",
			"SABEMAS OATS TADITIONAL",
			"SABEMAS POPCORN MW",
			"SABEMAS TORTILLA REGULAR",
			"SABRIMINIS CHARROS",
			"SABRISEMANA REGULAR",
			"SABRITAS REGULAR",
			"SABRITONES REGULAR",
			"SALUVITA REGULAR",
			"SAMS CHOICE",
			"SAN ENRIQUE REGULAR",
			"SANISSIMO REGULAR",
			"SASSON DIPS REGULAR",
			"SASSON NUTS REGULAR",
			"SEEBERGER REGULAR",
			"SENORIAL ARITOS",
			"SENORIAL BOLITAS QUESO",
			"SENORIAL CHICHARRON",
			"SENORIAL NACHOS TORTILLA CHIPS",
			"SENORIAL PLATANITOS",
			"SENORIAL POPCORN",
			"SENORIAL QUESIFRITO",
			"SENORIAL RINGOS",
			"SENORIAL RUFITAS",
			"SENORIAL SALTY MIX",
			"SENORIAL SOPLITOS",
			"SENORIAL TORTICHIPS",
			"SENORIAL TORTILLA REGULAR",
			"SENORIAL TORTILLITAS",
			"SENORIAL YUCARICAS",
			"SENSIBLE PORTIONS EXTRUDED REGULAR",
			"SENSIBLE PORTIONS HEALTHY SNACK",
			"SHAKA LAKA CEREAL ADULT",
			"SHAKA LAKA CEREAL KIDS",
			"SMARTFOOD REGULAR",
			"SNAK TIME REGULAR",
			"SNYDERS PRETZEL REGULAR",
			"STILA REGULAR",
			"SUANDY REGULAR",
			"SULI CEREAL KIDS",
			"SULI CRACKER SALTINE",
			"SULI EXTRUDED REGULAR",
			"SULI FILLED",
			"SULI HEALTHY SNACK",
			"SULI MARIAS",
			"SULI OATS REGULAR",
			"SULI OTHER",
			"SULI POTATO REGULAR",
			"SUN CHIPS REGULAR",
			"SUNSHINE SNACKS",
			"TAIFELDS REGULAR",
			"TAKIS",
			"TAQUERITOS REGULAR",
			"TELUMA REGULAR",
			"TERRA POTATO REGULAR",
			"TERRA SALTY MIX REGULAR",
			"TIA ANGELITA REGULAR",
			"TITO PLATANITAS REGULAR",
			"TITO SALTY MIX",
			"TORRADITOS REGULAR",
			"TORTI EXPRESS",
			"TORTICHIPS REGULAR",
			"TORTILLEROS REGULAR",
			"TORTRIX CHAPIMIX",
			"TORTRIX CHICHARRON",
			"TORTRIX CORN",
			"TORTRIX SNACK MIX",
			"TORTRIX TORTILLA",
			"TORTRIX TWIST",
			"TOSH CEREAL ADULT",
			"TOSH CEREAL GRANOLA",
			"TOSH CRACKER",
			"TOSH EXTRUDED REGULAR",
			"TOSH NUTS REGULAR",
			"TOSH OATS REGULAR",
			"TOSH TORTILLA REGULAR",
			"TOSTACHOS REGULAR",
			"TOSTITOS CANTINA",
			"TOSTITOS CRISPY ROUNDS",
			"TOSTITOS DIPS REGULAR",
			"TOSTITOS REGULAR",
			"TOSTITOS SANTE FE",
			"TOSTITOS SCOOPS",
			"TOSTITOS TOQUE DE LIMA",
			"TOTIS CORN REGULAR",
			"TOTIS EXTRUDED REGULAR",
			"TOTIS POPCORN RTE REGULAR",
			"TRIKOS CORN REGULAR",
			"TRIKOS EXTRUDED REGULAR",
			"TRONIX CORN REGULAR",
			"TRONIX HEALTHY SNACK REGULAR",
			"TROPIC",
			"TROPICAL",
			"TUTO SNACK",
			"VARIETY OTHER SUBBRAND",
			"WORLD TABLE",
			"YA ESTA EXTRUDED REGULAR",
			"YA ESTA OATS INSTANT",
			"YA ESTA POPCORN RTE REGULAR",
			"YA ESTA RTE DIPS",
			"YA ESTA TORTILLA REGULAR",
			"YOGI REGULAR",
			"YUMMIES DEL RANCHO",
			"YUMMIES EXTRUDED CAPPY",
			"YUMMIES POPCORN RTE CAPPY",
			"YUMMIES RANCHITAS",
			"YUMMIES TACO",
			"YUMMIES TRONES CHICHARRON",
			"YUMMIES TRONES TORTILLA",
			"YUMMIES ZAMBOS HEALTHY SNACK",
			"YUMMIES ZAMBOS SALTY MIX",
			"YUMMIES ZIBAS EXTRUDED",
			"YUMMIES ZIBAS POTATO",
			"YUMMIX REGULAR",
		],
		packSize: [
			"01P-PACK",
			"01X-SS XSMALL",
			"02P-PACK",
			"02X-SS SMALL",
			"03P-PACK",
			"03X-SS MEDIUM",
			"04P-PACK",
			"04X-SS LARGE",
			"05P-PACK",
			"05X-SS XLARGE",
			"06P-PACK",
			"06X-SS XXLARGE",
			"09X-JUMBO",
			"09X-XXXLARGE",
			"16X-SUPER SIZE",
		],
		date: "02/01/2024-03/01/2024",
		variables: ["priceByUnitSellOut"],
		views: ["absPeriodCompare", "currentPeriod", "periodCompare", "lastPeriod"],
	},
	{
		country: "COLOMBIA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL COLOMBIA-DTS"],
		channel: ["DTS"],
		periodView: ["na"],
		viewTop: 10,
		data: "sellOut",
		viewX: "absGrowthSOM",
		viewY: "priceSellOut",
		viewSize: "somSalesUnitSellOut",
		xSOMGroup: "Value",
		xSOMMeasure: "Category",
		ySOMGroup: "perVolume",
		ySOMMeasure: "",
		viewType: "subBrandSize",
		category: ["BIS-COOKIES", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-NUTS & SEEDS", "SAV-SALTY"],
		vendor: [
			"BIMBO",
			"CIA NAL CHOCOLATES",
			"COLOMBINA SA",
			"COMESTIBLES RICOS",
			"DIPSA",
			"FRITO MIX",
			"KELLOGGS",
			"LA VICTORIA",
			"MANITOBA",
			"MARS",
			"MEKATO",
			"OTHER VENDOR",
			"PEPSICO",
			"PRIVATE LABEL",
			"PRODUCTOS RAMO",
			"QUALA",
			"VECTOR FOODS",
			"YUPI",
		],
		segment: [
			"CKY-CHOC CHIP",
			"CKY-MARSHMLW",
			"CKY-OAT_QUAKER",
			"CKY-OTHER",
			"CKY-SANDWICH",
			"FDS-BARS OAT",
			"FDS-CEREAL GRANOLA",
			"FDS-OATS NATURAL",
			"SAV-CORN",
			"SAV-EXTRUDED",
			"SAV-HLTHY SNACK",
			"SAV-NUTS",
			"SAV-OTHER",
			"SAV-PELLET",
			"SAV-POPCORN-RTE",
			"SAV-PORK RINDS",
			"SAV-POTATO",
			"SAV-SALTY MIX",
			"SAV-TORTILLA",
			"SAV-VARIETY PK",
			"SAV-WHEAT",
		],
		brand: [
			"ABURRA NUTS PASAS",
			"ABURRA NUTS SAL",
			"BARY NUTS MIX",
			"BARY NUTS OTROS",
			"BARY NUTS PASAS",
			"BARY NUTS RECUBIERTO",
			"BARY NUTS SAL",
			"CHEESE TRIS REGULAR",
			"CHEETOS BOLICHEETOS",
			"CHEETOS CRISPETA",
			"CHEETOS REGULAR",
			"CHEETOS TRISSITOS",
			"CHEETOS WOOW",
			"CHIDOS",
			"CHIRICO CHIRIQUITO",
			"CHIRICO CHIRIQUITO PORK RIND",
			"CHOCLITOS REGULAR",
			"CHOKIS BLACK",
			"CHOKIS CHOCOBASE",
			"CHOKIS EXTRUDED",
			"CHOKIS MINI",
			"CHOKIS REGULAR",
			"CHOKIS SANDWICH",
			"COLOMBINA EXTRUDED",
			"COLOMBINA NUTS MIX",
			"COLOMBINA NUTS RECUBIERTO",
			"COLOMBINA POPCORN",
			"DE TODITO MANANITA",
			"DE TODITO REGULAR",
			"DELIMANI NUTS MIX",
			"DELIMANI NUTS OTROS",
			"DELIMANI NUTS PASAS",
			"DELIMANI NUTS SAL",
			"DONA BETTY NUTS OTROS",
			"DONA BETTY NUTS PASAS",
			"DONA BETTY NUTS RECUBIERTO",
			"DORITOS DINAMITA",
			"DORITOS DIPPAS",
			"DORITOS REGULAR",
			"FRITO LAY ASSORTED",
			"FRITO LAY CRONCH",
			"FRITO LAY LONCHERA",
			"FRITO LAY NUTS MIX",
			"FRITO LAY NUTS PASAS",
			"FRITO LAY NUTS RECUBIERTO",
			"FRITO LAY NUTS SAL",
			"FRITO LAY PROMOTIONAL CHOCO CHIP",
			"FRITO LAY PROMOTIONAL EXTRUDED",
			"FRITO LAY PROMOTIONAL HEALTHY",
			"FRITO LAY PROMOTIONAL MIX",
			"FRITO LAY PROMOTIONAL OATS",
			"FRITO LAY PROMOTIONAL TORTILLA",
			"FRITO LAY PROMOTIONAL VARIETY",
			"FRITO LAY VARIETY",
			"FRITO MIX EXTRUDED",
			"FRITO MIX HEALTHY",
			"FRITO MIX LISSITAZ",
			"FRITO MIX MEGA ONDITAS",
			"FRITO MIX PELLET",
			"FRITO MIX PELLET MEGAFRITOS",
			"FRITO MIX PORKRIND",
			"FRITO MIX POTATO",
			"FRITO MIX POTATO MEGAFRITOS",
			"FRITO MIX RIZZZ",
			"FRITO MIX ROSQUISS",
			"FRITO MIX RULASS",
			"FRITO MIX RULITASS",
			"FRITO MIX RULIZZ",
			"FRITO MIX TORTILLA",
			"FRITO MIX TOZIMIEL",
			"FRITO MIX TOZIMIX",
			"FRITO MIX WHEAT",
			"FRITO SALTY MEGAFRITOS DEL VALLE",
			"FRITO SALTY MIX",
			"GAMESA FLORENTINAS",
			"GAMESA NAVIDAD",
			"GUADALUPE",
			"GUDIZ REGULAR",
			"HATSU NUTS MIX",
			"HATSU NUTS OTROS",
			"HATSU NUTS SAL",
			"JACKS CHICHARRON",
			"JACKS CRONCH",
			"JACKS NUTS SAL",
			"JACKS PALITOS REGULAR",
			"JACKS RAQUETY",
			"JACKS SALTY MIX",
			"LA ESPECIAL CORN",
			"LA ESPECIAL EXTRUDED",
			"LA ESPECIAL HEALTHY SNACKS",
			"LA ESPECIAL NUTS MIX",
			"LA ESPECIAL NUTS OTROS",
			"LA ESPECIAL NUTS PASAS",
			"LA ESPECIAL NUTS RECUBIERTO",
			"LA ESPECIAL NUTS SAL",
			"LA ESPECIAL TORTILLA",
			"LA ESPECIAL VARIETY PK",
			"LA ESPECIAL WHEAT",
			"LA VICTORIA BESI",
			"LA VICTORIA CHICHARRON",
			"LA VICTORIA EXTRUDED",
			"LA VICTORIA EXTRUDED CHISSTOZO",
			"LA VICTORIA HEALTHY",
			"LA VICTORIA MIX",
			"LA VICTORIA MIX PLATANUDO",
			"LA VICTORIA OTHER",
			"LA VICTORIA POTATO",
			"LA VICTORIA TORTILLA",
			"LA VICTORIA VARIETY",
			"LA VICTORIA WAVY",
			"LA VICTORIA WHEAT",
			"LA VICTORIA WHEAT BESI",
			"LALO",
			"M&M NUTS RECUBIERTO",
			"M&MS NUTS RECUBIERTO",
			"MAMA INES",
			"MAMUT REGULAR",
			"MANIMOTO NUTS MIX",
			"MANIMOTO NUTS OTROS",
			"MANIMOTO NUTS PASAS",
			"MANIMOTO NUTS RECUBIERTO",
			"MANIMOTO NUTS SAL",
			"MANITOBA CORN",
			"MANITOBA NUTS MIX",
			"MANITOBA NUTS OTROS",
			"MANITOBA NUTS PASAS",
			"MANITOBA NUTS RECUBIERTO",
			"MANITOBA NUTS SAL",
			"MARGARITA FOSFORITOS",
			"MARGARITA KETTEL",
			"MARGARITA REGULAR",
			"MARGARITA SENSATIONS",
			"MARGARITA WAVY",
			"MEKATO EXTRUDED",
			"MEKATO HLTHY SNACK",
			"MEKATO REGULAR",
			"MEKATO SALTY MIX",
			"MEKATO TORTILLA",
			"MEKATO VARIETY PK",
			"MONTEROJO HEALTHY",
			"MONTEROJO MIXTO",
			"MONTEROJO POPCORN",
			"MONTEROJO POTATO",
			"MONTEROJO TORTILLA",
			"MONTEROJO VARIETY",
			"MONTEROJO WHEAT",
			"NAATU GRANOLA",
			"NAATU REGULAR",
			"NATUCHIPS PLATANO",
			"NATUCHIPS RICE",
			"NATUCHIPS TORTILLA",
			"NATUCHIPS WAVY",
			"NATUCHIPS YUCA",
			"NATURES HEART NUTS MIX",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND HEALTHY",
			"OTHER SUBBRAND MIX",
			"OTHER SUBBRAND NUTS MIX",
			"OTHER SUBBRAND NUTS OTROS",
			"OTHER SUBBRAND NUTS PASAS",
			"OTHER SUBBRAND NUTS RECUBIERTO",
			"OTHER SUBBRAND NUTS SAL",
			"OTHER SUBBRAND PELLET",
			"OTHER SUBBRAND POPCORN",
			"OTHER SUBBRAND PORKRIND",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND TORTILLA",
			"OTHER SUBBRAND VARIETY",
			"OTHER SUBBRAND WHEAT",
			"PA MI GENTE",
			"PLANTERS NUTS RECUBIERTO",
			"PLANTERS NUTS SAL",
			"POPETAS MIX",
			"PRINGLES REGULAR",
			"PRIVATE LABEL EXTRUDED",
			"PRIVATE LABEL HEALTHY",
			"PRIVATE LABEL MIX",
			"PRIVATE LABEL NUTS MIX",
			"PRIVATE LABEL NUTS OTROS",
			"PRIVATE LABEL NUTS PASAS",
			"PRIVATE LABEL NUTS RECUBIERTO",
			"PRIVATE LABEL NUTS SAL",
			"PRIVATE LABEL PELLET",
			"PRIVATE LABEL POPCORN",
			"PRIVATE LABEL POTATO",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL VARIETY",
			"PRIVATE LABEL WHEAT",
			"QUAKER BARS REGULAR",
			"QUAKER CRACKER INTEGRAL",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS REGULAR",
			"QUAKER GALLETAS SANDWICH",
			"RAMO CASERITAS",
			"RAMO MIXTO",
			"RAMO PATACON",
			"RAMO POPCORN",
			"RAMO PORK RIND",
			"RAMO TORTILLA",
			"RAMO VARIETY",
			"SANISSIMO",
			"STAX REGULAR",
			"SUNBITES REGULAR",
			"SUNBITES RICE",
			"SUPER RICAS CORN",
			"SUPER RICAS EXTRUDED",
			"SUPER RICAS PELLET",
			"SUPER RICAS PORK RIND",
			"SUPER RICAS POTATO",
			"SUPER RICAS TAJADITAS",
			"SUPER RICAS VARIETY",
			"SUPER RICAS WHEAT",
			"TAKIS REGULAR",
			"TODO RICO",
			"TOSTITOS OTHER SUBBRAND",
			"TRAILMIX NUTS MIX",
			"YUPI EL GOLPE",
			"YUPI EXTRUDED",
			"YUPI HEALTHY",
			"YUPI PELLET",
			"YUPI PORCORN",
			"YUPI POTATO",
			"YUPI TOSTI",
			"YUPI VARIETY",
			"YUPI WHEAT",
		],
		subBrand: [
			"ABURRA NUTS PASAS",
			"ABURRA NUTS SAL",
			"BARY NUTS MIX",
			"BARY NUTS OTROS",
			"BARY NUTS PASAS",
			"BARY NUTS RECUBIERTO",
			"BARY NUTS SAL",
			"CHEESE TRIS REGULAR",
			"CHEETOS BOLICHEETOS",
			"CHEETOS CRISPETA",
			"CHEETOS REGULAR",
			"CHEETOS TRISSITOS",
			"CHEETOS WOOW",
			"CHIDOS",
			"CHIRICO CHIRIQUITO",
			"CHIRICO CHIRIQUITO PORK RIND",
			"CHOCLITOS REGULAR",
			"CHOKIS BLACK",
			"CHOKIS CHOCOBASE",
			"CHOKIS EXTRUDED",
			"CHOKIS MINI",
			"CHOKIS REGULAR",
			"CHOKIS SANDWICH",
			"COLOMBINA EXTRUDED",
			"COLOMBINA NUTS MIX",
			"COLOMBINA NUTS RECUBIERTO",
			"COLOMBINA POPCORN",
			"DE TODITO MANANITA",
			"DE TODITO REGULAR",
			"DELIMANI NUTS MIX",
			"DELIMANI NUTS OTROS",
			"DELIMANI NUTS PASAS",
			"DELIMANI NUTS SAL",
			"DONA BETTY NUTS OTROS",
			"DONA BETTY NUTS PASAS",
			"DONA BETTY NUTS RECUBIERTO",
			"DORITOS DINAMITA",
			"DORITOS DIPPAS",
			"DORITOS REGULAR",
			"FRITO LAY ASSORTED",
			"FRITO LAY CRONCH",
			"FRITO LAY LONCHERA",
			"FRITO LAY NUTS MIX",
			"FRITO LAY NUTS PASAS",
			"FRITO LAY NUTS RECUBIERTO",
			"FRITO LAY NUTS SAL",
			"FRITO LAY PROMOTIONAL CHOCO CHIP",
			"FRITO LAY PROMOTIONAL EXTRUDED",
			"FRITO LAY PROMOTIONAL HEALTHY",
			"FRITO LAY PROMOTIONAL MIX",
			"FRITO LAY PROMOTIONAL OATS",
			"FRITO LAY PROMOTIONAL TORTILLA",
			"FRITO LAY PROMOTIONAL VARIETY",
			"FRITO LAY VARIETY",
			"FRITO MIX EXTRUDED",
			"FRITO MIX HEALTHY",
			"FRITO MIX LISSITAZ",
			"FRITO MIX MEGA ONDITAS",
			"FRITO MIX PELLET",
			"FRITO MIX PELLET MEGAFRITOS",
			"FRITO MIX PORKRIND",
			"FRITO MIX POTATO",
			"FRITO MIX POTATO MEGAFRITOS",
			"FRITO MIX RIZZZ",
			"FRITO MIX ROSQUISS",
			"FRITO MIX RULASS",
			"FRITO MIX RULITASS",
			"FRITO MIX RULIZZ",
			"FRITO MIX TORTILLA",
			"FRITO MIX TOZIMIEL",
			"FRITO MIX TOZIMIX",
			"FRITO MIX WHEAT",
			"FRITO SALTY MEGAFRITOS DEL VALLE",
			"FRITO SALTY MIX",
			"GAMESA FLORENTINAS",
			"GAMESA NAVIDAD",
			"GUADALUPE",
			"GUDIZ REGULAR",
			"HATSU NUTS MIX",
			"HATSU NUTS OTROS",
			"HATSU NUTS SAL",
			"JACKS CHICHARRON",
			"JACKS CRONCH",
			"JACKS NUTS SAL",
			"JACKS PALITOS REGULAR",
			"JACKS RAQUETY",
			"JACKS SALTY MIX",
			"LA ESPECIAL CORN",
			"LA ESPECIAL EXTRUDED",
			"LA ESPECIAL HEALTHY SNACKS",
			"LA ESPECIAL NUTS MIX",
			"LA ESPECIAL NUTS OTROS",
			"LA ESPECIAL NUTS PASAS",
			"LA ESPECIAL NUTS RECUBIERTO",
			"LA ESPECIAL NUTS SAL",
			"LA ESPECIAL TORTILLA",
			"LA ESPECIAL VARIETY PK",
			"LA ESPECIAL WHEAT",
			"LA VICTORIA BESI",
			"LA VICTORIA CHICHARRON",
			"LA VICTORIA EXTRUDED",
			"LA VICTORIA EXTRUDED CHISSTOZO",
			"LA VICTORIA HEALTHY",
			"LA VICTORIA MIX",
			"LA VICTORIA MIX PLATANUDO",
			"LA VICTORIA OTHER",
			"LA VICTORIA POTATO",
			"LA VICTORIA TORTILLA",
			"LA VICTORIA VARIETY",
			"LA VICTORIA WAVY",
			"LA VICTORIA WHEAT",
			"LA VICTORIA WHEAT BESI",
			"LALO",
			"M&M NUTS RECUBIERTO",
			"M&MS NUTS RECUBIERTO",
			"MAMA INES",
			"MAMUT REGULAR",
			"MANIMOTO NUTS MIX",
			"MANIMOTO NUTS OTROS",
			"MANIMOTO NUTS PASAS",
			"MANIMOTO NUTS RECUBIERTO",
			"MANIMOTO NUTS SAL",
			"MANITOBA CORN",
			"MANITOBA NUTS MIX",
			"MANITOBA NUTS OTROS",
			"MANITOBA NUTS PASAS",
			"MANITOBA NUTS RECUBIERTO",
			"MANITOBA NUTS SAL",
			"MARGARITA FOSFORITOS",
			"MARGARITA KETTEL",
			"MARGARITA REGULAR",
			"MARGARITA SENSATIONS",
			"MARGARITA WAVY",
			"MEKATO EXTRUDED",
			"MEKATO HLTHY SNACK",
			"MEKATO REGULAR",
			"MEKATO SALTY MIX",
			"MEKATO TORTILLA",
			"MEKATO VARIETY PK",
			"MONTEROJO HEALTHY",
			"MONTEROJO MIXTO",
			"MONTEROJO POPCORN",
			"MONTEROJO POTATO",
			"MONTEROJO TORTILLA",
			"MONTEROJO VARIETY",
			"MONTEROJO WHEAT",
			"NAATU GRANOLA",
			"NAATU REGULAR",
			"NATUCHIPS PLATANO",
			"NATUCHIPS RICE",
			"NATUCHIPS TORTILLA",
			"NATUCHIPS WAVY",
			"NATUCHIPS YUCA",
			"NATURES HEART NUTS MIX",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND HEALTHY",
			"OTHER SUBBRAND MIX",
			"OTHER SUBBRAND NUTS MIX",
			"OTHER SUBBRAND NUTS OTROS",
			"OTHER SUBBRAND NUTS PASAS",
			"OTHER SUBBRAND NUTS RECUBIERTO",
			"OTHER SUBBRAND NUTS SAL",
			"OTHER SUBBRAND PELLET",
			"OTHER SUBBRAND POPCORN",
			"OTHER SUBBRAND PORKRIND",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND TORTILLA",
			"OTHER SUBBRAND VARIETY",
			"OTHER SUBBRAND WHEAT",
			"PA MI GENTE",
			"PLANTERS NUTS RECUBIERTO",
			"PLANTERS NUTS SAL",
			"POPETAS MIX",
			"PRINGLES REGULAR",
			"PRIVATE LABEL EXTRUDED",
			"PRIVATE LABEL HEALTHY",
			"PRIVATE LABEL MIX",
			"PRIVATE LABEL NUTS MIX",
			"PRIVATE LABEL NUTS OTROS",
			"PRIVATE LABEL NUTS PASAS",
			"PRIVATE LABEL NUTS RECUBIERTO",
			"PRIVATE LABEL NUTS SAL",
			"PRIVATE LABEL PELLET",
			"PRIVATE LABEL POPCORN",
			"PRIVATE LABEL POTATO",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL VARIETY",
			"PRIVATE LABEL WHEAT",
			"QUAKER BARS REGULAR",
			"QUAKER CRACKER INTEGRAL",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS REGULAR",
			"QUAKER GALLETAS SANDWICH",
			"RAMO CASERITAS",
			"RAMO MIXTO",
			"RAMO PATACON",
			"RAMO POPCORN",
			"RAMO PORK RIND",
			"RAMO TORTILLA",
			"RAMO VARIETY",
			"SANISSIMO",
			"STAX REGULAR",
			"SUNBITES REGULAR",
			"SUNBITES RICE",
			"SUPER RICAS CORN",
			"SUPER RICAS EXTRUDED",
			"SUPER RICAS PELLET",
			"SUPER RICAS PORK RIND",
			"SUPER RICAS POTATO",
			"SUPER RICAS TAJADITAS",
			"SUPER RICAS VARIETY",
			"SUPER RICAS WHEAT",
			"TAKIS REGULAR",
			"TODO RICO",
			"TOSTITOS OTHER SUBBRAND",
			"TRAILMIX NUTS MIX",
			"YUPI EL GOLPE",
			"YUPI EXTRUDED",
			"YUPI HEALTHY",
			"YUPI PELLET",
			"YUPI PORCORN",
			"YUPI POTATO",
			"YUPI TOSTI",
			"YUPI VARIETY",
			"YUPI WHEAT",
		],
		packSize: ["01X-MINI", "04X-SMALL", "07X-MEDIUM", "11X-BIG", "12X-EXTRA BIG", "14X-FAMILY"],
		date: "02/01/2024-03/01/2024",
		variables: ["priceByUnitSellOut"],
		views: ["absPeriodCompare", "currentPeriod", "periodCompare", "lastPeriod"],
	},
	{
		country: "CHILE",
		geoLevel: ["COUNTRY-SUBCHAN"],
		geoLevel2: ["TTL CHILE-SMKT"],
		channel: ["OT"],
		periodView: ["na"],
		viewTop: 10,
		data: "sellOut",
		viewX: "absGrowthSOM",
		viewY: "priceSellOut",
		viewSize: "somSalesUnitSellOut",
		xSOMGroup: "Value",
		xSOMMeasure: "Category",
		ySOMGroup: "perVolume",
		ySOMMeasure: "",
		viewType: "subBrandSize",
		category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-DIPS & SALSA", "SAV-NUTS & SEEDS", "SAV-SALTY"],
		vendor: [
			"BARCEL",
			"CAFE DO BRASIL",
			"CARLOS ORTIZ",
			"COMERCIAL BUENA MESA",
			"DE LA ROSA",
			"FRUTISA",
			"GRUPO NUTRESA",
			"KELLOGGS",
			"MILLANTU",
			"OTHER VENDOR",
			"PAILLAPEN",
			"PANCHO VILLA",
			"PEPSICO",
			"PRIMOR",
			"PRIVATE LABEL",
			"TRENDY",
			"VELARDE",
			"ZE FARMS",
		],
		segment: [
			"CKY-BREAKFAST",
			"CKY-CHOC CHIP",
			"CKY-CHOC COVER",
			"CKY-FILLED",
			"CKY-OAT_QUAKER",
			"CKY-SANDWICH",
			"CRK-BAKED",
			"FDS-BARS",
			"FDS-CEREAL ADULT",
			"FDS-CEREAL GRANOLA",
			"FDS-OATS INSTANT",
			"FDS-OATS MULTISEED",
			"FDS-OATS TRAD",
			"SAV-EXTRUDED",
			"SAV-HLTHY SNACK",
			"SAV-NUTS",
			"SAV-OTHER",
			"SAV-POPCORN-RTE",
			"SAV-POTATO",
			"SAV-RTE DIPS",
			"SAV-SALTY MIX",
			"SAV-TORTILLA",
			"SAV-VARIETY PK",
			"SAV-WHEAT",
		],
		brand: [
			"ARCOR MIXED",
			"BUKA",
			"CABRITAS POPCORN REGULAR",
			"CHEETOS MIX",
			"CHEETOS POPCORN",
			"CHEETOS PUFF",
			"CHEETOS REGULAR",
			"CHEETOS SUFLE",
			"CHEETOS WOOW",
			"CHEEZELS REGULAR",
			"CHIS POP REGULAR",
			"CHOCO CRACS REGULAR",
			"CRUGN BKN EXTRUDED",
			"CRUGN BKN HEALTHY",
			"CRUGN BKN POTATO",
			"CURRITOS REGULAR",
			"DE LA ROSA",
			"DORITOS HEATWAVE",
			"DORITOS REGULAR",
			"DORITOS RULETA",
			"EL ARRIERO REGULAR",
			"EVERCRISP NUTS JAPONES",
			"EVERCRISP NUTS MIXED",
			"EVERCRISP NUTS PREMIUM",
			"EVERCRISP NUTS SAL",
			"EVERCRISP NUTS SIN SAL",
			"EVERCRISP POTATO",
			"EVERCRISP VARIETY",
			"FRUTISA MIXED",
			"FRUTISA PREMIUM",
			"FRUTISA SAL",
			"GALLETAS CONSEN",
			"GATOLATE REGULAR",
			"GRANUTS",
			"INFERNO",
			"KAZAI",
			"KRYZPO REGULAR",
			"LAYS ARTESANAS",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS HILO",
			"LAYS JUNTEMONOS",
			"LAYS MAXX",
			"LAYS MEDITERRANEAS",
			"LAYS SENSATIONS",
			"LAYS VARIETY",
			"LAYS WAVY",
			"MAFER",
			"MARCO POLO CASERAS",
			"MARCO POLO CORTE AMERICANO",
			"MARCO POLO CRUNCHIS",
			"MARCO POLO HEALTHY",
			"MARCO POLO MIX",
			"MARCO POLO MIXED",
			"MARCO POLO NUTS",
			"MARCO POLO OTHER",
			"MARCO POLO POTATO",
			"MARCO POLO PREMIUM",
			"MARCO POLO RAMITAS",
			"MARCO POLO RUSTICAS",
			"MARCO POLO SABORES",
			"MARCO POLO SAL",
			"MARCO POLO SNACK MIX",
			"MARCO POLO TORTILLA",
			"MILLANTU FLAVORED",
			"MILLANTU JAPONES",
			"MILLANTU MIXED",
			"MILLANTU PREMIUM",
			"MILLANTU SAL",
			"MILLANTU SIN SAL",
			"MOMS CASERAS",
			"MOMS CHAPARRITOS",
			"MOMS CHOCLITOS",
			"MOMS RAMITAS",
			"MOMS SUFLES",
			"NUTBAR REGULAR",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND NUTS",
			"OTHER SUBBRAND OTHER",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND WHEAT",
			"PANCHO VILLA JAPONES",
			"PANCHO VILLA NACHOS",
			"PANCHO VILLA PANCHITOS",
			"PANCHO VILLA PAPAS",
			"PATPOT",
			"PRIMOR REGULAR",
			"PRINGLES REGULAR",
			"PRIVATE LABEL EXTRUDED",
			"PRIVATE LABEL NUTS",
			"PRIVATE LABEL PREMIUM",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL WHEAT",
			"QUAKER BARS COLLECTION",
			"QUAKER BARS MULTISEED",
			"QUAKER BARS REGULAR",
			"QUAKER CEREAL FLAKES",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL HEARTS",
			"QUAKER CEREAL QUADRITOS",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS REGULAR",
			"QUAKER GALLETAS RELLENA",
			"QUAKER OATS INSTANT",
			"QUAKER OATS MULTISEMILLAS",
			"QUAKER OATS TRAD",
			"RAICES CHILENAS",
			"RAMITAS EVERCRISP",
			"ROOTS REGULAR",
			"RUFFLES ORIGINAL",
			"SABU MIXED",
			"SABU SAL",
			"SABU SIN SAL",
			"SNACK MIX REGULAR",
			"STAX REGULAR",
			"TAKIS FUEGO",
			"TAKIS OTHER SUBBRAND",
			"TAKIS REGULAR",
			"TIKA ARTESAN CHIPS",
			"TIKA CHIPS",
			"TIKA NATIVE",
			"TIKA TORTIKA",
			"TIM EXTRUDED",
			"TIM OTHER",
			"TIM POTATO",
			"TIM SAL",
			"TIM WHEAT",
			"TODDY BLACK",
			"TODDY BLOCK",
			"TODDY CHOKOMAX",
			"TODDY CLASICA",
			"TODDY EXTRA",
			"TODDY MINI",
			"TOSTITOS DIPS REGULAR",
			"TOSTITOS REGULAR",
			"TRAGA TRAGA REGULAR",
			"TWISTOS INTEGRALES",
			"TWISTOS MINITOSTADITAS",
			"TWISTOS RICE SNACK",
			"VIVO FRUTOS MIXED",
		],
		subBrand: [
			"ARCOR MIXED",
			"BUKA",
			"CABRITAS POPCORN REGULAR",
			"CHEETOS MIX",
			"CHEETOS POPCORN",
			"CHEETOS PUFF",
			"CHEETOS REGULAR",
			"CHEETOS SUFLE",
			"CHEETOS WOOW",
			"CHEEZELS REGULAR",
			"CHIS POP REGULAR",
			"CHOCO CRACS REGULAR",
			"CRUGN BKN EXTRUDED",
			"CRUGN BKN HEALTHY",
			"CRUGN BKN POTATO",
			"CURRITOS REGULAR",
			"DE LA ROSA",
			"DORITOS HEATWAVE",
			"DORITOS REGULAR",
			"DORITOS RULETA",
			"EL ARRIERO REGULAR",
			"EVERCRISP NUTS JAPONES",
			"EVERCRISP NUTS MIXED",
			"EVERCRISP NUTS PREMIUM",
			"EVERCRISP NUTS SAL",
			"EVERCRISP NUTS SIN SAL",
			"EVERCRISP POTATO",
			"EVERCRISP VARIETY",
			"FRUTISA MIXED",
			"FRUTISA PREMIUM",
			"FRUTISA SAL",
			"GALLETAS CONSEN",
			"GATOLATE REGULAR",
			"GRANUTS",
			"INFERNO",
			"KAZAI",
			"KRYZPO REGULAR",
			"LAYS ARTESANAS",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS HILO",
			"LAYS JUNTEMONOS",
			"LAYS MAXX",
			"LAYS MEDITERRANEAS",
			"LAYS SENSATIONS",
			"LAYS VARIETY",
			"LAYS WAVY",
			"MAFER",
			"MARCO POLO CASERAS",
			"MARCO POLO CORTE AMERICANO",
			"MARCO POLO CRUNCHIS",
			"MARCO POLO HEALTHY",
			"MARCO POLO MIX",
			"MARCO POLO MIXED",
			"MARCO POLO NUTS",
			"MARCO POLO OTHER",
			"MARCO POLO POTATO",
			"MARCO POLO PREMIUM",
			"MARCO POLO RAMITAS",
			"MARCO POLO RUSTICAS",
			"MARCO POLO SABORES",
			"MARCO POLO SAL",
			"MARCO POLO SNACK MIX",
			"MARCO POLO TORTILLA",
			"MILLANTU FLAVORED",
			"MILLANTU JAPONES",
			"MILLANTU MIXED",
			"MILLANTU PREMIUM",
			"MILLANTU SAL",
			"MILLANTU SIN SAL",
			"MOMS CASERAS",
			"MOMS CHAPARRITOS",
			"MOMS CHOCLITOS",
			"MOMS RAMITAS",
			"MOMS SUFLES",
			"NUTBAR REGULAR",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND NUTS",
			"OTHER SUBBRAND OTHER",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND WHEAT",
			"PANCHO VILLA JAPONES",
			"PANCHO VILLA NACHOS",
			"PANCHO VILLA PANCHITOS",
			"PANCHO VILLA PAPAS",
			"PATPOT",
			"PRIMOR REGULAR",
			"PRINGLES REGULAR",
			"PRIVATE LABEL EXTRUDED",
			"PRIVATE LABEL NUTS",
			"PRIVATE LABEL PREMIUM",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL WHEAT",
			"QUAKER BARS COLLECTION",
			"QUAKER BARS MULTISEED",
			"QUAKER BARS REGULAR",
			"QUAKER CEREAL FLAKES",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL HEARTS",
			"QUAKER CEREAL QUADRITOS",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS REGULAR",
			"QUAKER GALLETAS RELLENA",
			"QUAKER OATS INSTANT",
			"QUAKER OATS MULTISEMILLAS",
			"QUAKER OATS TRAD",
			"RAICES CHILENAS",
			"RAMITAS EVERCRISP",
			"ROOTS REGULAR",
			"RUFFLES ORIGINAL",
			"SABU MIXED",
			"SABU SAL",
			"SABU SIN SAL",
			"SNACK MIX REGULAR",
			"STAX REGULAR",
			"TAKIS FUEGO",
			"TAKIS OTHER SUBBRAND",
			"TAKIS REGULAR",
			"TIKA ARTESAN CHIPS",
			"TIKA CHIPS",
			"TIKA NATIVE",
			"TIKA TORTIKA",
			"TIM EXTRUDED",
			"TIM OTHER",
			"TIM POTATO",
			"TIM SAL",
			"TIM WHEAT",
			"TODDY BLACK",
			"TODDY BLOCK",
			"TODDY CHOKOMAX",
			"TODDY CLASICA",
			"TODDY EXTRA",
			"TODDY MINI",
			"TOSTITOS DIPS REGULAR",
			"TOSTITOS REGULAR",
			"TRAGA TRAGA REGULAR",
			"TWISTOS INTEGRALES",
			"TWISTOS MINITOSTADITAS",
			"TWISTOS RICE SNACK",
			"VIVO FRUTOS MIXED",
		],
		packSize: [
			"01X-MINI",
			"01X-SS XSMALL",
			"02P-MULTIPK",
			"02X-JR LINE",
			"03P-MULTIPK",
			"04P-MULTIPK",
			"04X-SS LARGE",
			"05P-MULTIPK",
			"06P-MULTIPK",
			"07X-MAX",
			"07X-MEDIUM",
			"08P-MULTIPK",
			"09X-GRANDE",
			"09X-JUMBO",
			"10X-MANGA",
			"11X-BIG",
			"12P-MULTIPK",
			"12X-EXTRA BIG",
			"15P-MULTIPK",
			"15X-MEGA",
			"16X-SUPER SIZE",
			"20P-MULTIPK",
			"22P-MULTIPK",
			"30P-MULTIPK",
			"36P-MULTIPK",
			"50P-MULTIPK",
			"53P-MULTIPK",
			"96P-MULTIPK",
		],
		date: "02/01/2024-03/01/2024",
		variables: ["priceByUnitSellOut"],
		views: ["absPeriodCompare", "currentPeriod", "periodCompare", "lastPeriod"],
	},
	{
		country: "ARGENTINA",
		geoLevel: ["COUNTRY-CHAN"],
		geoLevel2: ["TTL ARGENTINA-OT"],
		channel: ["OT"],
		periodView: ["na"],
		viewTop: 10,
		data: "sellOut",
		viewX: "absGrowthSOM",
		viewY: "priceSellOut",
		viewSize: "somSalesUnitSellOut",
		xSOMGroup: "Value",
		xSOMMeasure: "Category",
		ySOMGroup: "perVolume",
		ySOMMeasure: "",
		viewType: "subBrandSize",
		category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "FDS-RTE CEREAL", "SAV-NUTS & SEEDS", "SAV-POPCORN-MW", "SAV-POPCORN-RTE", "SAV-SALTY"],
		vendor: [
			"5 HISPANOS",
			"ARCOR",
			"BAGLEY",
			"CEREALKO",
			"CHIL",
			"COTO",
			"CROOPERS",
			"DIA",
			"KELLOGGS",
			"MONDELEZ",
			"NABISCO",
			"NIKITOS",
			"OTHER VENDOR",
			"PEPSICO",
			"PRIVATE LABEL",
			"SEMIX",
			"TIA MARUCA",
		],
		segment: [
			"CKY-BREAKFAST",
			"CKY-CHOC CHIP",
			"CKY-CHOC COVER",
			"CKY-FILLED",
			"CKY-MARIAS",
			"CKY-OAT_QUAKER",
			"CKY-OTHER",
			"CKY-RING",
			"CKY-SANDWICH",
			"CKY-TRAD",
			"CKY-WAFER",
			"CRK-BAKED",
			"CRK-FLAVOR",
			"CRK-OTHER",
			"CRK-PLAIN",
			"CRK-RICE",
			"FDS-BARS CHEWY",
			"FDS-BARS NUTR",
			"FDS-BARS OAT",
			"FDS-CEREAL ADULT",
			"FDS-CEREAL GRANOLA",
			"FDS-CEREAL KIDS",
			"FDS-OATS INSTANT",
			"FDS-OATS MILD_PWD",
			"FDS-OATS TRAD",
			"SAV-CORN",
			"SAV-EXTRUDED",
			"SAV-HLTHY SNACK",
			"SAV-NUTS",
			"SAV-OTHER",
			"SAV-PELLET",
			"SAV-POPCORN-MW",
			"SAV-POPCORN-RTE",
			"SAV-POTATO",
			"SAV-SALTY MIX",
			"SAV-TORTILLA",
			"SAV-WHEAT",
		],
		brand: [
			"3DS REGULAR",
			"9 DE ORO BIZCOCHITOS",
			"9 DE ORO PEPAS",
			"9 DE ORO REGULAR",
			"9 DE ORO SCONS",
			"A RODRIGUEZ REGULAR",
			"ACT II REGULAR",
			"ALICANTE REGULAR",
			"ALICORP REGULAR",
			"ALSEVI REGULAR",
			"ALSICA RIERA",
			"AM DAME REGULAR",
			"ANTOJITOS POTATO",
			"ANTOJITOS TORTILLA",
			"ARCOR ABUELA",
			"ARCOR AMOR",
			"ARCOR AZUCARADAS",
			"ARCOR CEREAL MIX",
			"ARCOR CHISPAS",
			"ARCOR CHOCOLINAS",
			"ARCOR CINDOR",
			"ARCOR COOKIE",
			"ARCOR COQUITAS",
			"ARCOR CRIOLLITAS",
			"ARCOR FORMIS",
			"ARCOR MACUCAS",
			"ARCOR MANA",
			"ARCOR NUTS",
			"ARCOR VARIETY",
			"ARGENTITAS REGULAR",
			"ARGENTITAS SIN SAL",
			"AVENTURA REGULAR",
			"BACHILITOS CORN",
			"BACHILITOS NUTS",
			"BACHILITOS POTATO",
			"BACHILITOS WHEAT",
			"BAGLEY MELITAS",
			"BAGLEY MERCI",
			"BAGLEY MERENGADAS",
			"BAGLEY OPERA",
			"BAGLEY PANCHITAS",
			"BAGLEY POLVORITA",
			"BAGLEY RUMBA",
			"BAGLEY SER",
			"BAGLEY SONRISAS",
			"BAGLEY TENTACIONES",
			"BAGLEY VOCACION",
			"BAHLSEN REGULAR",
			"BATATAS REGULAR",
			"BAUDUCCO BREAKFAST",
			"BAUDUCCO REGULAR",
			"BAUDUCCO WAFER",
			"BIG CHEESE REGULAR",
			"BIMBO REGULAR",
			"BONAFIDE REGULAR",
			"BUBY REGULAR",
			"BUN PAPAS",
			"BUN REGULAR",
			"CALLIERI REGULAR",
			"CASCARONES REGULAR",
			"CEREALITAS REGULAR",
			"CERRITO HEALTHY",
			"CERRITO NUTS",
			"CHALITAS REGULAR",
			"CHEETOS REGULAR",
			"CHEVALIER REGULAR",
			"CHIL PAPAS FRITAS",
			"CHIL SLICES INFLADITOS",
			"CIRUELA REGULAR",
			"CIUDAD DEL LAGO REGULAR",
			"CONOSUR NUTS",
			"CONOSUR SALTY MIX",
			"COP NUTS",
			"CORY HIPOPO",
			"COSTA CEREAL",
			"COSTA CHIPS",
			"CRACKETS REGULAR",
			"CRISKEY CORN",
			"CRISKEY POTATO",
			"CROOPERS MIX",
			"CROOPERS REGULAR",
			"CUYOAROMAS MIX",
			"CUYOAROMAS REGULAR",
			"DALE REGULAR",
			"DAMASCOS REGULAR",
			"DE TODITO MIX 3DS",
			"DE TODITO MIX DORITOS",
			"DE TODITO REGULAR",
			"DIA CRACKERS",
			"DICOMERE REGULAR",
			"DON MIGUEL REGULAR",
			"DON SATUR REGULAR",
			"DORITOS DIPPAS",
			"DORITOS FLAVOURS",
			"DORITOS QUESO",
			"DR HAVANNA REGULAR",
			"DULCOR REGULAR",
			"EL CACHAFAZ REGULAR",
			"EL MALLIN REGULAR",
			"EL PEONCITO CORN",
			"EL PEONCITO REGULAR",
			"EL TRIGAL BRIDGE",
			"EL TRIGAL LECHE",
			"FANTOCHE ESPUMITA",
			"FANTOCHE HOROSCOPO",
			"FANTOCHE MARMOLADAS",
			"FANTOCHE REGULAR",
			"FANTOCHE TAPITAS",
			"FRUGLACE REGULAR",
			"FRUIT ME UP REGULAR",
			"GALLETITAS REGULAR",
			"GAONA CRUNCHIPS",
			"GATORADE BARS",
			"GAUCHITAS REGULAR",
			"GONZALO EXTRUDED",
			"GONZALO NUTS",
			"GONZALO POPCORN",
			"GONZALO POTATO",
			"GONZALO REGULAR",
			"GOOD SHOW CORN",
			"GOOD SHOW EXTRUDED",
			"GOOD SHOW NUTS",
			"GOOD SHOW OTHER",
			"GOOD SHOW PELLET",
			"GOOD SHOW POTATO",
			"GOOD SHOW REGULAR",
			"GRANDIET MIX",
			"GRANIX FRUTIGRAN",
			"GRANIX REGULAR",
			"HERRS TORTILLA",
			"HIGOS DE SMIRNA REGULAR",
			"HITS REGULAR",
			"HOJALMAR CAPRI",
			"HOJALMAR REGULAR",
			"IRINA REGULAR",
			"JULI CROC CORN",
			"JULI CROC POPCORN",
			"JUMALA REGULAR",
			"JUNIN REGULAR",
			"KESBUN PIZZA",
			"KESBUN REGULAR",
			"KING REGULAR",
			"KOKITO REGULAR",
			"KRACHITOS CORN",
			"KRACHITOS EXTRUDED",
			"KRACHITOS MIX",
			"KRACHITOS NUTS",
			"KRACHITOS OTHER",
			"KRACHITOS PELLET",
			"KRACHITOS POTATO",
			"KRACHITOS TORTILLA",
			"KRACHITOS WHEAT",
			"KRAFT REGULAR",
			"KRISKI NUTS",
			"KRISKI PELLET",
			"KRISKI POTATO",
			"KRISKI REGULAR",
			"KRISKI WHEAT",
			"KROKANTES EXTRUDED",
			"KROKANTES NUTS",
			"KROKANTES OTHER",
			"KROKANTES POTATO",
			"LA ABUNDANCIA REGULAR",
			"LA CAMPINA REGULAR",
			"LA EGIPCIANA REGULAR",
			"LA PARMESANA REGULAR",
			"LA REINA REGULAR",
			"LAS BRISAS REGULAR",
			"LAYS ACANALADAS",
			"LAYS BAKED",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS GOURMET",
			"LAYS OTHER SUBBRAND",
			"LAYS PAY",
			"LAYS SIN SAL",
			"LAYS STAX",
			"LLUVIA REGULAR",
			"LOS CAROLINOS REGULAR",
			"LOS RUSITOS REGULAR",
			"MABEL COOKIE",
			"MABEL SALTY",
			"MACRITAS REGULAR",
			"MADZ ANDINO REGULAR",
			"MAGICA POLENTA",
			"MAN REGULAR",
			"MANI JA REGULAR",
			"MANI MINGO REGULAR",
			"MANI REPEL REGULAR",
			"MANI SABORZ REGULAR",
			"MANISUR CORN",
			"MANISUR REGULAR",
			"MANITOON REGULAR",
			"MARIPOSA REGULAR",
			"MARISA NUTS",
			"MARISA REGULAR",
			"MARLOMS REGULAR",
			"MAURI REGULAR",
			"MELBA TRAD",
			"MILKA REGULAR",
			"MOLTO REGULAR",
			"MORAN EXTRUDED",
			"MORAN OTHER",
			"MORAN POTATO",
			"NACHO CERAL REGULAR",
			"NATUCHIPS REGULAR",
			"NATURAL BREAK REGULAR",
			"NIKITOS CORN",
			"NIKITOS EXTRUDED",
			"NIKITOS NUTS",
			"NIKITOS OTHER",
			"NIKITOS PELLET",
			"NIKITOS POPCORN",
			"NIKITOS POTATO",
			"NIKITOS WHEAT",
			"NUCETE REGULAR",
			"NUECES REGULAR",
			"OASIS CORN",
			"OASIS NUTS",
			"OASIS PELLET",
			"OASIS POTATO",
			"OASIS WHEAT",
			"OKEBON LECHE",
			"OKEBON MOLINO",
			"OKEBON REGULAR",
			"OREO DUO",
			"OREO GOLDEN",
			"OREO MINI",
			"OREO REGULAR",
			"OREO TRIO",
			"OTHER SUBBRAND COOKIE",
			"OTHER SUBBRAND CORN",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND HEALTHY",
			"OTHER SUBBRAND MIX",
			"OTHER SUBBRAND NUTS",
			"OTHER SUBBRAND OTHER",
			"OTHER SUBBRAND PELLET",
			"OTHER SUBBRAND POPCORN",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND TORTILLA",
			"OTHER SUBBRAND TRADICIONAL",
			"OTHER SUBBRAND WAFER",
			"OTHER SUBBRAND WHEAT",
			"PALI CHIPS",
			"PARNOR PITUSAS",
			"PASAS DE UVA REGULAR",
			"PEDRIN CORN",
			"PEDRIN EXTRUDED",
			"PEDRIN NUTS",
			"PEDRIN OTHER",
			"PEDRIN PELLET",
			"PEDRIN REGULAR",
			"PEDRIN WHEAT",
			"PEHUAMAR ACANALADAS",
			"PEHUAMAR CLASICAS",
			"PEHUAMAR MAICITOS",
			"PEHUAMAR MAICITOS REJILLAS",
			"PEHUAMAR MANI CON PIEL",
			"PEHUAMAR MANI JAPONES",
			"PEHUAMAR MANI PELADO",
			"PEHUAMAR MANI SALADO",
			"PEHUAMAR MIX",
			"PEHUAMAR PALITOS",
			"PEP PALITOS",
			"PEP RAMITAS",
			"PEP RUEDITAS",
			"PEPITOS CHISPAS",
			"PEPITOS MINI",
			"PEPITOS RELLENOS",
			"PEPITOS STICKS",
			"PEPITOS TORTITAS",
			"PEPITOS TRAD",
			"PEPITOS WHITE",
			"PINDY REGULAR",
			"PIPAS REGULAR",
			"POP KORN REGULAR",
			"POSTREFINO REGULAR",
			"POZO REGULAR",
			"PRINGLES REGULAR",
			"PRINGLES TORTILLA CHIPS",
			"PRIVATE LABEL",
			"PRIVATE LABEL CORN",
			"PRIVATE LABEL MIX",
			"PRIVATE LABEL NUTS",
			"PRIVATE LABEL POTATO",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL WHEAT",
			"QUAKER BARS CEREAL",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL HONEY GRAHAM",
			"QUAKER CEREAL HONEY NUTS",
			"QUAKER CEREAL QUADRITOS",
			"QUAKER CRACKER REGULAR",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS MOLDE",
			"QUAKER GALLETAS RELLENA",
			"QUAKER GALLETAS WC",
			"QUAKER OATS EXTRA-FINA",
			"QUAKER OATS INSTANT",
			"QUAKER OATS REGULAR",
			"QUENTO HEALTHY",
			"QUENTO MIX",
			"QUENTO NUTS",
			"QUENTO REGULAR",
			"QUENTO TORTILLA",
			"QUENTO WHEAT",
			"SALADIX BACONZITOS",
			"SALADIX BACONZITOS EXTRUDED",
			"SALADIX CRAX",
			"SALADIX CROSS",
			"SALADIX CROSS WHEAT",
			"SALADIX HULA HULA",
			"SALADIX KESITOS",
			"SALADIX MILANESITAS",
			"SALADIX OTHER",
			"SALADIX PANCHITOS",
			"SALADIX PICANTO",
			"SALADIX PIZZITAS",
			"SALADIX POTATO",
			"SALADIX QUESO",
			"SALADIX RINGS",
			"SALADIX STICKS",
			"SALADIX TORTILLA",
			"SANJUANITA MIX",
			"SANJUANITA REGULAR",
			"SEMIX MANI",
			"SEMIX MIX",
			"SEMIX POPCORN",
			"SEMIX REGULAR",
			"SER SNACKS REGULAR",
			"SNACK REGULAR",
			"SNYDERS REGULAR",
			"SOLITAS REGULAR",
			"SORIANA REGULAR",
			"SUNBITES REGULAR",
			"TEREPIN REGULAR",
			"TERRA MIA REGULAR",
			"TERRAB VARIETY",
			"TERRABUSI ANILLOS",
			"TERRABUSI BOCA DAMA",
			"TERRABUSI CHAMPAGNE",
			"TERRABUSI DUQUESA",
			"TERRABUSI LINCOLN",
			"TERRABUSI MANON",
			"TERRABUSI MARIAS",
			"TERRABUSI ROCOCO",
			"TERRASANA REGULAR",
			"TIA MARUCA ANILLOS",
			"TIA MARUCA AZUCARADAS",
			"TIA MARUCA COOKIE",
			"TIA MARUCA CORAZON",
			"TIA MARUCA CORN",
			"TIA MARUCA JUNGLITAS",
			"TIA MARUCA NUTS",
			"TIA MARUCA PALMERITAS",
			"TIA MARUCA PEPITAS",
			"TIA MARUCA REGULAR",
			"TIA MARUCA ROSQUELLAS",
			"TIA MARUCA TORTITAS",
			"TIA MARUCA TRIANGULITOS",
			"TIA MARUCA VARIETY",
			"TIA MARUCA WHEAT",
			"TODDY CHISPAS",
			"TODDY CHISPAS BLACK",
			"TODDY CHISPAS DDL",
			"TODDY CHOCOLAT",
			"TODDY COCOA BLAST",
			"TODDY EXTRA",
			"TODDY ONE",
			"TODDY RELLENAS",
			"TOSTINAS REGULAR",
			"TOSTITOS REGULAR",
			"TOTAL O CORP",
			"TRAJANO REGULAR",
			"TRIO PEPAS",
			"TRIO REGULAR",
			"TRIO VARIETY",
			"TWISTOS CRACKERS",
			"TWISTOS MINITOSTADITAS",
			"TWISTOS MULTICEREAL",
			"TWISTOS RICE SNACK",
			"VALENTE REGULAR",
			"VILLA RICA REGULAR",
			"WONDERFUL PISTACHIOS",
			"YARIGUARENDA CORN",
			"YUKITAS REGULAR",
			"ZAFRAN REGULAR",
			"ZUCOA REGULAR",
			"ZYMMA POTATO",
			"ZYMMA REGULAR",
		],
		subBrand: [
			"3DS REGULAR",
			"9 DE ORO BIZCOCHITOS",
			"9 DE ORO PEPAS",
			"9 DE ORO REGULAR",
			"9 DE ORO SCONS",
			"A RODRIGUEZ REGULAR",
			"ACT II REGULAR",
			"ALICANTE REGULAR",
			"ALICORP REGULAR",
			"ALSEVI REGULAR",
			"ALSICA RIERA",
			"AM DAME REGULAR",
			"ANTOJITOS POTATO",
			"ANTOJITOS TORTILLA",
			"ARCOR ABUELA",
			"ARCOR AMOR",
			"ARCOR AZUCARADAS",
			"ARCOR CEREAL MIX",
			"ARCOR CHISPAS",
			"ARCOR CHOCOLINAS",
			"ARCOR CINDOR",
			"ARCOR COOKIE",
			"ARCOR COQUITAS",
			"ARCOR CRIOLLITAS",
			"ARCOR FORMIS",
			"ARCOR MACUCAS",
			"ARCOR MANA",
			"ARCOR NUTS",
			"ARCOR VARIETY",
			"ARGENTITAS REGULAR",
			"ARGENTITAS SIN SAL",
			"AVENTURA REGULAR",
			"BACHILITOS CORN",
			"BACHILITOS NUTS",
			"BACHILITOS POTATO",
			"BACHILITOS WHEAT",
			"BAGLEY MELITAS",
			"BAGLEY MERCI",
			"BAGLEY MERENGADAS",
			"BAGLEY OPERA",
			"BAGLEY PANCHITAS",
			"BAGLEY POLVORITA",
			"BAGLEY RUMBA",
			"BAGLEY SER",
			"BAGLEY SONRISAS",
			"BAGLEY TENTACIONES",
			"BAGLEY VOCACION",
			"BAHLSEN REGULAR",
			"BATATAS REGULAR",
			"BAUDUCCO BREAKFAST",
			"BAUDUCCO REGULAR",
			"BAUDUCCO WAFER",
			"BIG CHEESE REGULAR",
			"BIMBO REGULAR",
			"BONAFIDE REGULAR",
			"BUBY REGULAR",
			"BUN PAPAS",
			"BUN REGULAR",
			"CALLIERI REGULAR",
			"CASCARONES REGULAR",
			"CEREALITAS REGULAR",
			"CERRITO HEALTHY",
			"CERRITO NUTS",
			"CHALITAS REGULAR",
			"CHEETOS REGULAR",
			"CHEVALIER REGULAR",
			"CHIL PAPAS FRITAS",
			"CHIL SLICES INFLADITOS",
			"CIRUELA REGULAR",
			"CIUDAD DEL LAGO REGULAR",
			"CONOSUR NUTS",
			"CONOSUR SALTY MIX",
			"COP NUTS",
			"CORY HIPOPO",
			"COSTA CEREAL",
			"COSTA CHIPS",
			"CRACKETS REGULAR",
			"CRISKEY CORN",
			"CRISKEY POTATO",
			"CROOPERS MIX",
			"CROOPERS REGULAR",
			"CUYOAROMAS MIX",
			"CUYOAROMAS REGULAR",
			"DALE REGULAR",
			"DAMASCOS REGULAR",
			"DE TODITO MIX 3DS",
			"DE TODITO MIX DORITOS",
			"DE TODITO REGULAR",
			"DIA CRACKERS",
			"DICOMERE REGULAR",
			"DON MIGUEL REGULAR",
			"DON SATUR REGULAR",
			"DORITOS DIPPAS",
			"DORITOS FLAVOURS",
			"DORITOS QUESO",
			"DR HAVANNA REGULAR",
			"DULCOR REGULAR",
			"EL CACHAFAZ REGULAR",
			"EL MALLIN REGULAR",
			"EL PEONCITO CORN",
			"EL PEONCITO REGULAR",
			"EL TRIGAL BRIDGE",
			"EL TRIGAL LECHE",
			"FANTOCHE ESPUMITA",
			"FANTOCHE HOROSCOPO",
			"FANTOCHE MARMOLADAS",
			"FANTOCHE REGULAR",
			"FANTOCHE TAPITAS",
			"FRUGLACE REGULAR",
			"FRUIT ME UP REGULAR",
			"GALLETITAS REGULAR",
			"GAONA CRUNCHIPS",
			"GATORADE BARS",
			"GAUCHITAS REGULAR",
			"GONZALO EXTRUDED",
			"GONZALO NUTS",
			"GONZALO POPCORN",
			"GONZALO POTATO",
			"GONZALO REGULAR",
			"GOOD SHOW CORN",
			"GOOD SHOW EXTRUDED",
			"GOOD SHOW NUTS",
			"GOOD SHOW OTHER",
			"GOOD SHOW PELLET",
			"GOOD SHOW POTATO",
			"GOOD SHOW REGULAR",
			"GRANDIET MIX",
			"GRANIX FRUTIGRAN",
			"GRANIX REGULAR",
			"HERRS TORTILLA",
			"HIGOS DE SMIRNA REGULAR",
			"HITS REGULAR",
			"HOJALMAR CAPRI",
			"HOJALMAR REGULAR",
			"IRINA REGULAR",
			"JULI CROC CORN",
			"JULI CROC POPCORN",
			"JUMALA REGULAR",
			"JUNIN REGULAR",
			"KESBUN PIZZA",
			"KESBUN REGULAR",
			"KING REGULAR",
			"KOKITO REGULAR",
			"KRACHITOS CORN",
			"KRACHITOS EXTRUDED",
			"KRACHITOS MIX",
			"KRACHITOS NUTS",
			"KRACHITOS OTHER",
			"KRACHITOS PELLET",
			"KRACHITOS POTATO",
			"KRACHITOS TORTILLA",
			"KRACHITOS WHEAT",
			"KRAFT REGULAR",
			"KRISKI NUTS",
			"KRISKI PELLET",
			"KRISKI POTATO",
			"KRISKI REGULAR",
			"KRISKI WHEAT",
			"KROKANTES EXTRUDED",
			"KROKANTES NUTS",
			"KROKANTES OTHER",
			"KROKANTES POTATO",
			"LA ABUNDANCIA REGULAR",
			"LA CAMPINA REGULAR",
			"LA EGIPCIANA REGULAR",
			"LA PARMESANA REGULAR",
			"LA REINA REGULAR",
			"LAS BRISAS REGULAR",
			"LAYS ACANALADAS",
			"LAYS BAKED",
			"LAYS CLASSIC",
			"LAYS FLAVOURS",
			"LAYS GOURMET",
			"LAYS OTHER SUBBRAND",
			"LAYS PAY",
			"LAYS SIN SAL",
			"LAYS STAX",
			"LLUVIA REGULAR",
			"LOS CAROLINOS REGULAR",
			"LOS RUSITOS REGULAR",
			"MABEL COOKIE",
			"MABEL SALTY",
			"MACRITAS REGULAR",
			"MADZ ANDINO REGULAR",
			"MAGICA POLENTA",
			"MAN REGULAR",
			"MANI JA REGULAR",
			"MANI MINGO REGULAR",
			"MANI REPEL REGULAR",
			"MANI SABORZ REGULAR",
			"MANISUR CORN",
			"MANISUR REGULAR",
			"MANITOON REGULAR",
			"MARIPOSA REGULAR",
			"MARISA NUTS",
			"MARISA REGULAR",
			"MARLOMS REGULAR",
			"MAURI REGULAR",
			"MELBA TRAD",
			"MILKA REGULAR",
			"MOLTO REGULAR",
			"MORAN EXTRUDED",
			"MORAN OTHER",
			"MORAN POTATO",
			"NACHO CERAL REGULAR",
			"NATUCHIPS REGULAR",
			"NATURAL BREAK REGULAR",
			"NIKITOS CORN",
			"NIKITOS EXTRUDED",
			"NIKITOS NUTS",
			"NIKITOS OTHER",
			"NIKITOS PELLET",
			"NIKITOS POPCORN",
			"NIKITOS POTATO",
			"NIKITOS WHEAT",
			"NUCETE REGULAR",
			"NUECES REGULAR",
			"OASIS CORN",
			"OASIS NUTS",
			"OASIS PELLET",
			"OASIS POTATO",
			"OASIS WHEAT",
			"OKEBON LECHE",
			"OKEBON MOLINO",
			"OKEBON REGULAR",
			"OREO DUO",
			"OREO GOLDEN",
			"OREO MINI",
			"OREO REGULAR",
			"OREO TRIO",
			"OTHER SUBBRAND COOKIE",
			"OTHER SUBBRAND CORN",
			"OTHER SUBBRAND EXTRUDED",
			"OTHER SUBBRAND HEALTHY",
			"OTHER SUBBRAND MIX",
			"OTHER SUBBRAND NUTS",
			"OTHER SUBBRAND OTHER",
			"OTHER SUBBRAND PELLET",
			"OTHER SUBBRAND POPCORN",
			"OTHER SUBBRAND POTATO",
			"OTHER SUBBRAND TORTILLA",
			"OTHER SUBBRAND TRADICIONAL",
			"OTHER SUBBRAND WAFER",
			"OTHER SUBBRAND WHEAT",
			"PALI CHIPS",
			"PARNOR PITUSAS",
			"PASAS DE UVA REGULAR",
			"PEDRIN CORN",
			"PEDRIN EXTRUDED",
			"PEDRIN NUTS",
			"PEDRIN OTHER",
			"PEDRIN PELLET",
			"PEDRIN REGULAR",
			"PEDRIN WHEAT",
			"PEHUAMAR ACANALADAS",
			"PEHUAMAR CLASICAS",
			"PEHUAMAR MAICITOS",
			"PEHUAMAR MAICITOS REJILLAS",
			"PEHUAMAR MANI CON PIEL",
			"PEHUAMAR MANI JAPONES",
			"PEHUAMAR MANI PELADO",
			"PEHUAMAR MANI SALADO",
			"PEHUAMAR MIX",
			"PEHUAMAR PALITOS",
			"PEP PALITOS",
			"PEP RAMITAS",
			"PEP RUEDITAS",
			"PEPITOS CHISPAS",
			"PEPITOS MINI",
			"PEPITOS RELLENOS",
			"PEPITOS STICKS",
			"PEPITOS TORTITAS",
			"PEPITOS TRAD",
			"PEPITOS WHITE",
			"PINDY REGULAR",
			"PIPAS REGULAR",
			"POP KORN REGULAR",
			"POSTREFINO REGULAR",
			"POZO REGULAR",
			"PRINGLES REGULAR",
			"PRINGLES TORTILLA CHIPS",
			"PRIVATE LABEL",
			"PRIVATE LABEL CORN",
			"PRIVATE LABEL MIX",
			"PRIVATE LABEL NUTS",
			"PRIVATE LABEL POTATO",
			"PRIVATE LABEL TORTILLA",
			"PRIVATE LABEL WHEAT",
			"QUAKER BARS CEREAL",
			"QUAKER CEREAL GRANOLA",
			"QUAKER CEREAL HONEY GRAHAM",
			"QUAKER CEREAL HONEY NUTS",
			"QUAKER CEREAL QUADRITOS",
			"QUAKER CRACKER REGULAR",
			"QUAKER GALLETAS MINI",
			"QUAKER GALLETAS MOLDE",
			"QUAKER GALLETAS RELLENA",
			"QUAKER GALLETAS WC",
			"QUAKER OATS EXTRA-FINA",
			"QUAKER OATS INSTANT",
			"QUAKER OATS REGULAR",
			"QUENTO HEALTHY",
			"QUENTO MIX",
			"QUENTO NUTS",
			"QUENTO REGULAR",
			"QUENTO TORTILLA",
			"QUENTO WHEAT",
			"SALADIX BACONZITOS",
			"SALADIX BACONZITOS EXTRUDED",
			"SALADIX CRAX",
			"SALADIX CROSS",
			"SALADIX CROSS WHEAT",
			"SALADIX HULA HULA",
			"SALADIX KESITOS",
			"SALADIX MILANESITAS",
			"SALADIX OTHER",
			"SALADIX PANCHITOS",
			"SALADIX PICANTO",
			"SALADIX PIZZITAS",
			"SALADIX POTATO",
			"SALADIX QUESO",
			"SALADIX RINGS",
			"SALADIX STICKS",
			"SALADIX TORTILLA",
			"SANJUANITA MIX",
			"SANJUANITA REGULAR",
			"SEMIX MANI",
			"SEMIX MIX",
			"SEMIX POPCORN",
			"SEMIX REGULAR",
			"SER SNACKS REGULAR",
			"SNACK REGULAR",
			"SNYDERS REGULAR",
			"SOLITAS REGULAR",
			"SORIANA REGULAR",
			"SUNBITES REGULAR",
			"TEREPIN REGULAR",
			"TERRA MIA REGULAR",
			"TERRAB VARIETY",
			"TERRABUSI ANILLOS",
			"TERRABUSI BOCA DAMA",
			"TERRABUSI CHAMPAGNE",
			"TERRABUSI DUQUESA",
			"TERRABUSI LINCOLN",
			"TERRABUSI MANON",
			"TERRABUSI MARIAS",
			"TERRABUSI ROCOCO",
			"TERRASANA REGULAR",
			"TIA MARUCA ANILLOS",
			"TIA MARUCA AZUCARADAS",
			"TIA MARUCA COOKIE",
			"TIA MARUCA CORAZON",
			"TIA MARUCA CORN",
			"TIA MARUCA JUNGLITAS",
			"TIA MARUCA NUTS",
			"TIA MARUCA PALMERITAS",
			"TIA MARUCA PEPITAS",
			"TIA MARUCA REGULAR",
			"TIA MARUCA ROSQUELLAS",
			"TIA MARUCA TORTITAS",
			"TIA MARUCA TRIANGULITOS",
			"TIA MARUCA VARIETY",
			"TIA MARUCA WHEAT",
			"TODDY CHISPAS",
			"TODDY CHISPAS BLACK",
			"TODDY CHISPAS DDL",
			"TODDY CHOCOLAT",
			"TODDY COCOA BLAST",
			"TODDY EXTRA",
			"TODDY ONE",
			"TODDY RELLENAS",
			"TOSTINAS REGULAR",
			"TOSTITOS REGULAR",
			"TOTAL O CORP",
			"TRAJANO REGULAR",
			"TRIO PEPAS",
			"TRIO REGULAR",
			"TRIO VARIETY",
			"TWISTOS CRACKERS",
			"TWISTOS MINITOSTADITAS",
			"TWISTOS MULTICEREAL",
			"TWISTOS RICE SNACK",
			"VALENTE REGULAR",
			"VILLA RICA REGULAR",
			"WONDERFUL PISTACHIOS",
			"YARIGUARENDA CORN",
			"YUKITAS REGULAR",
			"ZAFRAN REGULAR",
			"ZUCOA REGULAR",
			"ZYMMA POTATO",
			"ZYMMA REGULAR",
		],
		packSize: [
			"01X-MINI",
			"01X-SS XSMALL",
			"02X-JR LINE",
			"04P-MULTIPK",
			"04X-SS LARGE",
			"07X-MAX",
			"07X-MEDIUM",
			"09X-GRANDE",
			"09X-JUMBO",
			"11X-BIG",
			"12X-EXTRA BIG",
			"15X-MEGA",
			"16X-SUPER SIZE",
		],
		date: "02/01/2024-03/01/2024",
		variables: ["priceByUnitSellOut"],
		views: ["absPeriodCompare", "currentPeriod", "periodCompare", "lastPeriod"],
	},
	{
		country: "BRAZIL",
		geoLevel: ["COUNTRY-TTL"],
		geoLevel2: ["TTL BRAZIL-ALL"],
		channel: ["ALL OUTLET"],
		periodView: ["na"],
		viewTop: 10,
		data: "sellOut",
		viewX: "absGrowthSOM",
		viewY: "priceSellOut",
		viewSize: "somSalesUnitSellOut",
		xSOMGroup: "Value",
		xSOMMeasure: "Category",
		ySOMGroup: "perVolume",
		ySOMMeasure: "",
		viewType: "subBrandSize",
		category: ["BIS-COOKIES", "BIS-CRACKERS", "FDS-BARS", "FDS-OATS", "SAV-NUTS & SEEDS", "SAV-SALTY", "SAV-WHEAT"],
		vendor: [
			"AGTAL",
			"ANCHIETA",
			"APTI ALIM.",
			"CARAMURU",
			"DORI",
			"ENOVA",
			"GENERAL MILLS",
			"GULOZITOS",
			"KELLOGGS",
			"LIOTECNICA",
			"MAE TERRA",
			"MARATA",
			"MARCA PROPRIA",
			"MONDELEZ",
			"NESTLE",
			"OTHER VENDOR",
			"PEPSICO",
			"SAO BRAZ",
		],
		segment: [
			"CKY-CHOC CHIP",
			"CKY-CHOC COVER",
			"CKY-FILLED",
			"CKY-MARIAS",
			"CKY-RING",
			"CKY-SANDWICH",
			"CKY-TRAD",
			"CKY-WAFER",
			"CRK-OIL",
			"CRK-PLAIN",
			"CRK-SALTINE",
			"FDS-BARS FRUIT",
			"FDS-BARS GRANOLA",
			"FDS-MILLED POWDER",
			"FDS-OATS NATURAL",
			"SAV-EXTRUDED",
			"SAV-NUTS",
			"SAV-POPCORN-RTE",
			"SAV-POTATO",
			"SAV-SALTY MIX",
			"SAV-TORTILLA",
			"SAV-VARIETY PK",
			"SAV-WHEAT",
		],
		brand: [
			"AGITO",
			"AGTAL",
			"AGTAL NUTS REGULAR",
			"AGUIA CRACKERS",
			"AMENDOIM JAPONES",
			"AMENDOIM OVINHO",
			"AMENDOIM SEM PELE",
			"ANCHIETA NUTS REGULAR",
			"APTI",
			"BACONZITOS",
			"BACONZITOS REGULAR",
			"BIRO BIRO REGULAR",
			"CEBOLITOS",
			"CEBOLITOS REGULAR",
			"CHEETOS",
			"CHEETOS ASSADO",
			"CHEETOS BAKED OTHER SUBBRAND",
			"CHEETOS BOLA",
			"CHEETOS ESQUELETO",
			"CHEETOS LUA",
			"CHEETOS MIX",
			"CHEETOS ONDA",
			"CHEETOS POPCORN",
			"CHEETOS TUBO",
			"CHEEZ IT",
			"DE MONTAO",
			"DE MONTAO REGULAR",
			"DELITOS REGULAR",
			"DOCE VIDA RECHEADO",
			"DOCE VIDA WAFER",
			"DORITOS",
			"DORITOS DINAMITA",
			"DORITOS DIPPAS",
			"DORITOS HEATWAVE",
			"DORITOS RAINBOW",
			"DORITOS REGULAR",
			"DORITOS ROCK",
			"ELBIS AMANTEIGADOS",
			"ELBIS LAMINADO",
			"ELBIS MARIAS",
			"ELBIS REGULAR",
			"ELBIS RING COOKIE",
			"ELBIS WAFER",
			"ELBIS WATER AND SALT",
			"ELMA CHIPS",
			"ELMA CHIPS AMENDOIM",
			"ELMA CHIPS STAX",
			"ELMA CHIPS STIKSY",
			"ELMA CHIPS ZAMBINOS",
			"EQLIBRI CRACKER",
			"EQLIBRI MULTIGRAIN",
			"EQLIBRI PANETINI",
			"EQLIBRI PIPOCA",
			"FANDANGOS",
			"FANDANGOS INTEGRAL",
			"FANDANGOS REGULAR",
			"FOFURA",
			"FOFURA JR",
			"FOFURA MIX",
			"FOFURA REGULAR",
			"GULAO",
			"KERO MAIS REGULAR",
			"LACTA COOKIES",
			"LANCHINHO",
			"LANCHINHO REGULAR",
			"LAYS",
			"LAYS REGULAR",
			"LUCKY",
			"LUCKY PALHA",
			"LUCKY PENTA",
			"LUCKY REGULAR",
			"LUCKY TRIGO",
			"MABEL",
			"MABEL AMANTEIGADOS",
			"MABEL CRACKER",
			"MABEL DELICIAS",
			"MABEL GLACE RING COOKIES",
			"MABEL LAMINADO",
			"MABEL MARIA",
			"MABEL REGULAR",
			"MABEL RING COOKIES",
			"MABEL SEQUILHOS",
			"MABEL WAFER",
			"MAE TERRA",
			"MAGIC FAVORITOS REGULAR",
			"MANIX",
			"MARATA",
			"MARCA TRAD PROPRIA",
			"MILHOBOM REGULAR",
			"MIRABEL WAFER",
			"NA MESA",
			"NATUCHIPS",
			"NATURNES",
			"NESFIT",
			"NESTLE",
			"OTHER EXTRUDED SUBBRAND",
			"OTHER MARIAS SUBBRAND",
			"OTHER NUTS SUBBRAND",
			"OTHER OATS NATURAL SUBBRAND",
			"OTHER POPCORN-RTE SUBBRAND",
			"OTHER POTATO SUBBRAND",
			"OTHER TORTILLA SUBBRAND",
			"OTHER VARIETY PK",
			"OTHER WHEAT SUBBRAND",
			"PAVESINO REGULAR",
			"PETTIZ",
			"PINGO D OURO",
			"PINGO DOURO REGULAR",
			"PIPPOS",
			"POPCORNERS",
			"PRINGLES",
			"QUAKER",
			"QUAKER BARS CEREAL",
			"QUAKER BARS FRUIT",
			"QUAKER GALLETAS REGULAR",
			"QUAKER OATS FINE FLAKE",
			"QUAKER OATS FLAKES",
			"QUAKER OATS OTHER",
			"QUAKER OATS REGULAR",
			"QUALIMAX",
			"RUFFLES",
			"RUFFLES REGULAR",
			"RUFFLES STAX",
			"SAN CARLO REGULAR",
			"SENSACOES",
			"SENSACOES CRACKER",
			"SENSACOES ELMA CHIPS",
			"SENSACOES MIXED NUTS",
			"SENSACOES TORTILLA",
			"SENSATIONS",
			"SINHA REGULAR",
			"SKINY",
			"SKINY REGULAR",
			"STAX",
			"STAX REGULAR",
			"STIKSY",
			"STIKSY REGULAR",
			"TODDY COOKIE",
			"TODDY EXTRA",
			"TODDY MALHADO",
			"TODDY RECHEADO",
			"TODDY TORTINHA",
			"TODDY WAFER",
			"TORCIDA",
			"TORCIDA JR",
			"TORCIDA REGULAR",
			"TORCIDA SNACK BAR",
			"TORRADITOS REGULAR",
			"YOKI",
			"YOKITOS",
			"ZAMBINOS REGULAR",
		],
		subBrand: [
			"AGITO",
			"AGTAL",
			"AGTAL NUTS REGULAR",
			"AGUIA CRACKERS",
			"AMENDOIM JAPONES",
			"AMENDOIM OVINHO",
			"AMENDOIM SEM PELE",
			"ANCHIETA NUTS REGULAR",
			"APTI",
			"BACONZITOS",
			"BACONZITOS REGULAR",
			"BIRO BIRO REGULAR",
			"CEBOLITOS",
			"CEBOLITOS REGULAR",
			"CHEETOS",
			"CHEETOS ASSADO",
			"CHEETOS BAKED OTHER SUBBRAND",
			"CHEETOS BOLA",
			"CHEETOS ESQUELETO",
			"CHEETOS LUA",
			"CHEETOS MIX",
			"CHEETOS ONDA",
			"CHEETOS POPCORN",
			"CHEETOS TUBO",
			"CHEEZ IT",
			"DE MONTAO",
			"DE MONTAO REGULAR",
			"DELITOS REGULAR",
			"DOCE VIDA RECHEADO",
			"DOCE VIDA WAFER",
			"DORITOS",
			"DORITOS DINAMITA",
			"DORITOS DIPPAS",
			"DORITOS HEATWAVE",
			"DORITOS RAINBOW",
			"DORITOS REGULAR",
			"DORITOS ROCK",
			"ELBIS AMANTEIGADOS",
			"ELBIS LAMINADO",
			"ELBIS MARIAS",
			"ELBIS REGULAR",
			"ELBIS RING COOKIE",
			"ELBIS WAFER",
			"ELBIS WATER AND SALT",
			"ELMA CHIPS",
			"ELMA CHIPS AMENDOIM",
			"ELMA CHIPS STAX",
			"ELMA CHIPS STIKSY",
			"ELMA CHIPS ZAMBINOS",
			"EQLIBRI CRACKER",
			"EQLIBRI MULTIGRAIN",
			"EQLIBRI PANETINI",
			"EQLIBRI PIPOCA",
			"FANDANGOS",
			"FANDANGOS INTEGRAL",
			"FANDANGOS REGULAR",
			"FOFURA",
			"FOFURA JR",
			"FOFURA MIX",
			"FOFURA REGULAR",
			"GULAO",
			"KERO MAIS REGULAR",
			"LACTA COOKIES",
			"LANCHINHO",
			"LANCHINHO REGULAR",
			"LAYS",
			"LAYS REGULAR",
			"LUCKY",
			"LUCKY PALHA",
			"LUCKY PENTA",
			"LUCKY REGULAR",
			"LUCKY TRIGO",
			"MABEL",
			"MABEL AMANTEIGADOS",
			"MABEL CRACKER",
			"MABEL DELICIAS",
			"MABEL GLACE RING COOKIES",
			"MABEL LAMINADO",
			"MABEL MARIA",
			"MABEL REGULAR",
			"MABEL RING COOKIES",
			"MABEL SEQUILHOS",
			"MABEL WAFER",
			"MAE TERRA",
			"MAGIC FAVORITOS REGULAR",
			"MANIX",
			"MARATA",
			"MARCA TRAD PROPRIA",
			"MILHOBOM REGULAR",
			"MIRABEL WAFER",
			"NA MESA",
			"NATUCHIPS",
			"NATURNES",
			"NESFIT",
			"NESTLE",
			"OTHER EXTRUDED SUBBRAND",
			"OTHER MARIAS SUBBRAND",
			"OTHER NUTS SUBBRAND",
			"OTHER OATS NATURAL SUBBRAND",
			"OTHER POPCORN-RTE SUBBRAND",
			"OTHER POTATO SUBBRAND",
			"OTHER TORTILLA SUBBRAND",
			"OTHER VARIETY PK",
			"OTHER WHEAT SUBBRAND",
			"PAVESINO REGULAR",
			"PETTIZ",
			"PINGO D OURO",
			"PINGO DOURO REGULAR",
			"PIPPOS",
			"POPCORNERS",
			"PRINGLES",
			"QUAKER",
			"QUAKER BARS CEREAL",
			"QUAKER BARS FRUIT",
			"QUAKER GALLETAS REGULAR",
			"QUAKER OATS FINE FLAKE",
			"QUAKER OATS FLAKES",
			"QUAKER OATS OTHER",
			"QUAKER OATS REGULAR",
			"QUALIMAX",
			"RUFFLES",
			"RUFFLES REGULAR",
			"RUFFLES STAX",
			"SAN CARLO REGULAR",
			"SENSACOES",
			"SENSACOES CRACKER",
			"SENSACOES ELMA CHIPS",
			"SENSACOES MIXED NUTS",
			"SENSACOES TORTILLA",
			"SENSATIONS",
			"SINHA REGULAR",
			"SKINY",
			"SKINY REGULAR",
			"STAX",
			"STAX REGULAR",
			"STIKSY",
			"STIKSY REGULAR",
			"TODDY COOKIE",
			"TODDY EXTRA",
			"TODDY MALHADO",
			"TODDY RECHEADO",
			"TODDY TORTINHA",
			"TODDY WAFER",
			"TORCIDA",
			"TORCIDA JR",
			"TORCIDA REGULAR",
			"TORCIDA SNACK BAR",
			"TORRADITOS REGULAR",
			"YOKI",
			"YOKITOS",
			"ZAMBINOS REGULAR",
		],
		packSize: [
			"01X-SS XSMALL",
			"03P-MULTIPK",
			"04X-SS LARGE",
			"05P-MULTIPK",
			"06P-MULTIPK",
			"07P-MULTIPK",
			"07X-MEDIUM",
			"08P-MULTIPK",
			"10P-MULTIPK",
			"11X-BIG",
			"12P-MULTIPK",
			"12X-EXTRA BIG",
			"12X-LARGE",
			"14X-FAMILY",
			"15P-MULTIPK",
			"15X-MEGA",
			"16P-MULTIPK",
			"16X-SUPER SIZE",
			"Economic Bag",
			"HEAVY USER/PRA FESTA",
			"Large Bag",
			"MULTIPACK AT 150G",
			"Medium Bag",
			"PRA GALERA ENTR. (101G-120G)",
			"PRA GALERA ENTR. (95G-150G)",
			"PRA GALERA REGULAR",
			"PRA GALERA UPPER (200G-250G)",
			"PRA GALERA UPPER (400G-750G)",
			"PRA GENTE",
			"PRA J",
			"PRA MIM",
			"REGULAR",
			"RESTOCK",
			"Small Bag",
			"To go",
			"UPSIZE",
		],
		date: "02/01/2024-03/01/2024",
		variables: ["priceByUnitSellOut"],
		views: ["absPeriodCompare", "currentPeriod", "periodCompare", "lastPeriod"],
	},
];

const dataObject = {
	varTop,
	viewSize,
	viewType,
	variableList,
	variableConfiguration,
	measureConfiguration,
	sellVariableData,
	ViewXLocal,
	ViewYLocal,
	AdjustPrice,
	productFilter,
	measureFilter,
	monthRangeFilter,
	simulationSellOutStatsCard,
	simulationSellInStatsCard,
	topProductFilterConfig,
	localProductFilterConfig,
	HeadingData,
	periodView,
	ViewYViewXMapper,
	initialRevenueMapFilterConfigurations,
	defaultRevenueMapFilterConfigurations,
};
export default dataObject;
